import React, { FunctionComponent, useCallback, useMemo } from 'react';
import { ISchemaFieldProps } from '../../model/IJsonSchema';
import GenericInput from '../genericInput/GenericInput';
import enquiryFormInputFieldErrorMessage from '../../service/enquiryFormInputFieldErrorMessage';

export const InputField: FunctionComponent<ISchemaFieldProps> = ({ formData }) => {
  const {
    label,
    required,
    id,
    onChange,
    value,
    rawErrors,
    options: { inputLabel },
    placeholder,
    schema: { readOnly },
    disabled,
  } = formData;
  const currentError = useMemo(() => rawErrors?.length > 0 && rawErrors[0], [rawErrors]);
  let errorMessage = enquiryFormInputFieldErrorMessage.setErrorMessage(formData, rawErrors);

  if (!errorMessage) {
    errorMessage = currentError;
  }

  const handleChange = useCallback(
    (e: React.ChangeEvent<{ value: string }>) => {
      onChange(e.target.value as string);
    },
    [value],
  );

  return (
    <GenericInput
      label={label}
      required={required}
      id={id}
      value={value}
      currentError={errorMessage}
      onChange={handleChange}
      inputLabel={inputLabel}
      placeholder={placeholder}
      readOnly={readOnly}
      disabled={disabled}
    />
  );
};

export default InputField;
