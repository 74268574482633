import { useMutation } from '@apollo/client';
import { CancelVehicleReservation } from '../../graphql/mutations/__generated__/CancelVehicleReservation';
import cancelVehicleReservationMutation from '../../graphql/mutations/cancelVehicleReservation';
import useToken from '../../../common/hooks/useToken';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';
import { JourneyType } from '../../../../__generated__/globalTypes';
import errorHandler from '../../../common/service/errorHandler';

export const useCancelVehicleReservation = () => {
  const { getCurrentToken } = useToken();
  const [cancelVehicleReservation, { loading }] = useMutation<CancelVehicleReservation>(
    cancelVehicleReservationMutation.CANCEL_VEHICLE_RESERVATION,
    {
      context: {
        headers: {
          Authorization: getCurrentToken(),
        },
      },
    },
  );
  const { deal } = useCheckoutContext();

  const removeSoftLock = async () => {
    const journeyType = deal?.journeyType;
    if (journeyType === JourneyType.online_order || journeyType === JourneyType.save_my_build) {
      try {
        await cancelVehicleReservation({ variables: { dealId: deal.uuid } });
      } catch (error) {
        errorHandler.handleError(error);
      }
    }
  };

  return {
    removeSoftLock,
    loading,
  };
};
