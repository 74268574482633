import React, { FunctionComponent } from 'react';
import ShowIfTranslationExists from '../../../../../common/components/ShowIfTranslationExists';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import staticDisclaimerStyles from './staticDisclaimersStyles';

const StaticDisclaimer: FunctionComponent = () => {
  const intl = useIntl();
  const styles = staticDisclaimerStyles({});

  return (
    <ShowIfTranslationExists translationKey="trans__checkout__enquiry_form__bottom_disclaimer">
      <Typography
        className={styles.disclaimer}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'trans__checkout__enquiry_form__bottom_disclaimer',
          }),
        }}
      />
    </ShowIfTranslationExists>
  );
};

export default StaticDisclaimer;
