import { gql } from '@apollo/client/core';

const preOrderOnlineExternal = {
  PRE_ORDER_ONLINE_EXTERNAL: gql`
    query PreOrder($dealId: String!, $postCode: String) {
      preOrderOnlineExternal(dealId: $dealId, postCode: $postCode)
    }
  `,
};

export default preOrderOnlineExternal;
