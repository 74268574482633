import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';

export type WebpayClasses = 'title' | 'description' | 'payButton' | 'hidePaymentButton' | 'description2' | 'email';

const webpayStyles = makeStyles<ICheckoutTheme, WebpayClasses>(
  ({ palette, typography }) => ({
    title: {
      paddingBottom: '10px',
      fontWeight: typography.fontWeightBold,
    },
    description: {
      minHeight: '50px',
      lineHeight: '40px',
      fontWeight: typography.fontWeightLight,
      display: 'block',
    },
    email: {
      display: 'inline',
      color: palette.primary.main,
      textDecoration: 'underline',
      fontSize: typography.fontMD,
    },
    description2: {
      fontSize: typography.fontMD,
      textAlign: 'center',
      marginTop: '20px',
      display: 'block',
    },
    payButton: {
      margin: '30px auto',
      display: 'block',
    },
    hidePaymentButton: {
      display: 'none',
    },
  }),
  { name: 'Gql_Webpay' },
);

export default webpayStyles;
