import priceFormatterFactory from '../../../../../common/service/priceFormatterFactory';
import locale from '../../../common/service/locale';
import { ICurrencyFormatConfig } from '../../../../../common/model/ICurrencyFormatConfig';

interface IFormatterOptions {
  language: string;
  country: string;
  currency: string;
  decimalScale?: number;
  currencyFormatConfig?: ICurrencyFormatConfig;
}

export type IFormatter = Intl.NumberFormat | { format: (price: number) => string };

export default {
  getFormatter({ language, country, currency, decimalScale, currencyFormatConfig }: IFormatterOptions): IFormatter {
    const currencyLocale: string = locale.getCurrencyLocaleCode(language, country, currency);
    const priceArgs = {
      currency,
      style: 'currency',
      currencyDisplay: 'symbol',
      minimumFractionDigits: decimalScale,
      maximumFractionDigits: decimalScale,
    };

    return priceFormatterFactory.create(priceArgs, currencyLocale, currencyFormatConfig);
  },
};
