import { InMemoryCache, makeVar, ReactiveVar } from '@apollo/client';
import ISelectedFeaturesInputVar, { SelectedFeaturesInputVarDefaultValue } from '../model/ISelectedFeaturesInputVar';
import { FinanceFormInput } from './__generated__/globalTypes';
export const activeJourneyType: ReactiveVar<string> = makeVar<string>('');
export const financeFormInputVar: ReactiveVar<FinanceFormInput> = makeVar<FinanceFormInput | null>(null);

export const selectedFeaturesInputVar: ReactiveVar<ISelectedFeaturesInputVar> = makeVar<ISelectedFeaturesInputVar>(
  SelectedFeaturesInputVarDefaultValue,
);

const cache = new InMemoryCache({
  typePolicies: {
    enquiryForm: {
      fields: {
        selectedActiveSectionName: {
          read(): string {
            return activeJourneyType();
          },
        },
      },
    },
    CustomizedCarConfiguration: {
      fields: {
        financeFormInput: {
          read(): FinanceFormInput {
            return financeFormInputVar();
          },
        },
        selectedFeaturesInput: {
          read(): ISelectedFeaturesInputVar {
            return selectedFeaturesInputVar();
          },
        },
      },
    },
  },
});

export default cache;
