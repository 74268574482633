import { gql } from '@apollo/client/core';

const dealInsurance = {
  DEAL_INSURANCE: gql`
    fragment DealInsurance on DealInsurance {
      applicationStatus
      input {
        estimate
        dateOfBirth
        noClaimsDiscount
      }
      policy {
        type
        money {
          currency
          amount
        }
      }
    }
  `,
};
export default dealInsurance;
