import CONSTANTS from './constants';

export const newApiKeyMarkets = [
  CONSTANTS.BE_LRE_PL_DIMENSIONS.brand,
  CONSTANTS.BE_JAG_PL_DIMENSIONS.brand,
  CONSTANTS.BE_SUB_DIS_AU_DIMENSIONS.brand,
  CONSTANTS.BE_BYD_DIS_EN_BE_DIMENSIONS.brand,
  CONSTANTS.BE_BYD_DIS_FR_BE_DIMENSIONS.brand,
  CONSTANTS.BE_BYD_DIS_NL_BE_DIMENSIONS.brand,
];
