import React, { FunctionComponent, useCallback } from 'react';
import { Box, Paper, PaperProps } from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import CancelIcon from '@material-ui/icons/Cancel';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import modalPaperStyles from './modalPaperStyles';
import { ICheckoutTheme } from '../../../theme/ICheckoutTheme';

interface IModalPaperPros extends PaperProps {
  toggleModal?: (shouldOpen: boolean) => any;
  onClose?: () => any;
  hideCloseIcon?: boolean;
}

const ModalPaper: FunctionComponent<IModalPaperPros> = ({
  className,
  children,
  hideCloseIcon,
  onClose,
  toggleModal,
  ...rest
}) => {
  const styles = modalPaperStyles();
  const isBelowTablet = useMediaQuery(useTheme<ICheckoutTheme>().breakpoints.down('sm'));
  const handleClose = useCallback(() => toggleModal(false), [toggleModal]);

  return (
    <Paper {...rest} className={className} classes={{ root: styles.overlayPaper }}>
      {!hideCloseIcon && (
        <IconButton onClick={onClose ? onClose : handleClose} data-test={'button:close'} className={styles.closeButton}>
          {isBelowTablet ? <CancelIcon className={styles.closeIcon} /> : <HighlightOffOutlinedIcon />}
        </IconButton>
      )}
      <Box className={[className, styles.insetPaper].join(' ')}>{children}</Box>
    </Paper>
  );
};

export default ModalPaper;
