import httpClient from './httpClient';
import API_ROUTES from '../constants/apiRoutes';
import { IDeal } from '../../../../common/model/IDeal';

const dealApi = {
  getDeal: () => httpClient.get<IDeal>(API_ROUTES.CURRENT_DEAL_PATH),
  saveDeal: () => httpClient.patch<IDeal>(API_ROUTES.SAVE_DEAL),
};

export default dealApi;
