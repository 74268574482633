import { useLocation } from 'react-router';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';
import { Routes } from '../../../../common/enum/Routes';
import navigation from '../service/navigation';
import { NavigationStepLabels } from '../enum/NavigationStepLabels';
import INavigationSteps from '../model/INavigationSteps';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';

const useNavigationSteps = (): INavigationSteps => {
  const { pathname } = useLocation();
  const {
    platformConfig: {
      platformFeatures: { isDealerEnabled, isExtrasPageEnabled },
    },
  } = usePlatformConfig();
  const reserveOnlineJourney = [Routes.RESERVE_ONLINE, Routes.PAYMENT, Routes.CONFIRMATION_RESERVE_ONLINE].some(p =>
    pathname.includes(p),
  );
  const { extrasPageHasContent } = useCheckoutContext();

  const steps = reserveOnlineJourney
    ? [
        {
          label: NavigationStepLabels.DETAILS,
          pathname: Routes.RESERVE_ONLINE,
        },
        {
          label: NavigationStepLabels.PAYMENT,
          pathname: Routes.PAYMENT,
        },
        {
          label: NavigationStepLabels.CONFIRMATION,
          pathname: Routes.CONFIRMATION_RESERVE_ONLINE,
        },
      ]
    : [
        {
          label: NavigationStepLabels.DETAILS,
          pathname: Routes.REQUEST_QUOTE,
        },
        {
          label: NavigationStepLabels.CONFIRMATION,
          pathname: Routes.CONFIRMATION_REQUEST_QUOTE,
        },
      ];

  if (isDealerEnabled && !reserveOnlineJourney) {
    steps.unshift({
      label: NavigationStepLabels.DEALER,
      pathname: Routes.DEALER,
    });
  }

  if (isExtrasPageEnabled && extrasPageHasContent) {
    steps.unshift({
      label: NavigationStepLabels.EXTRAS,
      pathname: Routes.EXTRAS,
    });
  }

  const activeStep = navigation.getActiveStep(pathname, steps, reserveOnlineJourney);

  return { steps, activeStep };
};

export default useNavigationSteps;
