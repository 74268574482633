import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';
export type MarkdownTextClasses = 'text';

const markdownTextStyles = makeStyles<ICheckoutTheme, MarkdownTextClasses>(
  ({ typography }) => ({
    text: {
      paddingLeft: 10,
      fontSize: typography.fontSM,
    },
  }),
  { name: 'GqlCheckout_MarkdownText' },
);

export default markdownTextStyles;
