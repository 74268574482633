import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from 'src/common/theme/ICheckoutTheme';

export type PaymentClasses =
  | 'subTitle'
  | 'paragraph'
  | 'paymentButtonWrapper'
  | 'saveDealButton'
  | 'visaNetForm'
  | 'alignCenter'
  | 'paymentDescriptionContainer'
  | 'paymentDescription'
  | 'container';

const paymentStyles = makeStyles<ICheckoutTheme, PaymentClasses>(
  theme => ({
    container: {
      [theme.breakpoints.down('sm')]: {
        padding: 12,
      },
    },
    subTitle: {
      margin: `0 0 ${theme.spacing(2)} 0`,
      fontWeight: theme.typography.fontWeightBold,
    },
    paragraph: {
      margin: `0 0 ${theme.spacing(2)} 0`,
      whiteSpace: 'pre-wrap',
    },
    paymentButtonWrapper: {
      height: '100px',
      padding: `${theme.spacing(1)} 0 0 0`,
    },
    saveDealButton: {
      maxWidth: '350px',
      width: '100%',
    },
    visaNetForm: {
      '& button': {
        width: '223px',
        height: '49px',
        margin: 'auto',
        display: 'block',
      },
    },
    alignCenter: {
      display: 'flex',
      justifyContent: 'center',
    },
    cardContainer: {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        maxWidth: '50px',
        height: 'auto',
        padding: 0,
        transform: 'translate(10px, -35%)',
      },
    },
    cardLogo: {
      maxWidth: '50px',
      height: 'auto',
      padding: 0,
      transform: 'translateY(35%)',
    },
    paymentDescriptionContainer: {
      marginBottom: 20,
    },
    paymentDescription: {
      whiteSpace: 'break-spaces',
    },
  }),
  { name: 'Checkout_PaymentVisaNet' },
);

export default paymentStyles;
