import React, { FunctionComponent } from 'react';
import { Typography } from '@material-ui/core';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';

const CustomerInformationSummary: FunctionComponent = () => {
  const { deal } = useCheckoutContext();

  if (!deal) {
    return null;
  }

  const { email, firstName, lastName, phone } = deal.customer || {};

  return (
    <>
      <Typography variant="body1" data-test="checkout:customerInformation:summary:email">
        {email}
      </Typography>
      <Typography variant="body1" data-test="checkout:customerInformation:summary:name">
        {`${firstName} ${lastName}`}
      </Typography>
      <Typography variant="body1" data-test="checkout:customerInformation:summary:phone">
        {phone}
      </Typography>
    </>
  );
};

export default CustomerInformationSummary;
