import React from 'react';
import universal, { UniversalComponent } from 'react-universal-component';
import ThemedErrorMessage from '../../../common/components/presentation/Error/ThemedErrorMessage';
import errorHandler from '../../../common/service/errorHandler';
import MinimalLoader from '../../../common/components/presentation/MinimalLoader/MinimalLoader';
import IThemableComponent from 'src/common/theme/IThemableComponent';
import { DealerPageTitleClasses } from '../../commonSteps/dealer/components/dealerPageTitle/dealerPageTitleStyles';
import { DealerDetailsClasses } from '../../commonSteps/dealer/components/dealerSelector/dealerDetails/dealerDetailsStyles';
import { DealerDistanceClasses } from '../../commonSteps/dealer/components/dealerSelector/dealerDetails/dealerDistance/dealerDistanceStyles';
import { NavigationStepperClasses } from '../common/components/presentation/navigationStepper/navigationStepperStyles';
import { TooltipClasses } from '../../commonSteps/dealer/components/dealerSelector/dealersMap/tooltip/tooltipStyles';
import { DealerSelectorClasses } from '../../commonSteps/dealer/components/dealerSelector/dealerSelectorStyles';
import { DealerContainerClasses } from './dealerContainerStyles';
import { DealersListClasses } from '../../commonSteps/dealer/components/dealerSelector/dealersList/dealersListStyles';

export interface IGqlDealerThemableComponents {
  GqlCheckout_DealerPageTitle: IThemableComponent<DealerPageTitleClasses>;
  GqlCheckout_DealerDetails: IThemableComponent<DealerDetailsClasses>;
  Checkout_DealerDistance: IThemableComponent<DealerDistanceClasses>;
  Checkout_NavigationStepper: IThemableComponent<NavigationStepperClasses>;
  GqlCheckout_DealerMapTooltip: IThemableComponent<TooltipClasses>;
  GqlCheckout_DealerSelector: IThemableComponent<DealerSelectorClasses>;
  GqlCheckout_DealerContainer: IThemableComponent<DealerContainerClasses>;
  Checkout_DealersList: IThemableComponent<DealersListClasses>;
}

const DeferredDealer: UniversalComponent<any> = universal<any>(
  () => import(/* webpackChunkName: "dealer" */ './DealerContainer'),
  {
    loading: (): JSX.Element => <MinimalLoader />,
    error: (props: { error: Error }) => {
      errorHandler.handleError(props.error);
      return <ThemedErrorMessage fullPage={true} message={'Failed loading dealer screen.'} error={props.error} />;
    },
  },
);

export default DeferredDealer;
