import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent, useCallback } from 'react';
import navigationStyles from './navigationStyles';
import { Routes } from '../../../enum/Routes';
import Translation from '../Translation/Translation';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import clsx from 'clsx';
import { Variant } from '@material-ui/core/styles/createTypography';
import { constants } from '../../../../../../common/constants/constants';
import usePlatformConfig from '../../../react/usePlatformConfig';
import { useGqlNav } from '../../../../gql/common/hooks/useGqlNav';

export interface INavigationProps {
  withIcon?: boolean;
  backTextClassName?: string;
  backTextVariant?: Variant;
}

const Navigation: FunctionComponent<INavigationProps> = ({
  withIcon = true,
  backTextClassName = '',
  backTextVariant = 'body1',
}) => {
  const {
    platformConfig: {
      platformFeatures: { isDealerEnabled, returnToConfiguratorFromPayment, isExtrasPageEnabled },
    },
  } = usePlatformConfig();
  const { location, history, configurationSourceUrl } = useGqlNav();

  const style = navigationStyles();

  const navigateBack = useCallback(() => {
    const pathName = window.location.pathname.split('/');
    const paymentPath = window.location.pathname.includes(Routes.PAYMENT);

    if (
      (!isExtrasPageEnabled &&
        ((!isDealerEnabled && pathName.includes(constants.REQUEST_QUOTE)) || pathName.includes(constants.DEALER))) ||
      pathName.includes(constants.EXTRAS)
    ) {
      window.location.href = configurationSourceUrl;
      return;
    }

    switch (pathName[pathName.length - 1]) {
      case Routes.PAYMENT.substr(1).split('/')[1]:
        if (returnToConfiguratorFromPayment) {
          window.location.href = configurationSourceUrl;
          return;
        }
        history.replace(Routes.RESERVE_ONLINE + location.search);
        return;
      case Routes.RESERVE_ONLINE.substr(1):
        window.location.href = configurationSourceUrl;
        return;
      default:
        return paymentPath ? null : history.goBack();
    }
  }, [history, configurationSourceUrl]);

  return (
    <Grid className={style.innerBackButtonContainer} item>
      {!!withIcon && <ArrowBackIosIcon className={style.icon} />}
      <Typography
        onClick={navigateBack}
        component="span"
        variant={backTextVariant}
        data-test="text:navigation:backButton"
        className={clsx([style.label, backTextClassName])}
      >
        <Translation id="trans__checkout__general__back_link" />
      </Typography>
    </Grid>
  );
};

export default Navigation;
