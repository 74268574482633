import { Typography } from '@material-ui/core';
import React, { FunctionComponent, ReactElement } from 'react';
import { format } from 'date-fns';
import { CreditCardBrands } from '../../../../../../../common/enum/CreditCardBrands';
import Translation from '../../../../../../../common/components/presentation/Translation/Translation';
import MasterCardLogo from '../../../../../../../common/assets/creditCardLogos/MasterCardLogo';
import VisaLogoMulti from '../../../../../../../common/assets/creditCardLogos/VisaLogoMulti';
import { GetDeal_deal_paymentInfo } from '../../../../../../common/graphql/queries/__generated__/GetDeal';
import { useIntl } from 'react-intl';

interface IPaymentFailedMessageProps {
  style: Record<string, string>;
  paymentInfo: GetDeal_deal_paymentInfo;
}

const PaymentFailedMessage: FunctionComponent<IPaymentFailedMessageProps> = ({ style, paymentInfo }) => {
  const transactionDateLabel = paymentInfo
    ? format(new Date(parseInt(paymentInfo?.transactionTime)), 'dd/MMM/yyyy hh:mm aa')
    : '';
  const intl = useIntl();
  const cardBrands: Record<string, ReactElement> = {
    [CreditCardBrands.VISA]: <VisaLogoMulti className={style.cardLogo} />,
    [CreditCardBrands.MASTERCARD]: <MasterCardLogo />,
  };
  const cardBrand = cardBrands[paymentInfo?.brand] || null;

  if (!paymentInfo) {
    return null;
  }

  return (
    <>
      <Typography component="h2" variant="h4" data-test="text:paymentError:subTitle" className={style.subTitle}>
        <Translation id="trans__checkout__payment_page__failed_subtitle" />
      </Typography>
      <Typography
        component="div"
        variant="body1"
        data-test="payment_page:payment_error:description1"
        className={style.paymentDescriptionContainer}
      >
        <Translation
          className={style.paymentDescription as string}
          id="trans__checkout__payment_page__failed_description1"
          values={{
            card_brand_image: cardBrand,
            card_number: paymentInfo.card,
            transaction_id: paymentInfo.transactionId,
            current_date: transactionDateLabel,
            reason_rejection: paymentInfo.actionDescription,
            mobile_phone: (
              <a
                data-test="payment_page:payment_error:phone"
                href={`tel:${intl.formatMessage({ id: 'trans__checkout__payment_page__phone_in_description' })}`}
              >
                <Translation id="trans__checkout__payment_page__phone_in_description" />
              </a>
            ),
          }}
        />
      </Typography>
    </>
  );
};

export default PaymentFailedMessage;
