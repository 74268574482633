import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type DealerDetailsClasses =
  | 'dealerDetailsContainer'
  | 'dealerDetails'
  | 'dealerName'
  | 'dealerAddress'
  | 'dealerCity';

const dealerDetailsStyles = makeStyles<ICheckoutTheme, DealerDetailsClasses>(
  ({ breakpoints, palette }) => ({
    dealerDetailsContainer: {
      display: 'flex',
      width: '100%',
      paddingTop: 12,
      paddingBottom: 27,
      minHeight: 100,
    },
    dealerDetails: {
      position: 'relative',
      maxWidth: 180,
      [breakpoints.down('sm')]: {
        maxWidth: '92%',
      },
    },
    dealerName: {
      paddingBottom: 10,
      textTransform: 'uppercase',
      color: palette.common.black,
    },
    dealerAddress: {},
    dealerCity: {},
  }),
  { name: 'GqlCheckout_DealerDetails' },
);

export default dealerDetailsStyles;
