import locale from "../service/locale";
import priceFormatterFactory from "../service/priceFormatterFactory";
import { ICurrencyFormatConfig } from "../model/ICurrencyFormatConfig";

export default function usePriceFormatter({
  currency,
  dimensions: { language, country },
  decimals = 2,
  currencyFormatConfig,
}: {
  currency: string;
  dimensions: {
    language: string;
    country: string;
  };
  decimals?: number;
  currencyFormatConfig?: ICurrencyFormatConfig;
}): Intl.NumberFormat | { format: (price: number) => string } {
  const currencyLocale: string = locale.getCurrencyLocaleCode(
    language,
    country,
    currency
  );
  const priceArgs = {
    currency: currency || "AUD",
    style: "currency",
    currencyDisplay: "symbol",
    minimumFractionDigits: decimals,
    maximumFractionDigits: decimals,
  };

  return priceFormatterFactory.create(
    priceArgs,
    currencyLocale,
    currencyFormatConfig
  );
}
