const queryParams = {
  getParam: (queryParamName: string, location: any) => {
    const search = location.search;
    const params = new URLSearchParams(search);

    return params.get(queryParamName);
  },
  changeParamValue: (location: any, history: any, paramName: string, oldValue: string, newValue: string) => {
    const oldSearchParams = location.search;
    const newSearchParams = oldSearchParams.replace(`${paramName}=${oldValue}`, `${paramName}=${newValue}`);

    history.replace({
      ...location,
      search: newSearchParams,
    });
  },
  addParamValue: (location: any, history: any, paramName: string, newValue: string) => {
    const oldSearchParams = location.search;
    const newSearchParams = `${oldSearchParams}&${paramName}=${newValue}`;

    history.replace({
      ...location,
      search: newSearchParams,
    });
  },
};

export default queryParams;
