import React, { FunctionComponent, useState } from 'react';
import { Container } from '@material-ui/core';
import SingpassContainer from './singpass/SingpassContainer';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';
import identityVerificationContainerStyles from './identityVerificationContainerStyles';
import { SingpassEventData } from './singpass/model/SingpassEvent';
import { SingpassAccess } from './singpass/enum/SingpassAccess';
import CustomerInformation from '../customerInformation/CustomerInformation';

const IdentityVerificationContainer: FunctionComponent = () => {
  const [verificationData, setVerificationData] = useState<SingpassEventData>();
  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const {
    platformConfig: {
      platformFeatures: { identityVerification },
    },
  } = usePlatformConfig();
  const styles = identityVerificationContainerStyles();

  const { progress, state } = verificationData || {};

  if (identityVerification === 'singpass') {
    return (
      <Container className={styles.root} data-test="checkout:identityVerification:container">
        <SingpassContainer
          isAccepted={isAccepted}
          verificationData={verificationData}
          setIsAccepted={setIsAccepted}
          setVerificationData={setVerificationData}
        />

        {progress === SingpassAccess.Granted && !!state && isAccepted && <CustomerInformation stateId={state} />}
      </Container>
    );
  }

  return null;
};

export default IdentityVerificationContainer;
