import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../theme/ICheckoutTheme';

export type DealUnavailableClasses = 'dialog' | 'message' | 'actionButton';

const dealUnavailablePageStyles = makeStyles<ICheckoutTheme, DealUnavailableClasses>(
  theme => ({
    dialog: {
      maxWidth: 550,
      width: '100%',
    },
    message: {
      textAlign: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    actionButton: {
      margin: 'auto',
      display: 'block',
      maxWidth: '210px',
    },
  }),
  { name: 'Checkout_DealUnavailable' },
);

export default dealUnavailablePageStyles;
