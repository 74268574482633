import { Typography } from '@material-ui/core';
import { ClassNameMap } from '@material-ui/core/styles/withStyles';
import React, { FunctionComponent } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { IPaymentData } from '../../../../../common/model/IPaymentData';
import { PaymentClasses } from '../paymentProviders/visanet/PaymentVisaNetStyles';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import { gqlPriceFormatter } from '../../../../common/hooks/prices/gqlPriceFormatter';

interface IDefaultStaticText {
  paymentData: IPaymentData;
  style: ClassNameMap<PaymentClasses>;
}

const DefaultText: FunctionComponent<IDefaultStaticText> = ({ style, paymentData }) => {
  const intl = useIntl();

  return (
    <>
      <Typography component="h2" variant="body1" data-test="text:payment:subTitle" className={style.subTitle}>
        <FormattedMessage id="trans__checkout__payment_page__subtitle" />
      </Typography>
      {paymentData?.amount && (
        <Typography component="div" variant="body1" data-test="text:payment:description" className={style.paragraph}>
          <Translation
            id="trans__checkout__payment_page__description1"
            values={{
              amount: gqlPriceFormatter().format(paymentData?.amount),
              mobile_phone: (
                <a href={`tel:${intl.formatMessage({ id: 'trans__checkout__payment_page__phone_in_description' })}`}>
                  <Translation id="trans__checkout__payment_page__phone_in_description" />
                </a>
              ),
            }}
          />
        </Typography>
      )}
    </>
  );
};

export default DefaultText;
