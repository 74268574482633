import { useQuery } from '@apollo/client';
import { GetExpressionOfInterest } from '../../graphql/queries/__generated__/GetExpressionOfInterest';
import expressionOfInterest from '../../graphql/queries/expressionOfInterest';
import useErrorHandler from '../useErrorHandler';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';

const useGetExpressionOfInterest = () => {
  const {
    platformConfig: {
      platformFeatures: { isExtrasPageEnabled },
    },
  } = usePlatformConfig();
  const { loading, error, data } = useQuery<GetExpressionOfInterest>(expressionOfInterest.GET_EXPRESSION_OF_INTEREST, {
    fetchPolicy: 'cache-and-network',
    skip: !isExtrasPageEnabled,
  });

  useErrorHandler(error);

  return { data, loading, error };
};

export default useGetExpressionOfInterest;
