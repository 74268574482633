import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import clsx from 'clsx';
import Button from '@material-ui/core/Button';
import Translation from '../../../../../../common/components/presentation/Translation/Translation';
import itemButtonStyles from './itemButtonStyles';

type ItemButtonProps = {
  isSelected: boolean;
  onClick: () => void;
};

const ItemButton: FunctionComponent<ItemButtonProps> = ({ isSelected, onClick }) => {
  const styles = itemButtonStyles();

  return (
    <Box className={styles.buttonWrapper}>
      <Button
        type="button"
        className={clsx([
          styles.button,
          {
            [styles.isSelected]: isSelected,
          },
        ])}
        data-test={'checkout:extras:expression_of_interest:button'}
        variant="outlined"
        onClick={onClick}
        classes={{ outlinedPrimary: styles.outlinedPrimary }}
      >
        <Translation
          id={isSelected ? 'trans__checkout__extras_page__eoi_remove_cta' : 'trans__checkout__extras_page__eoi_add_cta'}
          className={styles.buttonLabel}
        />
      </Button>
    </Box>
  );
};

export default ItemButton;
