import React from 'react';
import {
  createGenerateClassName,
  createMuiTheme,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
  jssPreset,
} from '@material-ui/core/styles';
import { create } from 'jss';
import increaseSpecificity from 'jss-increase-specificity';
import Box from '@material-ui/core/Box';
import CssBaseline from '@material-ui/core/CssBaseline';
import IInitializerComponent from './IInitializerComponent';
import commonConstants from '../theme/common';
import useWidgetContext from '../react/useWidgetContext';
import { assetManager } from '../service/assetManager';
import CONSTANTS from '../constants/constants';
import IWidgetConfiguration from '../../../../common/model/IWidgetConfiguration';

const generateClassName = createGenerateClassName({
  seed: 'CHECKOUT_LITE_WIDGET',
  productionPrefix: 'PROD',
});

const jss = create({
  ...jssPreset(),
  plugins: [...jssPreset().plugins, increaseSpecificity({ repeat: 1 })],
});

const ThemeProvider: IInitializerComponent<{ theme: any }, { configuration: IWidgetConfiguration }> = {
  hooks: () => {
    return useWidgetContext();
  },
  initialize: async args => {
    const {
      configuration: {
        dimensions: { brand, region, country },
      },
    } = args;

    await assetManager.loadStyle(CONSTANTS.CSS_URL);
    const themeModule = await import(
      /* webpackChunkName: "theme.[request]" */ `../../../../common/${brand}.${country}.${region}.assets/checkoutLiteBrandTheme`
    );

    return {
      theme: createMuiTheme(themeModule.default, commonConstants),
    };
  },

  component: ({ theme, children }) => (
    <StylesProvider generateClassName={generateClassName} jss={jss}>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        {/* dxp-id - added for widget integration*/}
        <Box id="dxp-checkout-wrapper" color="text.primary">
          {children}
        </Box>
      </MuiThemeProvider>
    </StylesProvider>
  ),
};

export default ThemeProvider;
