import useWidgetContext from '../../../common/react/useWidgetContext';
import useIsGqlMarket from './useIsGqlMarket';

const useGqlRestSwitch: () => { isGqlApi: boolean } = () => {
  const { configuration } = useWidgetContext();
  const isGqlApi = useIsGqlMarket(configuration) || configuration.gqlApi;

  return { isGqlApi };
};

export default useGqlRestSwitch;
