import React, { FunctionComponent, useCallback, useEffect } from 'react';
import { IRawError, ISchemaField } from 'src/enquiryForm/model/IJsonSchema';
import GenericInput from '../genericInput/GenericInput';

interface IDatePickerInputProps {
  props: React.PropsWithChildren<any>;
  formData: ISchemaField;
  toggleDatePicker: (value: boolean) => void;
  errorMessage: IRawError;
}

const DatePickerInput: FunctionComponent<IDatePickerInputProps> = ({
  props,
  formData,
  toggleDatePicker,
  errorMessage,
}) => {
  const {
    label,
    required,
    id,
    onChange,
    value,
    options: { inputLabel },
    placeholder,
  } = formData;

  useEffect(() => {
    onChange(formattedDate(props.value));
  }, [props.value]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<{ value: string }>) => {
      onChange(formattedDate(e.target.value) as string);
    },
    [value],
  );

  const formattedDate = (value: string) => {
    return value?.replace(/\//g, '-');
  };

  return (
    <GenericInput
      label={label}
      required={required}
      id={id}
      value={props.value}
      currentError={errorMessage}
      onChange={handleChange}
      inputLabel={inputLabel}
      datePicker={true}
      toggleDatePicker={toggleDatePicker}
      placeholder={placeholder}
      data-test={props?.inputProps?.['data-test']}
    />
  );
};

export default DatePickerInput;
