import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type UpdateOrderButtonClasses = 'updateOrderButton';

const updateOrderButtonStyles = makeStyles<ICheckoutTheme, UpdateOrderButtonClasses>(
  ({ breakpoints }) => ({
    updateOrderButton: {
      borderRadius: '4px',
      [breakpoints.down('xs')]: {
        marginTop: '10px',
      },
    },
  }),
  { name: 'Checkout4_UpdateOrderButton' },
);

export default updateOrderButtonStyles;
