import { useHistory } from 'react-router';
import { History } from 'history';
import { DealResponseCodes } from '../enum/DealResponseCodes';
import { Routes } from '../enum/Routes';

/**
 * @returns function
 *   Callback that will handle the error and notify whether it was specific deal error.
 *   Returns false if error was handled, or the error in case it was not.
 */
export default function useDealErrorHandler(overrideHistory?: History<unknown>): (error: any) => false | any {
  const history = overrideHistory || useHistory();

  return (error: any) => {
    if (!error?.response) {
      return error;
    }

    const code = error.response.data?.code;

    const isAccessDenied: boolean =
      error.response.status === 403 ||
      code === DealResponseCodes.INVALID_DEAL_STATUS ||
      code === DealResponseCodes.ACCESS_CODE_DENIED;
    if (isAccessDenied) {
      history.replace(Routes.ACCESS_DENIED);
      return false;
    }

    const isDealUnavailable =
      (error.response.status === 404 && code === DealResponseCodes.NO_ACTIVE_DEAL) ||
      (error.response.status === 404 && (!error.response.data || Object.keys(error.response.data).length === 0)) ||
      (error.response.status === 400 && code === DealResponseCodes.EXPIRED_DEAL);
    if (isDealUnavailable) {
      history.replace(Routes.DEAL_UNAVAILABLE, { isExpired: code === DealResponseCodes.EXPIRED_DEAL });
      return false;
    }

    return error;
  };
}
