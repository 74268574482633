import { useState, useEffect } from 'react';
import { UrlParamTypes } from '../enum/UrlParamTypes';

const useUrlSearchParams = () => {
  const queryString = window.location.search;
  const [searchParams, setSearchParams] = useState<Partial<Record<UrlParamTypes, string>>>();

  useEffect(() => {
    const searchParams = new URLSearchParams(queryString);

    const token = searchParams.get(UrlParamTypes.TOKEN) || '';
    const from = searchParams.get(UrlParamTypes.FROM) || '';

    setSearchParams(prev => ({
      ...prev,
      token: token,
      from: from,
    }));
  }, [queryString]);

  return searchParams;
};

export default useUrlSearchParams;
