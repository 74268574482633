import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type DealerPageInfoClasses = 'infoContainer' | 'info';

const dealerPageInfoStyles = makeStyles<ICheckoutTheme, DealerPageInfoClasses>(
  theme => ({
    infoContainer: {
      display: 'flex',
    },
    info: {
      color: theme.palette.text.disabled,
    },
  }),
  { name: 'GqlCheckout_DealerPageInfo' },
);

export default dealerPageInfoStyles;
