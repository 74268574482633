import { gql } from '@apollo/client/core';
import baseImage from './baseImage';

const interior = {
  INTERIOR: gql`
    ${baseImage.BASE_IMAGE}

    fragment Interior on Interior {
      shortname
      attributes {
        oemcode
      }
      image {
        ...BaseImage
      }
      price {
        ...Price
      }
    }
  `,
};

export default interior;
