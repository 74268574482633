import React, { FunctionComponent } from 'react';
import LinearProgress from '@material-ui/core/LinearProgress';
import Box from '@material-ui/core/Box';

interface ILoaderProps {
  className?: string;
}

const Loader: FunctionComponent<ILoaderProps> = ({ className }) => {
  return (
    <Box my={2} className={className} data-test={'container:loader'}>
      <LinearProgress color="primary" />
    </Box>
  );
};

export default Loader;
