import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../theme/ICheckoutTheme';

export type TranslationClasses = 'container';

const translationStyles = makeStyles<ICheckoutTheme, TranslationClasses>(() => ({
  container: {
    fontFamily: 'inherit',
    fontSize: 'inherit',
    fontStyle: 'inherit',
    fontWeight: 'inherit',
    textTransform: 'inherit',
  },
}));

export default translationStyles;
