import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';

export type BaseDealerLayoutClasses = 'layoutContainer';

const baseDealerLayoutStyles = makeStyles<ICheckoutTheme, BaseDealerLayoutClasses>(
  ({ breakpoints }) => ({
    layoutContainer: {
      display: 'flex',
      width: '100%',
      flexWrap: 'wrap',
      overflow: 'hidden',
      justifyContent: 'center',
      alignItems: 'center',

      [breakpoints.down('sm')]: {
        padding: '0 15px',
      },
    },
  }),
  { name: 'Common_BaseDealerLayout' },
);

export default baseDealerLayoutStyles;
