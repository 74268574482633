import { useMemo, useState } from 'react';
import { useMutation } from '@apollo/client';
import { InitializePayment, InitializePaymentVariables } from '../graphql/mutations/__generated__/InitializePayment';
import reserveForm from '../graphql/mutations/initPayment';
import { PaymentType } from '../../../../__generated__/globalTypes';
import errorHandler from '../../../common/service/errorHandler';
import usePriceFormatter from '../../../../../../common/react/usePriceFormatter';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';
import useWidgetContext from '../../../../common/react/useWidgetContext';
import { TransactionErrorTypes } from '../../../../common/enum/TransactionErrorTypes';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';

export const usePaymentEway = () => {
  const [initPayment, { data: initPaymentData, loading, error: initPaymentError }] = useMutation<
    InitializePayment,
    InitializePaymentVariables
  >(reserveForm.INITIALIZE_PAYMENT);
  const [softLocked, setSoftLocked] = useState(false);

  const { deal } = useCheckoutContext();
  const {
    platformConfig: {
      priceDisplay: {
        primary: { currency, subunitToUnit, decimalScale, currencyFormatConfig },
      },
    },
  } = usePlatformConfig();

  const {
    configuration: { dimensions },
  } = useWidgetContext();

  const priceFormatter = usePriceFormatter({
    currency,
    dimensions: {
      country: dimensions.country,
      language: dimensions.language,
    },
    decimals: decimalScale ?? 0,
    currencyFormatConfig,
  });

  const paymentAmount = useMemo(() => {
    const orderFee = deal?.attributes?.paymentFee?.orderFee?.find(orderFee => orderFee.currency === currency);

    if (orderFee) {
      return priceFormatter.format(Number(orderFee.amount / (subunitToUnit || 1)));
    }
  }, [deal, currency]);

  const handleInitPayment = async () => {
    try {
      const initData = await initPayment({
        variables: {
          identifier: deal.uuid,
          paymentType: PaymentType.order_car,
        },
      });

      if (initData.data.initPayment.url) {
        window.location.href = initData.data.initPayment.url;
      }
    } catch (e) {
      const softLockError = errorHandler.handleSoftLock(e);
      setSoftLocked(softLockError?.isStockLocked);
    }
  };

  const isPaymentFailed = deal?.status === TransactionErrorTypes.FAILED;

  return {
    handleInitPayment,
    initPaymentData,
    loading,
    initPaymentError,
    paymentAmount,
    isPaymentFailed,
    softLocked,
    setSoftLocked,
  };
};
