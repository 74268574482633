import { axiosInstance } from './httpClient';
import IPlatformConfig from '../model/IPlatformConfig';
import CONSTANTS from '../constants/constants';
import platformConfigService from '../../../../common/service/platformConfigService';
import API_ROUTES from '../constants/apiRoutes';

const { PLATFORM_API_URL } = CONSTANTS;

const multiDimensionApi = {
  getConfiguration(): Promise<IPlatformConfig> {
    // Overriding digital api base url
    return axiosInstance({ url: API_ROUTES.PLATFORM_FEATURES, baseURL: PLATFORM_API_URL }).then((response: any) => {
      const featureFlags: any = Object.values((response.data as any).platformFeatures || {}).pop();
      const paymentProviderKey: any = Object.values((response.data as any).payment || {}).pop();

      if (!featureFlags) {
        throw new Error(`Missing Checkout Lite feature flags platform configuration.`);
      }

      return {
        paymentProvider: paymentProviderKey?.provider,
        payment: {
          isCoeCategoryAmountEnabled: paymentProviderKey?.isCoeCategoryAmountEnabled === true,
        },
        priceDisplay: platformConfigService.normalizePriceDisplay(response.data?.priceDisplay),
        tradeInIntegration: platformConfigService.getPXIntegrationType(response.data),
        platformFeatures: featureFlags,
      };
    });
  },
};

export default multiDimensionApi;
