import { gql } from '@apollo/client/core';

const baseImage = {
  BASE_IMAGE: gql`
    fragment BaseImage on Image {
      url
      type
    }
  `,
};
export default baseImage;
