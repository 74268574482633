import React, { FunctionComponent, memo, forwardRef } from 'react';
import { Dialog, DialogProps } from '@material-ui/core';
import Slide from '@material-ui/core/Slide';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { TransitionProps } from '@material-ui/core/transitions';
import modalStyles from './modalStyles';
import ModalPaper from './ModalPaper';
import { ICheckoutTheme } from '../../../theme/ICheckoutTheme';

interface IModalProps extends DialogProps {
  labelledby?: string;
  toggleModal?: (shouldOpen: boolean) => any;
  onClose?: () => any;
  hideCloseIcon?: boolean;
}

const Transition = forwardRef<unknown, TransitionProps>(function Transition(props: any, ref: any): any {
  return <Slide direction="down" ref={ref} {...props} timeout={500} />;
});

const Modal: FunctionComponent<IModalProps> = memo(
  ({ children, labelledby, hideCloseIcon, onClose, toggleModal, PaperProps, ...rest }) => {
    const styles = modalStyles();
    const isBelowTablet = useMediaQuery(useTheme<ICheckoutTheme>().breakpoints.down('sm'));

    return (
      <Dialog
        aria-labelledby={labelledby}
        classes={{ paper: styles.container }}
        onClose={onClose}
        disableBackdropClick={true}
        disableEscapeKeyDown={true}
        PaperComponent={ModalPaper}
        PaperProps={
          {
            ...PaperProps,
            hideCloseIcon,
            onClose,
            toggleModal,
          } as any
        }
        {...rest}
        {...(isBelowTablet ? { fullScreen: true } : {})}
        TransitionComponent={Transition}
      >
        {children}
      </Dialog>
    );
  },
);

export default Modal;
