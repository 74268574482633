import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import dealerListHeaderStyles from './dealerListHeaderStyles';
import Translation from '../../../../../../common/components/presentation/Translation/Translation';

const DealerListHeader: FunctionComponent = () => {
  const styles = dealerListHeaderStyles();

  return (
    <Box className={styles.headerContainer}>
      <Typography variant={'body1'} className={styles.headerTitle} data-test="text:dealerList:header">
        <Translation id={'trans__checkout__dealers_page__list_title'} />
      </Typography>
    </Box>
  );
};

export default DealerListHeader;
