import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from 'src/common/theme/ICheckoutTheme';

export type ModalWithHeaderClasses = 'modalRoot' | 'closeContainer' | 'title' | 'closeButton' | 'icon';

const modalWithHeaderStyles = makeStyles<ICheckoutTheme, ModalWithHeaderClasses>(
  theme => ({
    modalRoot: {
      [theme.breakpoints.down('sm')]: {
        minWidth: '60vw',
      },
      width: '100%',
      [theme.breakpoints.up('md')]: {
        maxWidth: '600px',
      },
    },
    closeContainer: {
      color: theme.palette.common.white,
      zIndex: 100,
      backgroundColor: theme.palette.background.header,
      display: 'flex',
      justifyContent: 'space-between',
      padding: 0,
    },
    title: {
      lineHeight: '33px',
      display: 'inline-block',
      padding: '16px',
      color: theme.palette.common.white,
    },
    closeButton: {
      height: '25px',
      width: '25px',
      cursor: 'pointer',
      textAlign: 'right',
      margin: '8px',
    },
    icon: {
      width: '100%',
      height: '100%',
      fill: theme.palette.primary.main,
    },
  }),
  { name: 'Checkout_ModalWithHeader' },
);

export default modalWithHeaderStyles;
