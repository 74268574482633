import useWidgetContext from '../../../common/react/useWidgetContext';
import { tokenService } from '../../../../../common/service/tokenService';

const useToken = () => {
  const {
    configuration: { dimensions },
  } = useWidgetContext();

  return {
    getCurrentToken: () => tokenService.getCurrentToken(dimensions),
    removeCurrentToken: () => tokenService.removeCurrentToken(dimensions),
  };
};

export default useToken;
