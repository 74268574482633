import React, { FC, ReactElement } from 'react';
import Tooltip from '@material-ui/core/Tooltip';
import textTooltipStyles from './textTooltipStyles';

export interface ITextTooltipProps {
  text: string;
  content: ReactElement;
  placement?: 'bottom' | 'left' | 'right' | 'top';
  id?: string;
}

const TextTooltip: FC<ITextTooltipProps> = ({ text, content, placement = 'top', id }) => {
  const styles = textTooltipStyles();

  return (
    <Tooltip
      placement={placement}
      title={text}
      data-test={`text:tooltip:${id ?? ''}`}
      enterTouchDelay={0}
      leaveTouchDelay={5000}
      arrow={true}
      classes={{
        arrow: styles.tooltipArrow,
        tooltip: styles.tooltip,
      }}
    >
      {content}
    </Tooltip>
  );
};

export default TextTooltip;
