import CheckoutLiteError from './CheckoutLiteError';

export interface IValidationError {
  key: string;
  message: string;
}

export default class ValidationError extends CheckoutLiteError {
  /**
   * The name of the error.
   *
   * @type {string}
   */
  public static code = 'CHECKOUT_LITE-VALIDATION';

  protected validationErrors: IValidationError[] = [];

  constructor(message: string, errors: IValidationError[]) {
    super(message);

    this.message =
      message + ' => ' + errors.map((error: IValidationError) => error.key + ': ' + error.message).join(', ');
    this.validationErrors = errors;

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, ValidationError.prototype);
    this.name = this.getCode();
  }

  public getValidationErrors(): IValidationError[] {
    return this.validationErrors;
  }
}
