import React, { FunctionComponent, useMemo } from 'react';
import { Box, Button, useTheme } from '@material-ui/core';
import buttonSubmitStyles from './buttonSubmitStyles';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import { JourneyTypes } from '../../../../../../../common/enum/JourneyTypes';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

interface IButtonSubmitProps {
  disabled: boolean;
  journeyType: string;
}

const ButtonSubmit: FunctionComponent<IButtonSubmitProps> = ({ disabled, journeyType }) => {
  const getButtonTranslationID = (journeyType: string) => {
    if (journeyType === JourneyTypes.CONTINUE_IN_DEALERSHIP) {
      return 'trans__checkout__enquiry_form__submit_button';
    } else if (journeyType === JourneyTypes.ORDER_ONLINE) {
      return 'trans__checkout__next_step__continue_CTA';
    }

    return 'trans__checkout__enquiry_form__reserve_submit_button';
  };

  const translationId = useMemo(() => getButtonTranslationID(journeyType), [journeyType]);
  const styles = buttonSubmitStyles();

  const {
    checkoutLite: { checkout4 },
  } = useTheme<ICheckoutTheme>();

  return (
    <Box className={styles.submitButtonContainer}>
      <Button
        type="submit"
        disabled={disabled}
        className={styles.submitButton}
        classes={{ contained: styles.btnContained, containedPrimary: styles.newContainedPrimary }}
        variant="contained"
        color="primary"
        data-test="button:enquiry_form:submit"
        disableTouchRipple={!!checkout4?.disableTouchRipple}
      >
        <Translation id={translationId} />
      </Button>
    </Box>
  );
};

export default ButtonSubmit;
