import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import dealerPageTitleStyles from './dealerPageTitleStyles';
import Translation from '../../../../../common/components/presentation/Translation/Translation';

const DealerPageTitle: FunctionComponent = () => {
  const styles = dealerPageTitleStyles();

  return (
    <Box className={styles.titleContainer}>
      <Typography variant={'h4'} className={styles.title} data-test="text:dealer:pageTitle">
        <Translation id={'trans__checkout__dealers_page__page_title'} />
      </Typography>
    </Box>
  );
};

export default DealerPageTitle;
