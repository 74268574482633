import { ICheckoutTheme } from '../../../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type PinClasses = 'pinContainer' | 'indexContainer' | 'selected' | 'disabled';

const pinStyles = makeStyles<ICheckoutTheme, PinClasses>(
  theme => ({
    pinContainer: {
      width: '24px',
      height: '24px',
      cursor: 'pointer',
      position: 'absolute',
      top: '-24px',
      left: '-12px',
    },
    indexContainer: {
      position: 'absolute',
      color: theme.palette.common.white,
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      top: 0,
    },
    selected: {
      '& svg': {
        '& path': {
          fill: theme.palette.primary.main,
        },
      },
    },
    disabled: {
      '& svg': {
        '& path': {
          fill: theme.palette.primaryDisabled.main,
        },
      },
    },
  }),
  { name: 'Checkout_DealerMapPin' },
);

export default pinStyles;
