import { gql } from '@apollo/client/core';

const badge = {
  BADGE: gql`
    fragment Badge on Badge {
      image
      label
    }
  `,
};
export default badge;
