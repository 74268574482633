import { gql } from '@apollo/client/core';

const expressionOfInterest = {
  GET_EXPRESSION_OF_INTEREST: gql`
    query GetExpressionOfInterest {
      expressionOfInterest {
        code
        enabled
        attributes {
          picture
          label
          description
        }
      }
    }
  `,
};

export default expressionOfInterest;
