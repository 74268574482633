import { gql } from '@apollo/client/core';
import badge from './badge';
import discount from './discount';

const offers = {
  OFFERS: gql`
    ${badge.BADGE}
    ${discount.DISCOUNT}
    fragment Offers on Offers {
      badge {
        ...Badge
      }
      discounts {
        ...Discount
      }
    }
  `,
};
export default offers;
