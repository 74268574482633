import React, { FunctionComponent } from 'react';
import mainHeaderWithBackLinkStyles from './mainHeaderWithBackLinkStyles';
import Image from 'material-ui-image';
import logo from '../../../assets/images/logo.png';
import { Typography, Box, useTheme } from '@material-ui/core';
import { ICheckoutTheme } from 'src/common/theme/ICheckoutTheme';
import BleedContainer from '../Misc/BleedContainer';
import clsx from 'clsx';
import Translation from '../Translation/Translation';
import NavigationContainer from '../Navigation/NavigationContainer';

interface IMainHeaderWithBackLink {
  titleTranslation: string;
  withBackLink?: boolean;
  theme?: string;
  stickyHeader?: boolean;
}

const MainHeaderWithBackLink: FunctionComponent<IMainHeaderWithBackLink> = ({
  titleTranslation,
  withBackLink,
  theme = 'default',
  stickyHeader,
}) => {
  const {
    checkoutLite: {
      common: { showHeader, showLogo },
    },
  } = useTheme<ICheckoutTheme>();

  if (!showHeader) {
    return null;
  }

  const style = mainHeaderWithBackLinkStyles();

  return (
    <BleedContainer data-test="container:mainHeader" className={clsx({ [style.stickyHeader]: stickyHeader })}>
      {showLogo && (
        <Image
          key="Market Logo"
          src={logo}
          data-test="image:mainHeader:logo"
          alt="Market Logo"
          color="transparent"
          imageStyle={{
            height: '44px',
            width: '165px',
            left: 'inherit',
          }}
          style={{
            height: '60px',
            width: '100%',
            paddingTop: '0',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        />
      )}
      <Box className={clsx(style.step, theme === 'default' ? style.default : style.simple)}>
        {withBackLink && <NavigationContainer />}
        <Typography
          className={style.headerTitle}
          component="h1"
          variant="h4"
          data-test="text:mainHeader:currentJourney"
        >
          <Translation id={titleTranslation} />
        </Typography>
      </Box>
    </BleedContainer>
  );
};

export default MainHeaderWithBackLink;
