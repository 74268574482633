import CheckoutLiteError from './CheckoutLiteError';

export default class RequestCancelledError extends CheckoutLiteError {
  public static code = 'CHECKOUT-REQUEST-CANCELED';

  constructor(message: string) {
    super(message);

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, RequestCancelledError.prototype);
    this.name = this.getCode();
  }
}
