import IDimensions from '../../../../../spc/src/common/model/IDimensions';

const CONSTANTS = {
  BE_DFS_CL_DIMENSIONS: {
    brand: 'dfs-dis',
    country: 'cl',
    region: 'latam',
    language: 'es-cl',
  } as IDimensions,

  BE_DFS_PE_DIMENSIONS: {
    brand: 'dfs-dis',
    country: 'pe',
    region: 'latam',
    language: 'es-pe',
  } as IDimensions,

  BE_LRE_FI_DIMENSIONS: {
    brand: 'lre-dis',
    country: 'fi',
    region: 'eu',
    language: 'fi-fi',
  } as IDimensions,

  BE_LRE_PL_DIMENSIONS: {
    brand: 'lre-dis',
    country: 'pl',
    region: 'eu',
    language: 'pl-pl',
  } as IDimensions,

  BE_JAG_FI_DIMENSIONS: {
    brand: 'jag-dis',
    country: 'fi',
    region: 'eu',
    language: 'fi-fi',
  } as IDimensions,

  BE_JAG_PL_DIMENSIONS: {
    brand: 'jag-dis',
    country: 'pl',
    region: 'eu',
    language: 'pl-pl',
  } as IDimensions,
  BE_BMW_CL_DIMENSIONS: {
    brand: 'bmw-dis',
    country: 'cl',
    region: 'latam',
    language: 'es-cl',
  } as IDimensions,
  BE_SZK_PA_DIMENSIONS: {
    brand: 'szk-dis',
    country: 'pa',
    region: 'latam',
    language: 'es-pa',
  } as IDimensions,
  BE_SZK_CR_DIMENSIONS: {
    brand: 'szk-dis',
    country: 'cr',
    region: 'latam',
    language: 'es-cr',
  } as IDimensions,
  BE_TOY_DIS_BN_DIMENSIONS: {
    brand: 'toy-dis',
    country: 'bn',
    region: 'au',
    language: 'en-bn',
  } as IDimensions,
  BE_LEX_DIS_BN_DIMENSIONS: {
    brand: 'lex-dis',
    country: 'bn',
    region: 'au',
    language: 'en-bn',
  } as IDimensions,
  BE_SUB_DIS_NZ_DIMENSIONS: {
    brand: 'sub-dis',
    country: 'nz',
    region: 'au',
    language: 'en-nz',
  } as IDimensions,
  BE_TOY_DIS_SG_DIMENSIONS: {
    brand: 'toy-dis',
    country: 'sg',
    region: 'au',
    language: 'en-sg',
  } as IDimensions,
  BE_TOY_DIS_HK_DIMENSIONS: {
    brand: 'toy-dis',
    country: 'hk',
    region: 'au',
    language: 'en-hk',
  } as IDimensions,
  BE_TOY_DIS_ZH_HK_DIMENSIONS: {
    brand: 'toy-dis',
    country: 'hk',
    region: 'au',
    language: 'zh-hk',
  } as IDimensions,
  BE_SUB_DIS_AU_DIMENSIONS: {
    brand: 'sub-dis',
    country: 'au',
    region: 'au',
    language: 'en-au',
  } as IDimensions,
  BE_BMW_PE_DIMENSIONS: {
    brand: 'bmw-dis',
    country: 'pe',
    region: 'latam',
    language: 'es-pe',
  } as IDimensions,
  BE_LRE_LT_DIMENSIONS: {
    brand: 'lre-dis',
    country: 'lt',
    region: 'eu',
    language: 'lt-lt',
  } as IDimensions,
  BE_LRE_LV_DIMENSIONS: {
    brand: 'lre-dis',
    country: 'lv',
    region: 'eu',
    language: 'lv-lv',
  } as IDimensions,
  BE_LRE_EE_DIMENSIONS: {
    brand: 'lre-dis',
    country: 'ee',
    region: 'eu',
    language: 'et-ee',
  } as IDimensions,
  BE_GLY_CL_DIMENSIONS: {
    brand: 'gly-dis',
    country: 'cl',
    region: 'latam',
    language: 'es-cl',
  } as IDimensions,
  BE_JAG_DIS_LV_DIMENSIONS: {
    brand: 'jag-dis',
    country: 'lv',
    region: 'eu',
    language: 'lv-lv',
  } as IDimensions,

  BE_JAG_DIS_EE_DIMENSIONS: {
    brand: 'jag-dis',
    country: 'ee',
    region: 'eu',
    language: 'et-ee',
  } as IDimensions,

  BE_JAG_DIS_LT_DIMENSIONS: {
    brand: 'jag-dis',
    country: 'lt',
    region: 'eu',
    language: 'lt-lt',
  } as IDimensions,

  BE_LEX_DIS_SG_DIMENSIONS: {
    brand: 'lex-dis',
    country: 'sg',
    region: 'au',
    language: 'en-sg',
  } as IDimensions,

  BE_MINI_DIS_CL_DIMENSIONS: {
    brand: 'mini-dis',
    country: 'cl',
    region: 'latam',
    language: 'es-cl',
  } as IDimensions,

  BE_MINI_DIS_PE_DIMENSIONS: {
    brand: 'mini-dis',
    country: 'pe',
    region: 'latam',
    language: 'es-pe',
  } as IDimensions,

  BE_MOT_DIS_CL_DIMENSIONS: {
    brand: 'mot-dis',
    country: 'cl',
    region: 'latam',
    language: 'es-cl',
  } as IDimensions,

  BE_MOT_DIS_PE_DIMENSIONS: {
    brand: 'mot-dis',
    country: 'pe',
    region: 'latam',
    language: 'es-pe',
  } as IDimensions,

  BE_GRW_DIS_HK_DIMENSIONS: {
    brand: 'grw-dis',
    country: 'hk',
    region: 'au',
    language: 'en-hk',
  } as IDimensions,

  BE_GRW_DIS_ZH_HK_DIMENSIONS: {
    brand: 'grw-dis',
    country: 'hk',
    region: 'au',
    language: 'zh-hk',
  } as IDimensions,

  BE_MER_DIS_CO_DIMENSIONS: {
    brand: 'mer-dis',
    country: 'co',
    region: 'latam',
    language: 'es-co',
  } as IDimensions,

  BE_MER_PAS_CO_DIMENSIONS: {
    brand: 'mer-pas',
    country: 'ec',
    region: 'latam',
    language: 'es-ec',
  } as IDimensions,

  BE_HIN_DIS_CO_DIMENSIONS: {
    brand: 'hin-dis',
    country: 'co',
    region: 'latam',
    language: 'es-co',
  } as IDimensions,

  BE_SZK_DIS_SG_DIMENSIONS: {
    brand: 'szk-dis',
    country: 'sg',
    region: 'au',
    language: 'en-sg',
  } as IDimensions,

  BE_SUBARU_PE_DIMENSIONS: {
    brand: 'subaru',
    country: 'pe',
    region: 'latam',
    language: 'es-pe',
  } as IDimensions,

  BE_SUBARU_CO_DIMENSIONS: {
    brand: 'subaru',
    country: 'co',
    region: 'latam',
    language: 'es-co',
  } as IDimensions,

  BE_LEX_DIS_HK_DIMENSIONS: {
    brand: 'lex-dis',
    country: 'hk',
    region: 'au',
    language: 'en-hk',
  } as IDimensions,

  BE_SUBARU_CL_DIMENSIONS: {
    brand: 'subaru',
    country: 'cl',
    region: 'latam',
    language: 'es-cl',
  } as IDimensions,
  BE_BYD_DIS_NL_BE_DIMENSIONS: {
    brand: 'byd-dis',
    country: 'be',
    region: 'eu',
    language: 'nl-be',
  } as IDimensions,
  BE_BYD_DIS_FR_BE_DIMENSIONS: {
    brand: 'byd-dis',
    country: 'be',
    region: 'eu',
    language: 'fr-be',
  } as IDimensions,
  BE_BYD_DIS_EN_BE_DIMENSIONS: {
    brand: 'byd-dis',
    country: 'be',
    region: 'eu',
    language: 'en-be',
  } as IDimensions,
};

export default CONSTANTS;
