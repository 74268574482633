import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import Image from 'material-ui-image';
import Typography from '@material-ui/core/Typography';
import itemDetailsStyles, { imageStyle } from './itemDetailsStyles';
import { DealData_expressionsOfInterest_attributes } from '../../../../../common/graphql/fragments/deal/__generated__/DealData';

export interface ItemDetailsProps {
  itemData: DealData_expressionsOfInterest_attributes;
}

const ItemDetails: FunctionComponent<ItemDetailsProps> = ({ itemData }) => {
  const styles = itemDetailsStyles();

  return (
    <Box>
      <Image
        src={itemData.picture}
        alt={itemData.label}
        animationDuration={0}
        className={styles.image}
        imageStyle={imageStyle}
        style={{ paddingTop: 0, position: 'unset' }}
        color="transparent"
        data-test={'checkout:expressions_of_interest:item:image'}
      />
      <Box className={styles.details}>
        <Typography
          variant={'subtitle1'}
          data-test={'checkout:expressions_of_interest:item:title'}
          className={styles.title}
        >
          {itemData.label}
        </Typography>
        <Typography
          variant={'body1'}
          data-test={'checkout:expressions_of_interest:item:description'}
          className={styles.description}
        >
          {itemData.description}
        </Typography>
      </Box>
    </Box>
  );
};

export default ItemDetails;
