import React, { FunctionComponent } from 'react';

const MapPin: FunctionComponent = () => {
  return (
    <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 66.44">
      <path
        fill="#0c121c"
        className="cls-1"
        d="M51.19,17.13A24.83,24.83,0,0,0,18.85,3.79C9.91,7.45,4.7,14.3,3.38,23.9c-.81,5.89.55,11.51,3,16.82,5,10.93,12.75,19.77,21.8,27.63C35.3,61.92,41.72,55,46.67,46.87c3.52-5.79,6.12-11.91,6.45-18.82A24,24,0,0,0,51.19,17.13Z"
        transform="translate(-3.15 -1.9)"
      />
    </svg>
  );
};

export default MapPin;
