import { useCallback } from 'react';
import { useMutation } from '@apollo/client';
import { SelectExpressionsOfInterest } from '../../../../commonSteps/extras/graphql/mutations/__generated__/SelectExpressionsOfInterest';
import selectExpressionsOfInterestMutation from '../../../../commonSteps/extras/graphql/mutations/selectExpressionsOfInterest';

export const useSaveExpressionsOfInterest = () => {
  const [selectExpressionsOfInterest, { loading, error }] = useMutation<SelectExpressionsOfInterest>(
    selectExpressionsOfInterestMutation.SELECT_EXPRESSIONS_OF_INTEREST,
  );
  const saveExpressionsOfInterest = useCallback(async (token: string, codes: string[]) => {
    const expressionsOfInterest = await selectExpressionsOfInterest({
      variables: {
        dealId: token,
        codes: codes,
      },
    });
    return expressionsOfInterest;
  }, []);

  return { saveExpressionsOfInterest, loading, error };
};
