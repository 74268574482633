import React, { ReactNode, useCallback, useState } from 'react';
import ThemedErrorMessage from './ThemedErrorMessage';
import errorHandler from '../../../service/errorHandler';

export interface IErrorMessageHocProps {
  componentHandleError: (error: any) => void;
}

export default function errorMessageHoc<T extends IErrorMessageHocProps & { children?: ReactNode }>(
  Component: React.ComponentType<T>,
): React.ComponentType<Omit<T, keyof IErrorMessageHocProps>> {
  return (props: Omit<T, keyof IErrorMessageHocProps>) => {
    const [error, setError] = useState(null);

    const componentHandleError = useCallback(err => {
      // Consider error that is false as a sign of handled error for easier handler chaining.
      if (error !== false) {
        error !== null && errorHandler.handleError(err);
        setError(err);
      }
    }, []);

    if (error) {
      return <ThemedErrorMessage fullPage={true} error={error} message={'exception.unhandledError.message'} />;
    }

    return (
      <Component {...(props as T)} componentHandleError={componentHandleError}>
        {props.children}
      </Component>
    );
  };
}
