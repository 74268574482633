import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { ICheckoutThemeOptions } from './ICheckoutThemeOptions';

const COLOR_BLACK = '#222';
const COLOR_WHITE = '#fff';

export const BREAKPOINTS = {
  xs: 0,
  sm: 768,
  md: 960,
  lg: 1280,
  xl: 1920,
};
// TODO: update interface
const commonConstants: ICheckoutThemeOptions = {
  palette: {
    common: {
      black: COLOR_BLACK,
      white: COLOR_WHITE,
    },
  },
  breakpoints: createBreakpoints({ values: BREAKPOINTS }),
  shape: {
    borderRadius: 10,
  },
  shadows: [
    'none',
    '0px 2px 5px rgba(0,0,50,0.2)',
    '0px 3px 10px rgba(0,0,50,0.22)',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
    'none',
  ] as any,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          paddingTop: '1.12rem',
          paddingBottom: '1.12rem',
          borderRadius: 9999,
          fontWeight: 'bold',
          textTransform: 'none',
          textAlign: 'center',
          width: '100%',
          maxWidth: '180px',
        },
        outlined: {
          paddingTop: '1.12rem',
          paddingBottom: '1.12rem',
          fontWeight: 'normal',
        },
        contained: {
          boxShadow: 'none',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: '0px 3px 10px rgba(0,0,50,0.22)',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          paddingLeft: '1rem',
          paddingRight: '1rem',
          paddingBottom: '0',
          paddingTop: '.7rem',
          borderBottom: 0,
        },
        head: {
          borderWidth: 2,
        },
        body: {
          color: 'inherit',
          paddingTop: 6,
          paddingBottom: 6,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        outlinedSecondary: {
          borderWidth: '1.5px',
          fontWeight: 'normal',
          padding: '18.5px 3.5px',
        },
        colorSecondary: {
          fontWeight: 'bold',
          padding: '20px 5px',
          borderRadius: '2rem',
        },
        label: {
          paddingLeft: '.5rem',
          paddingRight: '.5rem',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          '&:after': {
            content: '" "',
            width: 0,
            height: 0,
            margin: 'auto',
            display: 'block',
            borderStyle: 'solid',
            position: 'absolute',
          },
        },
        popperArrow: {
          '&[x-placement*="bottom"] $arrow': {
            bottom: '100%',
            '&::after': {
              flip: false,
              bottom: 0,
              left: '50%',
              transform: 'translateX(-50%)',
              borderWidth: '0 4px 4px 4px',
              borderColor: 'transparent transparent white transparent',
            },
          },
          '&[x-placement*="top"] $arrow': {
            top: '99%',
            '&::after': {
              flip: false,
              top: 0,
              left: '50%',
              transform: 'translateX(-55%)',
              borderColor: 'white transparent transparent transparent',
              borderWidth: '4px 4px 0 4px',
            },
          },
          '&[x-placement*="right"] $arrow': {
            right: '100%',
            '&::after': {
              flip: false,
              right: 0,
              top: '50%',
              transform: 'translateY(-55%)',
              borderWidth: '4px 4px 4px 0',
              borderColor: 'transparent white transparent transparent',
            },
          },
          '&[x-placement*="left"] $arrow': {
            left: '100%',
            '&::after': {
              flip: false,
              left: 0,
              top: '50%',
              transform: 'translateY(-55%)',
              borderWidth: '4px 0 4px 4px',
              borderColor: 'transparent transparent transparent white',
            },
          },
        },
      },
    },
  },
};

export default commonConstants;
