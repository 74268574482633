import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../common/theme/ICheckoutTheme';

export type Checkout4LiteMainRoutesContainerStyles = 'root';

const checkout4LiteMainRoutesContainerStyles = makeStyles<ICheckoutTheme, Checkout4LiteMainRoutesContainerStyles>(
  () => ({
    root: {
      maxWidth: '1676px',
      margin: 'auto',
      width: '100%',
      paddingBottom: 40,
    },
  }),
  { name: 'Checkout4_checkoutLiteMainRoutesContainer' },
);

export default checkout4LiteMainRoutesContainerStyles;
