import React from 'react';
import universal, { UniversalComponent } from 'react-universal-component';
import IThemableComponent from 'src/common/theme/IThemableComponent';
import errorHandler from '../common/service/errorHandler';
import MinimalLoader from '../common/components/presentation/MinimalLoader/MinimalLoader';
import ThemedErrorMessage from '../common/components/presentation/Error/ThemedErrorMessage';
import { ReserveOnlineClasses } from './components/reserveOnline/ReserveOnlineStyles';
import { ConfirmationMainContentClasses } from './components/confirmationMainContent/ConfirmationMainContentStyles';
import { ConfirmationGoHomeClasses } from './components/GoHomeButton/GoHomeButtonStyles';

export interface ReserveOnlineConfirmationThemableComponents {
  Checkout_ConfirmationReserveOnline: IThemableComponent<ReserveOnlineClasses>;
  Checkout_ConfirmationMainContent: IThemableComponent<ConfirmationMainContentClasses>;
  Checkout_ConfirmationGoHome: IThemableComponent<ConfirmationGoHomeClasses>;
}

const DeferredReserveOnlineConfirmationPage: UniversalComponent<any> = universal<any>(
  () => import(/* webpackChunkName: "confirmationReserveOnline" */ './components/ReserveOnlineConfirmationScreen'),
  {
    loading: (): JSX.Element => <MinimalLoader />,
    error: (props: { error: Error }) => {
      errorHandler.handleError(props.error);
      return <ThemedErrorMessage fullPage={true} message={'Failed loading confirmation screen.'} error={props.error} />;
    },
  },
);

export default DeferredReserveOnlineConfirmationPage;
