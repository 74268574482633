import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type PaymentDisclaimerClasses =
  | 'container'
  | 'controlContainer'
  | 'controlCheckbox'
  | 'buttonWrapper'
  | 'paymentDisclaimerLabel';

const paymentDisclaimerStyles = makeStyles<ICheckoutTheme, PaymentDisclaimerClasses>(
  theme => ({
    container: {
      position: 'relative',
    },
    controlContainer: {
      display: 'table',
      marginBottom: 30,
    },
    controlCheckbox: {
      display: 'table-cell',
    },
    label: {},
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',

      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
      },
    },
    paymentDisclaimerLabel: {},
  }),
  { name: 'Checkout4_PaymentDisclaimer' },
);

export default paymentDisclaimerStyles;
