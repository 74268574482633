import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type DealerSelectorClasses = 'container' | 'actionButtonsContainer';

const dealerSelectorStyles = makeStyles<ICheckoutTheme, DealerSelectorClasses>(
  ({ breakpoints }) => ({
    container: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',

      [breakpoints.down('sm')]: {
        flexDirection: 'column-reverse',
        flexWrap: 'wrap',
      },
    },
    actionButtonsContainer: {
      margin: '40px 0',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  }),
  { name: 'GqlCheckout_DealerSelector' },
);

export default dealerSelectorStyles;
