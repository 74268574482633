import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type SingpassContainerClasses =
  | 'root'
  | 'disclaimerContainer'
  | 'singpassContainer'
  | 'titleContainer'
  | 'description';

const singpassContainerStyles = makeStyles<ICheckoutTheme, SingpassContainerClasses>(
  ({ palette, breakpoints }) => ({
    root: {
      backgroundColor: palette.common.white,
    },
    disclaimerContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 0',
      '& strong': {
        color: palette.secondary.main,
      },
    },
    singpassContainer: {
      marginBottom: 20,
      [breakpoints.down('xs')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      },
    },
    titleContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
    },
    description: {
      margin: '8px 0',
    },
  }),
  { name: 'Checkout4_SingpassContainer' },
);

export default singpassContainerStyles;
