import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';

export type JourneyListClasses =
  | 'listContainer'
  | 'listItem'
  | 'selected'
  | 'icon'
  | 'title'
  | 'description'
  | 'radio'
  | 'checked'
  | 'buttonWrapper'
  | 'selectedIcon'
  | 'radioIcon'
  | 'hideSelection';

const journeyListStyles = makeStyles<ICheckoutTheme, JourneyListClasses>(
  theme => ({
    listContainer: {
      display: 'block',
      marginBottom: '30px',
      padding: 0,
    },
    listItem: {
      boxShadow: `0 0 2px ${theme.palette.text.primary}`,
      width: '100%',
      cursor: 'pointer',
    },
    selected: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      '& $title': {
        color: theme.palette.primary.contrastText,
      },
      '& $description': {
        color: theme.palette.primary.contrastText,
      },
      '& $radio': {
        '&$checked': {
          color: theme.palette.primary.contrastText,
        },
      },
    },
    title: {},
    description: {},
    radio: {},
    checked: {},
    icon: {
      width: '20px',
      minWidth: '20px',
      marginRight: '20px',
    },
    buttonWrapper: {
      display: 'flex',
      flexDirection: 'column',

      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
      },
    },
    selectedIcon: {},
    radioIcon: {},
    hideSelection: {
      display: 'none',
    },
  }),
  { name: 'Checkout4_JourneyList' },
);

export default journeyListStyles;
