import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import Dealer from '../../../commonSteps/dealer/components/Dealer';
import dealerContainerStyles from './dealerContainerStyles';

const DealerContainer: FunctionComponent = () => {
  const styles = dealerContainerStyles();

  return (
    <Box data-test="checkout:dealer" className={styles.dealerContainer}>
      <Dealer />
    </Box>
  );
};

export default DealerContainer;
