import React, { useEffect, useState } from 'react';
import { UrlParamTypes } from '../../../common/enum/UrlParamTypes';
import useGetDeal from '../hooks/queries/useGetDeal';
import { GetDeal_deal } from '../graphql/queries/__generated__/GetDeal';
import CheckoutContext from '../context/CheckoutContext';
import useGetExpressionOfInterest from '../hooks/queries/useGetExpressionOfInterest';
import { ExpressionOfInterest } from '../graphql/fragments/deal/__generated__/ExpressionOfInterest';
import { FinanceFormType, StockType } from '../../../__generated__/globalTypes';
import { ApolloError } from '@apollo/client';
import { GetExpressionOfInterest_expressionOfInterest } from '../graphql/queries/__generated__/GetExpressionOfInterest';
import { DiscountCodes } from '../../checkout4/enum/DiscountCodes';

interface IInitializerComponent<T, H = null> {
  hooks?: () => H;
  initialize: (args: H) => Promise<T>;
  component: React.ComponentType<T>;
}

const SessionDataProvider: IInitializerComponent<
  {
    deal?: GetDeal_deal;
    loading: boolean;
    error: ApolloError;
    setDeal?: (deal: GetDeal_deal) => void;
    refetch: () => void;
    expressionOfInterest: ExpressionOfInterest[];
    extrasPageHasContent: boolean | null;
    isStock: boolean;
    isJlrStockDiscount: boolean;
    stockType: StockType | null;
  },
  {
    deal?: GetDeal_deal;
    loading: boolean;
    error: ApolloError;
    setDeal?: (deal: GetDeal_deal) => void;
    refetch: () => void;
    expressionOfInterest: ExpressionOfInterest[];
  }
> = {
  hooks: () => {
    const url = window.location.search;
    const searchParams = new URLSearchParams(url);
    const token = searchParams.get(UrlParamTypes.TOKEN) || '';
    const [deal, setDeal] = useState<GetDeal_deal | null>(null);
    const [expressionOfInterest, setExpressionOfInterest] = useState<GetExpressionOfInterest_expressionOfInterest[]>(
      null,
    );
    const { loadDeal, data, loading, error, refetch } = useGetDeal(token);
    const { data: expressionOfInterestData, loading: eoiLoading } = useGetExpressionOfInterest();

    useEffect(() => {
      loadDeal({
        variables: {
          token: token,
        },
      });
    }, [token]);

    useEffect(() => {
      if (data?.deal) {
        setDeal(data.deal);
      }
    }, [data]);

    useEffect(() => {
      if (!eoiLoading) {
        setExpressionOfInterest(expressionOfInterestData?.expressionOfInterest || []);
      }
    }, [expressionOfInterestData, eoiLoading]);

    return { deal, loading, error, setDeal, refetch, expressionOfInterest };
  },

  initialize: args => {
    const { deal, loading, error, setDeal, refetch, expressionOfInterest } = args;
    const hasCash: boolean = deal?.finance?.type === FinanceFormType.cash;
    const extrasPageHasContent = deal && expressionOfInterest ? !!(hasCash || expressionOfInterest?.length) : null;
    const isStock = !!deal?.car?.stock;
    const isJlrStockDiscount = deal?.car?.prices?.discounts?.[0]?.code === DiscountCodes.JLR_STOCK;
    const stockType = deal?.car?.stock?.stockType || null;

    return Promise.resolve({
      deal,
      loading,
      error,
      setDeal,
      refetch,
      expressionOfInterest,
      extrasPageHasContent,
      isStock,
      isJlrStockDiscount,
      stockType,
    });
  },

  component: ({
    deal,
    loading,
    error,
    setDeal,
    refetch,
    expressionOfInterest,
    extrasPageHasContent,
    isStock,
    isJlrStockDiscount,
    stockType,
    children,
  }) => {
    return (
      <CheckoutContext.Provider
        value={{
          deal,
          loading,
          error,
          setDeal,
          refetch,
          expressionOfInterest,
          extrasPageHasContent,
          isStock,
          isJlrStockDiscount,
          stockType,
        }}
      >
        {children}
      </CheckoutContext.Provider>
    );
  },
};

export default SessionDataProvider;
