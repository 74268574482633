import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type MyAccountChangeDetailsCtaClasses = 'root' | 'text';

const myAccountChangeDetailsCtaStyles = makeStyles<ICheckoutTheme, MyAccountChangeDetailsCtaClasses>(
  ({ breakpoints, typography }) => ({
    root: {
      position: 'absolute',
      right: 0,
      top: -55,
      [breakpoints.down('sm')]: {
        width: 'auto',
      },
      justifyContent: 'flex-end',
    },
    text: {
      fontSize: typography.fontMD,
    },
  }),
  { name: 'Checkout4_ChangeDetailsCTA' },
);

export default myAccountChangeDetailsCtaStyles;
