import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type DealerContainerClasses = 'dealerContainer';

const dealerContainerStyles = makeStyles<ICheckoutTheme, DealerContainerClasses>(
  () => ({
    dealerContainer: {},
  }),
  { name: 'Checkout4_DealerContainer' },
);

export default dealerContainerStyles;
