import React, { FC, useMemo, useCallback } from 'react';
import { ISchemaFieldProps } from '../../model/IJsonSchema';
import genericInputStyles from '../genericInput/GenericInputStyles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import InputLabel from '@material-ui/core/InputLabel';
import Box from '@material-ui/core/Box';
import textareaInputStyles from './TextareaInputStyles';

const TextareaInput: FC<ISchemaFieldProps> = ({ formData }) => {
  const {
    label,
    required,
    id,
    onChange,
    value,
    rawErrors,
    options: { inputLabel, rows },
    placeholder,
  } = formData;
  const styles = textareaInputStyles();
  const genericInputStyle = genericInputStyles();

  const currentError = useMemo(() => rawErrors?.length > 0 && rawErrors[0], [rawErrors]);

  const handleChange = useCallback(
    (e: React.ChangeEvent<{ value: string }>) => {
      onChange(e.target.value as string);
    },
    [value],
  );

  return (
    <Box>
      {inputLabel && (
        <Box display={'flex'}>
          <Typography component={'span'} variant={'subtitle1'}>
            <InputLabel
              htmlFor={id}
              required={required}
              className={genericInputStyle.label}
              data-test={`textarea_input:label:${id}`}
            >
              {label}
            </InputLabel>
          </Typography>
        </Box>
      )}
      <TextField
        data-test={`textarea_input:${id}`}
        id={id}
        variant={'outlined'}
        value={value ?? ''}
        multiline
        error={!!currentError}
        onChange={handleChange}
        rows={rows}
        fullWidth
        placeholder={placeholder}
        helperText={
          currentError && (
            <Typography
              component={'span'}
              className={genericInputStyle.errorText}
              data-test={`textarea_input:error:${id}`}
            >
              {currentError}
            </Typography>
          )
        }
        InputProps={{
          classes: {
            root: styles.root,
            focused: genericInputStyle.focused,
            error: genericInputStyle.inputError,
            notchedOutline: genericInputStyle.notchedOutline,
          },
        }}
        FormHelperTextProps={{
          classes: {
            root: genericInputStyle.helperTextError,
          },
        }}
      />
    </Box>
  );
};

export default TextareaInput;
