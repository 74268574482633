import React, { FunctionComponent, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import dealerDetailsStyles from './dealerDetailsStyles';
import SelectDealerButton from '../../buttons/selectDealerButton/SelectDealerButton';
import DealerDistance from './dealerDistance/DealerDistance';
import { GetDealers_dealers_nodes } from '../../../graphql/queries/__generated__/GetDealers';

export interface IDealerDetailsProps {
  orderId: number;
  dealer: GetDealers_dealers_nodes;
  isSelected: boolean;
  selectDealerId: (id: string) => void;
  disableDealerSelection?: boolean;
}

const DealerDetails: FunctionComponent<IDealerDetailsProps> = ({
  dealer,
  orderId,
  isSelected,
  selectDealerId,
  disableDealerSelection,
}) => {
  const styles = dealerDetailsStyles();
  const fieldRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    isSelected && fieldRef.current.scrollIntoView({ block: 'end' });
  }, []);

  return (
    <div className={styles.dealerDetailsContainer} ref={fieldRef}>
      <Box flexGrow={1}>
        <Box className={styles.dealerDetails}>
          <Typography variant={'subtitle2'} className={styles.dealerName} data-test={'text:dealer:name'}>
            {`${++orderId}. ${dealer.label}`}
          </Typography>
          <Typography
            variant={'body2'}
            component={'div'}
            data-test={'text:dealer:streetName'}
            className={styles.dealerAddress}
          >
            {dealer.address.address}
          </Typography>
          <Typography variant={'body2'} component={'div'} data-test={'text:dealer:city'} className={styles.dealerCity}>
            {dealer.address.city}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" flexDirection="column">
        <DealerDistance dealer={dealer} />
        <SelectDealerButton
          isSelected={isSelected}
          onClick={() => selectDealerId(dealer.externalId)}
          testId={`dealer:select:button_${dealer.externalId}_${isSelected}`}
          disableDealerSelection={disableDealerSelection}
        />
      </Box>
    </div>
  );
};

export default DealerDetails;
