import React, { FunctionComponent } from 'react';

const BubbleHelpIcon: FunctionComponent = () => {
  return (
    <svg width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 19">
      <path
        d="M17.8436 17.361L16.2679 13.9231C17.1273 12.5623 17.6287 10.9149 17.6287 9.196C17.6287 4.39727 13.6894 0.458008 8.8907 0.458008C4.09197 0.458008 0.15271 4.39727 0.15271 9.196C0.15271 13.9947 4.09197 17.934 8.8907 17.934C10.6096 17.934 12.257 17.4326 13.6178 16.5732L17.0557 18.1489C17.5571 18.3637 18.0584 17.8624 17.8436 17.361ZM8.8907 15.0691C8.17447 15.0691 7.60149 14.4961 7.60149 13.7799C7.60149 13.0636 8.17447 12.4907 8.8907 12.4907C9.60693 12.4907 10.1799 13.0636 10.1799 13.7799C10.1083 14.4961 9.53531 15.0691 8.8907 15.0691ZM10.3948 9.91223C9.8218 10.4136 9.8218 10.6285 9.8218 10.7717V11.2014C9.8218 11.4163 9.60693 11.6312 9.39206 11.6312H8.24609C8.03123 11.6312 7.81636 11.4163 7.81636 11.2014V10.7001C7.81636 9.8406 8.24609 9.05275 9.17719 8.33652C9.8218 7.83516 10.4664 7.19056 10.4664 6.61757C10.4664 5.7581 10.0367 5.39999 9.03394 5.39999C7.45824 5.39999 7.45824 6.54595 7.45824 6.61757V6.83244C7.45824 7.04731 7.24337 7.26218 7.0285 7.26218H5.88254C5.66767 7.26218 5.4528 7.04731 5.4528 6.83244V6.61757C5.4528 5.32836 6.3839 3.39454 9.03394 3.39454C11.5407 3.39454 12.4718 5.04187 12.4718 6.61757C12.4718 8.19328 11.1826 9.26762 10.3948 9.91223Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default BubbleHelpIcon;
