import { FunctionComponent } from 'react';
import { Box, Divider, List, ListItem } from '@material-ui/core';
import dealersListStyles from './dealersListStyles';
import React from 'react';
import DealerDetails from '../dealerDetails/DealerDetails';
import { GetDealers_dealers_nodes } from '../../../graphql/queries/__generated__/GetDealers';

export interface IDealersListProps {
  dealers: GetDealers_dealers_nodes[];
  selectedDealerId: string;
  selectDealerId: (id: string) => void;
  disableDealerSelection?: boolean;
}

const DealersList: FunctionComponent<IDealersListProps> = ({
  dealers,
  selectedDealerId,
  selectDealerId,
  disableDealerSelection,
}) => {
  const styles = dealersListStyles();

  return (
    <List className={styles.list}>
      {Array.isArray(dealers) &&
        dealers.map((dealer, index) => (
          <Box key={index}>
            <ListItem key={dealer.externalId} data-test={`dealer:listItem:card_${dealer.externalId}`}>
              <DealerDetails
                dealer={dealer}
                orderId={index}
                isSelected={selectedDealerId === dealer.externalId}
                selectDealerId={selectDealerId}
                disableDealerSelection={disableDealerSelection}
              />
            </ListItem>
            <Divider />
          </Box>
        ))}
    </List>
  );
};

export default DealersList;
