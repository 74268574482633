import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';

export type IdentityVerificationContainerClasses = 'root';

const identityVerificationContainerStyles = makeStyles<ICheckoutTheme, IdentityVerificationContainerClasses>(
  ({ breakpoints }) => ({
    root: {
      position: 'relative',
      [breakpoints.down('sm')]: {
        padding: 5,
      },
    },
  }),
  { name: 'Checkout4_IdentityVerification' },
);

export default identityVerificationContainerStyles;
