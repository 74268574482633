import React, { FunctionComponent, useCallback, useState } from 'react';
import CustomCheckbox from '../checkbox/Checkbox';
import TermsAndConditionsModal from './TermsAndConditionsModal';

type TermsAndConditionsProps = {
  isAccepted: boolean;
  setIsAccepted: (newValue: boolean) => void;
};

const TermsAndConditions: FunctionComponent<TermsAndConditionsProps> = ({ isAccepted, setIsAccepted }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleTermsClick = useCallback(() => {
    if (isAccepted) {
      return setIsAccepted(false);
    }
    return setIsOpen(true);
  }, [isAccepted]);

  return (
    <>
      <CustomCheckbox
        data-test={'checkout:identityVerification:terms'}
        label={'trans__checkout__next_step__customer_information__terms_and_conditions__order_online'}
        value={isAccepted}
        onClick={handleTermsClick}
      />
      <TermsAndConditionsModal isOpen={isOpen} setIsOpen={setIsOpen} setIsAccepted={setIsAccepted} />
    </>
  );
};

export default TermsAndConditions;
