import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type EnquiryFormClasses = 'formWrapper' | 'myAccountIntegrationContainer' | 'signinButton';

const enquiryFormStyles = makeStyles<ICheckoutTheme, { isCheckout4Enabled?: boolean }, EnquiryFormClasses>(
  ({ palette, breakpoints, spacing }) => ({
    formWrapper: {
      backgroundColor: ({ isCheckout4Enabled }) =>
        !!isCheckout4Enabled ? 'transparent' : palette.background.alternate,
      padding: ({ isCheckout4Enabled }) => (!!isCheckout4Enabled ? '0' : `${spacing(2)} ${spacing(5)}`),

      '& form': {
        maxWidth: '100%',
      },

      '& .form-group.field.field-string.field-error.has-error.has-danger ul li': {
        display: 'none',
      },

      '& .error-detail': {
        display: 'none',
      },

      '& .form-group.field-object': {
        '& .form-group.field-object .form-group.field-object, & .form-group.field-string, & .form-group.field-integer': {
          marginBottom: 10,
        },
        '& .form-group.field-object .field': {
          [breakpoints.down('sm')]: {
            paddingBottom: 20,
          },
        },
      },

      [breakpoints.down('sm')]: {
        padding: () => `0 ${spacing(1.5)}`,
      },
    },
    myAccountIntegrationContainer: {
      position: 'relative',
    },
    signinButton: {
      position: 'absolute',
      top: '-55px',
      right: 0,
      [breakpoints.down('xs')]: {
        position: 'relative',
        top: 0,
      },
    },
  }),
  { name: 'GqlCheckout_EnquiryForm' },
);

export default enquiryFormStyles;
