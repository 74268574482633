import { gql } from '@apollo/client/core';
import customer from '../customer';
import carVariant from '../carVariant';
import model from '../model';
import engine from '../engine';
import baseImage from '../baseImage';
import dealCarPrice from '../deal/dealCarPrice';
import price from '../price';
import dealFeature from '../deal/dealFeature';
import dealFinance from '../deal/dealFinance';
import dealInsurance from '../deal/dealInsurance';
import expressionOfInterest from '../deal/expressionOfInterest';
import speckPack from '../speckPack';
import dealer from '../dealer';
import appointment from '../appointment';
import interior from '../interior';
import exterior from '../exterior';
import background from '../background';
import paymentInfo from './paymentInfo';
import discount from '../discount';
import freebie from '../freebie';
import paymentFee from './paymentFee';
import bodyStyle from './bodyStyle';

const dealData = {
  DEAL_DATA: gql`
    ${customer.CUSTOMER_DETAILS}
    ${dealer.DEALER_DETAILS}
    ${appointment.APPOINTMENT_DETAILS}
    ${baseImage.BASE_IMAGE}
    ${dealCarPrice.DEAL_CAR_PRICE}
    ${freebie.FREEBIES}
    ${discount.DISCOUNT}
    ${price.PRICE}
    ${dealFinance.DEAL_FINANCE}
    ${dealInsurance.DEAL_INSURANCE}
    ${dealFeature.DEAL_FEATURE}
    ${carVariant.VARIANT}
    ${model.MODEL}
    ${engine.ENGINE}
    ${speckPack.SPECK_PACK}
    ${interior.INTERIOR}
    ${exterior.EXTERIOR}
    ${background.BACKGROUND}
    ${expressionOfInterest.EXPRESSIONS_OF_INTEREST}
    ${paymentInfo.PAYMENT_INFO}
    ${paymentFee.PAYMENT_FEE}
    ${bodyStyle.BODY_STYLE}

    fragment DealData on Deal {
      uuid
      disabled
      car {
        attributes {
          priceLabel
          coeCategoryAmount {
            amount
            currency
          }
          freebies {
            ...Freebies
          }
        }
        code
        displayName
        images {
          ...BaseImage
        }
        aggregatedOptions
        configurationSourceUrl
        deliveryTimeFrame {
          deliveryTimeFrameLabel
        }
        startConfigurationSourceUrl
        nameplate {
          code
          attributes {
            shortname
            modelyear
            oemcode
          }
        }
        interior {
          ...Interior
        }
        exterior {
          ...Exterior
        }
        background {
          ...Background
        }
        variant {
          ...Variant
        }
        model {
          ...Model
        }
        engine {
          ...Engine
        }
        specPack {
          ...SpecPack
        }
        prices {
          carPrice {
            ...DealCarPrice
          }
          discounts {
            ...Discount
          }
          totalPrice {
            ...Price
          }
          totalFeatures {
            ...Price
          }
          totalOfferFeatures {
            ...Price
          }
        }
        standardFeatures {
          ...DealFeature
        }
        selectedFeatures {
          ...DealFeature
        }
        disclaimers {
          value
          needsToBeTranslated
        }
        stock {
          vin
          dms
          stockType
        }
        bodyStyle {
          ...BodyStyle
        }
        isForReservation
      }
      finance {
        ...DealFinance
      }
      insurance {
        ...DealInsurance
      }
      expressionsOfInterest {
        ...ExpressionOfInterest
      }
      customer {
        ...CustomerDetails
      }
      paymentInfo {
        ...PaymentInfo
      }
      dealer {
        ...DealerDetails
      }
      appointment {
        ...AppointmentDetails
      }
      location {
        province
        district
        street
      }
      attributes {
        market
        modelyear
        co2
        range
        tradeIn
        tradeInMake
        tradeInModel
        tradeInVariant
        tradeInYear
        tradeInMileage
        tradeInRegistrationNumber
        tradeInColour
        tradeInComment
        paymentFee {
          ...PaymentFee
        }
        showErrorModal
        isDistributionStock
      }
      status
      journeyType
      currency
      region
      country
      brand
      language
      createdAt
      updatedAt
    }
  `,
};

export default dealData;
