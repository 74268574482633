import { useEffect, useState } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import collectedFilters from '../../../../common/service/collectedFilters';
import { TransactionErrorTypes } from '../../../../common/enum/TransactionErrorTypes';
import {
  InitializePayment,
  InitializePayment_initPayment,
  InitializePaymentVariables,
} from '../graphql/mutations/__generated__/InitializePayment';
import reserveForm from '../graphql/mutations/initPayment';
import { JourneyType } from '../../../../__generated__/globalTypes';
import { PaymentType } from '../../../../__generated__/globalTypes';
import { GetDeal_deal } from '../../../common/graphql/queries/__generated__/GetDeal';
import errorHandler from '../../../../gql/common/service/errorHandler';
import { StockErrorTypes } from '../../../checkout4/enum/StockErrorTypes';

export const usePayment2C2P = (deal: GetDeal_deal, loading: boolean) => {
  const {
    filters: { token },
  } = collectedFilters();
  const [initPayment] = useMutation<InitializePayment, InitializePaymentVariables>(reserveForm.INITIALIZE_PAYMENT);
  const [initData, setInitData] = useState<InitializePayment_initPayment>(null);
  const [initError, setInitError] = useState<ApolloError>(null);
  const [btnLoading, setBtnLoading] = useState<boolean>(false);
  const [isPaymentFailed, setIsPaymentFailed] = useState<boolean>(false);
  const [isTransactionComplete, setIsTransactionComplete] = useState<boolean>(false);
  const [isStatusLoading, setIsStatusLoading] = useState<boolean>(true);
  const [softLocked, setSoftLocked] = useState(false);
  const [softLockedErrorMsg, setSoftLockedErrorMsg] = useState<string>('');
  const [isErrorPaymentInitSoftLock, setErrorPaymentInitSoftLock] = useState<boolean>(false);

  const handlePayment = async () => {
    setInitError(null);
    setBtnLoading(true);
    localStorage.setItem('lastPaymentStatus', TransactionErrorTypes.NONE);

    if (isPaymentFailed) {
      setIsPaymentFailed(false);
    }

    const currentPaymentType =
      deal.journeyType === JourneyType.online_order ? PaymentType.order_car : PaymentType.reservation_fee;
    await initPayment({ variables: { identifier: token, paymentType: currentPaymentType } })
      .then(initData => {
        setInitData(initData.data.initPayment);
        setBtnLoading(false);
      })
      .catch(e => {
        const setSoftLockedError = errorHandler.handleSoftLock(e);
        setBtnLoading(false);
        setInitError(e);
        setSoftLocked(setSoftLockedError?.isStockLocked);
        setSoftLockedErrorMsg(setSoftLockedError?.translation || '');
        if (setSoftLockedError.path?.includes(StockErrorTypes.INIT_PAYMENT)) {
          setErrorPaymentInitSoftLock(true);
        }
      });
  };

  const handlePaymentPostMessages = (event: MessageEvent) => {
    const { paymentResult } = event?.data;
    if (paymentResult) {
      const { respCode } = paymentResult;

      if (respCode == '2000') {
        localStorage.setItem('isTransactionComplete', 'true');
        setIsTransactionComplete(true);
      }
    }
  };

  const handleCloseSoftLockModal = (shouldOpen?: boolean) => {
    if (!shouldOpen) {
      setSoftLocked(false);
      setSoftLockedErrorMsg('');
      setErrorPaymentInitSoftLock(false);
    }
  };

  window.addEventListener('message', handlePaymentPostMessages);

  useEffect(() => {
    const status = deal?.status;

    if (status === TransactionErrorTypes.FAILED) {
      setIsPaymentFailed(true);
    }

    if (status === TransactionErrorTypes.AUTHORIZED) {
      setIsTransactionComplete(true);
    }

    if (status && !loading) {
      setIsStatusLoading(false);
      localStorage.setItem('lastPaymentStatus', status);
    }
  }, [deal, loading]);

  return {
    initData,
    initError,
    btnLoading,
    isPaymentFailed,
    handlePayment,
    isTransactionComplete,
    isStatusLoading,
    softLocked,
    softLockedErrorMsg,
    isErrorPaymentInitSoftLock,
    handleCloseSoftLockModal,
  };
};
