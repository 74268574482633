import React from 'react';
import IInitializerComponent from './IInitializerComponent';
import dealApi from '../api/dealApi';
import userApi from '../api/userApi';
import AppContext, { IAppContext } from '../react/AppContext';
import { useGetAppContextValue } from '../react/useGetAppContextValue';
import { IUserInformation } from '../../../../common/model/IUser';
import { IDeal } from '../../../../common/model/IDeal';

const SessionDataProvider: IInitializerComponent<{ deal?: IDeal; dealError?: any; user: IUserInformation }, unknown> = {
  hooks: () => {
    return {};
  },

  initialize: () => {
    return Promise.all([
      dealApi
        .getDeal()
        .then(response => response.data)
        .then(deal => ({ deal, error: null }))
        .catch(error => {
          return { deal: null, error };
        }),
      userApi.isLogged().then(response => response.data),
    ]).then(([{ deal, error }, user]) => ({ deal, dealError: error, user }));
  },

  component: ({ deal, dealError, user, children }) => {
    const appContext: IAppContext = useGetAppContextValue({ deal, dealError, user });

    return <AppContext.Provider value={appContext}>{children}</AppContext.Provider>;
  },
};

export default SessionDataProvider;
