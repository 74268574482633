import { gql } from '@apollo/client/core';

const money = {
  MONEY: gql`
    fragment Money on Money {
      amount
      currency
    }
  `,
};
export default money;
