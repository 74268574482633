import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type EnquiryFormTitleClasses = 'title' | 'titleContent';

const enquiryFormTitleStyles = makeStyles<ICheckoutTheme, EnquiryFormTitleClasses>(
  ({ typography, breakpoints }) => ({
    title: {
      textAlign: 'center',
      marginLeft: 'inherit',
      fontFamily: typography.fontFamily2,
      margin: '16px auto 28px',
      [breakpoints.down('sm')]: {
        padding: '0 8px',
        margin: '16px auto 4px',
      },
    },
    titleContent: {},
  }),
  { name: 'GqlCheckout_EnquiryFormTitle' },
);

export default enquiryFormTitleStyles;
