import { useTheme } from '@material-ui/core';
import usePlatformConfig from '../../../common/react/usePlatformConfig';
import useWidgetContext from '../../../common/react/useWidgetContext';
import { ICheckoutTheme } from '../../../common/theme/ICheckoutTheme';
import useCheckoutContext from '../../common/hooks/useCheckoutContext';
import useGetConfigurationUrl from '../../common/hooks/useGetConfigurationUrl';

const useBackLink = () => {
  const {
    platformConfig: { checkout },
  } = usePlatformConfig();
  const {
    checkoutLite: { checkout4 },
  } = useTheme<ICheckoutTheme>();
  const backOverwrittenBasePath = checkout?.backOverwrittenBasePath;
  const { deal } = useCheckoutContext();
  const { configuration } = useWidgetContext();
  const { getConfigurationUrl } = useGetConfigurationUrl();
  const { useChangeModelUrlFromCar } = checkout4 || {};
  const carDms = deal?.car?.stock?.dms;
  const isOverride = carDms && !!backOverwrittenBasePath;
  const changeModelUrl = useChangeModelUrlFromCar ? getConfigurationUrl() : configuration.spcURL;

  const backLinkUrl = isOverride ? `${backOverwrittenBasePath}${carDms}` : changeModelUrl;

  return { backLinkUrl, isOverride, useChangeModelUrlFromCar };
};

export default useBackLink;
