import React, { FunctionComponent, useCallback, useState } from 'react';
import Box from '@material-ui/core/Box';
import { ISchemaFieldProps } from '../../model/IJsonSchema';
import { FormLabel, Radio, RadioGroup, Typography } from '@material-ui/core';
import radioButtonStyles from './customRadioButtonStyles';

export const CustomRadioButton: FunctionComponent<ISchemaFieldProps> = ({ formData }) => {
  const styles = radioButtonStyles();
  const {
    value,
    id,
    onChange,
    options: { enumOptions },
    schema,
    label,
  } = formData;

  const [selectedValue, setSelectedValue] = useState(value);

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value);
    onChange(e.target.value);
  }, []);

  const radioButtons = enumOptions.map((option, index) => (
    <Box className={styles.radioButtonContainer} key={index}>
      <Radio
        color={'primary'}
        id={id}
        checked={selectedValue === option.value}
        value={option.value}
        size={'small'}
        onChange={handleChange}
        data-test={`radioButton:${option.value}`}
        name={option.value}
        classes={{
          root: styles.root,
        }}
      />
      <Box className={styles.labelContainer}>
        <Typography variant={'body2'} data-test={`text:enquiryForm:${option.value}`}>
          {option.label}
        </Typography>
        {schema?.disclaimers && (
          <Typography className={styles.disclaimer} data-test={`text:enquiryForm:${option.value}:disclaimer`}>
            {schema.disclaimers[option.value]}
          </Typography>
        )}
      </Box>
    </Box>
  ));

  return (
    <>
      {label && <FormLabel className={styles.title}>{label}</FormLabel>}
      <RadioGroup className={styles.radioButtonGroup}>{radioButtons}</RadioGroup>
    </>
  );
};

export default CustomRadioButton;
