import { gql } from '@apollo/client/core';
import money from './money';

const price = {
  PRICE: gql`
    ${money.MONEY}
    fragment Price on Price {
      money {
        ...Money
      }
      type
      taxes {
        money {
          ...Money
        }
        aggregated
        type
        label
      }
    }
  `,
};
export default price;
