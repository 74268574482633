import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../theme/ICheckoutTheme';

export type NavigationClasses =
  | 'backButton'
  | 'stepNumber'
  | 'stepTitle'
  | 'stepTitleWrapper'
  | 'icon'
  | 'label'
  | 'backButtonContainer'
  | 'innerBackButtonContainer';

const navigationStyles = makeStyles<ICheckoutTheme, NavigationClasses>(
  theme => ({
    navigationContainer: {
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        textAlign: 'center',
      },
    },
    backButtonContainer: {
      display: 'flex',
      padding: '0 30px',
      position: 'absolute',
      left: '0',
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        margin: '5px 10px 0px',
      },
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        width: '100%',
        borderBottom: `1px solid ${theme.palette.text.disabled}`,
        paddingBottom: 10,
        textAlign: 'center',
        position: 'unset',
        margin: '5px 10px 10px',
      },
    },
    innerBackButtonContainer: {
      alignItems: 'center',
      whiteSpace: 'nowrap',
      lineHeight: '14px',
      color: theme.palette.common.white,
      display: 'block',
      padding: `${theme.spacing(2)} ${theme.spacing(2)}`,
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        width: '100%',
      },
    },
    backContainer: {
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        margin: 'auto',
      },
    },
    backButton: {
      display: 'inline-block',
      color: theme.palette.primary.main,

      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
    stepNumber: {
      display: 'inline-block',
      color: theme.palette.primary.main,
      fontFamily: theme.typography.fontFamily2,
      fontWeight: 100,
    },
    stepTitle: {
      display: 'inline-block',
      padding: `0 0 0 ${theme.spacing(1)}`,
      fontWeight: 100,
    },
    stepTitleWrapper: {
      textAlign: 'center',
    },
    icon: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.fontMD,
      paddingTop: '4px',
    },
    label: {
      color: theme.palette.primary.main,
      textTransform: 'uppercase',
      display: 'inline-block',
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
  }),
  { name: 'Checkout_Navigation' },
);

export default navigationStyles;
