import CONSTANTS from './constants';
import IWidgetConfiguration from '../../../../common/model/IWidgetConfiguration';

function timeBasedValue<T>(value: T, date: string): T | undefined {
  return new Date().getTime() < Date.parse(date) ? value : undefined;
}

const DEFAULT_WIDGET_CONFIG: Partial<IWidgetConfiguration> = {
  dimensions: CONSTANTS.DIMENSIONS,
  // @TODO: Remove.
  spcURL: timeBasedValue('https://au-ecom-sit.inchcapedigital.com/pe/sub-dis/cms/spc', '2020-12-15'),
};

// Unset values that are undefined.
Object.keys(DEFAULT_WIDGET_CONFIG).forEach(key => {
  if (DEFAULT_WIDGET_CONFIG[key as keyof IWidgetConfiguration] === undefined) {
    delete DEFAULT_WIDGET_CONFIG[key as keyof IWidgetConfiguration];
  }
});

export default DEFAULT_WIDGET_CONFIG;
