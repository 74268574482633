import { gql } from '@apollo/client/core';

const expressionOfInterest = {
  EXPRESSIONS_OF_INTEREST: gql`
    fragment ExpressionOfInterest on ExpressionOfInterest {
      code
      enabled
      attributes {
        picture
        label
        description
      }
    }
  `,
};
export default expressionOfInterest;
