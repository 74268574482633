import { ApolloError, useQuery } from '@apollo/client';
import dealers from '../graphql/queries/dealers';
import useErrorHandler from '../../../common/hooks/useErrorHandler';
import { GetDealers, GetDealersVariables } from '../graphql/queries/__generated__/GetDealers';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';

export const useDealers: () => { loading: boolean; data: GetDealers; error?: ApolloError } = () => {
  const { deal } = useCheckoutContext();
  const { postCode } = deal?.dealer || {};
  const { data, loading, error } = useQuery<GetDealers, GetDealersVariables>(dealers.GET_DEALERS, {
    variables: {
      postCode,
    },
  });

  useErrorHandler(error);

  return { loading, data, error };
};
