import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type TextareaInputClasses = 'root';

const textareaInputStyles = makeStyles<ICheckoutTheme, TextareaInputClasses>(
  ({ palette }) => ({
    root: {
      background: palette.common.white,
    },
  }),
  { name: 'GqlCheckout_TextareaInput' },
);

export default textareaInputStyles;
