import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import { ISchemaFieldProps } from '../../model/IJsonSchema';
import { Checkbox, Typography, useTheme } from '@material-ui/core';
import checkboxesStyles from './customCheckboxesStyles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ICheckoutTheme } from 'src/common/theme/ICheckoutTheme';
import Translation from '../../../../../common/components/presentation/Translation/Translation';

export const CustomCheckboxes: FunctionComponent<ISchemaFieldProps> = ({ formData }) => {
  const styles = checkboxesStyles();
  const {
    value,
    id,
    onChange,
    options: { enumOptions },
    rawErrors,
  } = formData;
  const { checkoutLite } = useTheme<ICheckoutTheme>();
  const checkboxIconCircle = checkoutLite?.enquiryForm?.checkboxIconCircle;
  const selectedValues = new Set(value);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    selectedValues.has(e.target.name) ? selectedValues.delete(e.target.name) : selectedValues.add(e.target.name);
    onChange(Array.from(selectedValues));
  };

  const checkboxes = enumOptions.map((option, index) => (
    <Box className={styles.checkboxContainer} key={index}>
      <Checkbox
        icon={checkboxIconCircle ? <RadioButtonUncheckedOutlinedIcon /> : <CheckBoxOutlineBlankIcon />}
        checkedIcon={checkboxIconCircle ? <CheckCircleIcon /> : <CheckBoxIcon />}
        color={'primary'}
        id={id}
        checked={selectedValues.has(option.value)}
        value={value}
        size={'small'}
        onChange={handleChange}
        data-test={`checkbox:${option.value}`}
        name={option.value}
        classes={{
          root: styles.root,
        }}
      />
      <Typography variant={'body2'} className={styles.label} data-test={`text:enquiryForm:${option.value}`}>
        <Translation id={option.label} />
      </Typography>
      <Typography className={styles.errorMessage}>{rawErrors && rawErrors[index]} </Typography>
    </Box>
  ));

  return <Box className={styles.checkboxesContainer}>{checkboxes}</Box>;
};

export default CustomCheckboxes;
