import React, { FunctionComponent } from 'react';
import CheckoutLiteRouter from './CheckoutLiteRouter';
import usePlatformConfig from '../react/usePlatformConfig';
import Checkout4LiteRouter from '../../gql/checkout4/components/Checkout4LiteRouter';
import MiniMyAccountComponent from './myAccountDxp/MiniMyAccountComponent';
import dxpDataLayerInitializationHandler from '../../../../common/utilities/dxpDataLayerInitializationHandler';

const WidgetContent: FunctionComponent = () => {
  const {
    platformConfig: {
      platformFeatures: { isCheckout4Enabled },
      myAccount,
      dxpDataLayer,
    },
  } = usePlatformConfig();

  dxpDataLayerInitializationHandler(dxpDataLayer);

  return isCheckout4Enabled ? (
    <>
      {myAccount?.miniMyAccountConfig && <MiniMyAccountComponent />}
      <Checkout4LiteRouter />
    </>
  ) : (
    <CheckoutLiteRouter />
  );
};

export default WidgetContent;
