import urlRoute from '../service/urlRoute';
import IDimensions from '../model/IDimensions';

const MISSING_ENV_STR = '{missing_env}';

const PUB_PATH = urlRoute.joinUrl(
  process.env.SERVER_URL || MISSING_ENV_STR,
  process.env.REACT_CHECKOUT_LITE_PUBLIC_PATH,
);

const CONSTANTS = {
  APP_CONFIG_BASE_URL: process.env.APP_CONFIG_BASE_URL || MISSING_ENV_STR,
  PLATFORM_API_URL: process.env.PLATFORM_API_URL || MISSING_ENV_STR,
  DIGITAL_API_URL: urlRoute.joinUrl(process.env.PLATFORM_API_URL || MISSING_ENV_STR, '/digital-api'),
  BASE_URL: process.env.REACT_APP_DIGITAL_API_URL,

  PLATFORM_API_BASE_URL: process.env.PLATFORM_API_BASE_URL || '',
  REACT_APP_WITH_CREDENTIALS: process.env.REACT_APP_WITH_CREDENTIALS || '',
  CSS_URL: urlRoute.joinUrl(PUB_PATH, '/library/checkout_lite.css'),
  RECAPTCHA_CSS_URL: urlRoute.joinUrl(PUB_PATH, '/css/recaptcha.css'),
  FINANCE_CALCULATOR_WIDGET_BASE_PATH: '/finance-calculator-react/library',
  MANUFACTURER: process.env.REACT_APP_MANUFACTURER || 'Subaru',
  // TODO Will be removed once we have multi-currency parsed into getCurrency from backend side
  AUD: 'AUD',

  PUBLIC_PATH: process.env.SERVER_URL + process.env.REACT_CHECKOUT_LITE_PUBLIC_PATH,

  BASKET_URL: process.env.REACT_APP_INTEGRATION_URL ? process.env.REACT_APP_INTEGRATION_URL + '/basket' : '',
  // Currently this locale code displays both currency and decimals in the required format.
  // If a case presents where the USD and decimal need to be displayed differently,
  // the desired locale code should come from the platform configuration.
  USD_CURRENCY_DEFAULT_LOCALE_CODE: 'en-GB',

  DIMENSIONS: {
    brand: process.env.REACT_APP_BRAND_CODE || '',
    country: process.env.REACT_APP_COUNTRY || '',
    region: process.env.REACT_APP_REGION || '',
    language: process.env.REACT_DEFAULT_LANGUAGE || '',
  },

  BE_DEFAULT_DIMENSIONS: {
    brand: 'subaru',
    country: 'au',
    region: 'au',
    language: 'en',
  } as IDimensions,

  RECAPTCHA_SITE_KEY: process.env.REACT_RECAPTCHA_SITE_KEY,
  RECAPTCHA_SITE_URL: `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_RECAPTCHA_SITE_KEY}`,

  VISANET_CHECKOUT_RESOURCE_URL: process.env.VISANET_CHECKOUT_RESOURCE_URL,

  GTM_ID: process.env.REACT_APP_GTM_ID,

  REACT_DEPENDENCIES:
    process.env.NODE_ENV === 'production'
      ? {
          react:
            process.env.AUTOMOTIVE_WIDGETS_REACT_BASE_URL.replace(/^$/, 'https://cdn.jsdelivr.net/npm/react@16/umd') +
            '/react.production.min.js',
          reactDom:
            process.env.AUTOMOTIVE_WIDGETS_REACT_BASE_URL.replace(
              /^$/,
              'https://cdn.jsdelivr.net/npm/react-dom@16/umd',
            ) + '/react-dom.production.min.js',
          react18:
            process.env.DXP_WIDGETS_REACT_BASE_URL.replace(/^$/, 'https://cdn.jsdelivr.net/npm/react@18/umd') +
            '/react.production.min.js',
          reactDom18:
            process.env.DXP_WIDGETS_REACT_BASE_URL.replace(/^$/, 'https://cdn.jsdelivr.net/npm/react-dom@18/umd') +
            '/react-dom.production.min.js',
        }
      : {
          react: 'https://cdn.jsdelivr.net/npm/react@16/umd/react.development.js',
          reactDom: 'https://cdn.jsdelivr.net/npm/react-dom@16/umd/react-dom.development.js',
          react18: 'https://cdn.jsdelivr.net/npm/react@18/umd/react.development.min.js',
          reactDom18: 'https://cdn.jsdelivr.net/npm/react-dom@18/umd/react-dom.development.min.js',
        },
};

export default CONSTANTS;
