import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';

export type NavigationClasses =
  | 'root'
  | 'wrapper'
  | 'backContainer'
  | 'backButton'
  | 'backLabel'
  | 'backIcon'
  | 'headerContainer'
  | 'headerTitle';

const navigationStyles = makeStyles<ICheckoutTheme, NavigationClasses>(
  ({ breakpoints, typography, palette }) => ({
    root: {
      backgroundColor: palette.primary.main,
      position: 'sticky',
      top: 0,
      zIndex: 3,
    },
    wrapper: {
      margin: 'auto',
      maxWidth: 1440,
    },
    backContainer: {
      position: 'relative',
      [breakpoints.down(breakpoints.values.md)]: {
        backgroundColor: palette.primary.main,
        textAlign: 'center',
      },
    },
    backButton: {
      border: '0 none',
      color: palette.primary.contrastText,
      fontSize: typography.fontSM,
      position: 'absolute',
      top: '15px',
      left: '10px',
      cursor: 'pointer',
      [breakpoints.down(breakpoints.values.md)]: {
        position: 'relative',
        top: 0,
        left: 0,
      },
    },
    backLabel: {
      color: 'inherit',
    },
    backIcon: {
      color: 'inherit',
      fontSize: 'inherit',
    },
    headerContainer: {
      padding: '15px 0',
      border: `1px solid ${palette.background.alternate}`,
    },
    headerTitle: {
      textAlign: 'center',
      fontSize: typography.fontXL,
      lineHeight: 1.2,
      color: palette.primary.contrastText,
    },
  }),

  { name: 'Checkout4_Navigation' },
);

export default navigationStyles;
