import { useCallback, useEffect, useReducer } from 'react';
import { useIntl } from 'react-intl';
import { useTheme } from '@material-ui/core';
import { IEnquiryFormSchema, ISchemaError } from '../model/IJsonSchema';
import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';

export const useCustomErrorMessagesMapper = (formData: IEnquiryFormSchema) => {
  const intl = useIntl();
  const {
    checkoutLite: {
      enquiryForm: { hasCustomErrorPriority, hasCustomPhoneErrorMessage },
    },
  } = useTheme<ICheckoutTheme>();
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const getErrorDomain = (errorPath: string, isParentDomain = false) => {
    const pathArray: string[] = errorPath.split('.');

    return isParentDomain ? pathArray[1] : pathArray[pathArray.length - 1];
  };

  const isFieldDirty = (domain: string, formData?: IEnquiryFormSchema): boolean => {
    const isInCypressIframe = (window as any).Cypress && (window as any).Cypress?.spec;
    if (isInCypressIframe && typeof domain === 'string') return true;

    if (!formData || typeof formData !== 'object') {
      return false;
    }

    if (domain in formData) {
      return !!(formData as any)[domain];
    }

    return Object.values(formData).some(value => {
      return isFieldDirty(domain, value);
    });
  };

  const filterDirtyFields = (errors: ISchemaError[], formData?: IEnquiryFormSchema) => {
    if (!formData) return errors;

    return errors.filter((error: ISchemaError) => {
      const domain = getErrorDomain(error.property);

      return isFieldDirty(domain, formData);
    });
  };

  const prioritizeError = (
    errorDomain: string,
    errorName: string,
    errors: ISchemaError[],
    defaultMessage: string,
  ): string => {
    if (errorDomain === 'phone' && errorName === 'maxLength' && hasCustomPhoneErrorMessage) {
      const errorNames = errors.map((error: ISchemaError) => error.name);
      if (errorNames.includes('maxLength') && (errorNames.includes('regexp') || errorNames.includes('pattern'))) {
        return intl.formatMessage({ id: 'trans__checkout__enquiry_form__error_messages__phone_format' });
      }
    }

    if (errorDomain === 'identificationNumber' && errorName === 'maxLength') {
      const errorIdentificationType = errors.reduce((filteredValue, current) => {
        if (current.property === '.identificationNumberByType.identificationType') {
          filteredValue.push(current.params.allowedValues[0]);
        }
        return filteredValue;
      }, []);
      const errorMaxLength = errors.reduce((filteredValue, current) => {
        if (current.name === 'maxLength') {
          filteredValue.push(current.params.limit);
        }
        return filteredValue;
      }, []);
      if (!errorIdentificationType.some(val => val === 'RUC') && !errorMaxLength.some(val => val === 11)) {
        return intl.formatMessage({
          id: 'trans__checkout__enquiry_form__error_messages__identification_number_format',
        });
      }
    }

    return defaultMessage;
  };

  const getInputValue = (property: string, formData: IEnquiryFormSchema) => {
    const properties: string[] = property.split('.').filter(prop => prop !== '');
    let value: any = formData;

    for (const prop of properties) {
      if (value && typeof value === 'object' && prop in value) {
        value = value[prop];
      } else {
        value = undefined;
        break;
      }
    }

    return value;
  };

  /**
   * Calls a force update function whenever the formData state changes.
   * This is needed because transformErrors callback is called before the latest formData state is updated,
   * so a force update is required to ensure that the latest state is used.
   */
  useEffect(() => {
    forceUpdate();
  }, [formData]);

  return useCallback(
    (errors: ISchemaError[]) =>
      filterDirtyFields(errors, formData)?.map((error: ISchemaError) => {
        switch (error.name) {
          case 'required':
            error.message = intl.formatMessage({ id: 'trans__checkout__enquiry_form__error_messages__required_field' });
            break;
          case 'pattern':
          case 'regexp':
            switch (getErrorDomain(error.property)) {
              case 'firstName':
              case 'lastName':
                error.message = intl.formatMessage({
                  id: 'trans__checkout__enquiry_form__error_messages__alphabetic_format',
                });
                break;
              case 'phone':
                error.message = intl.formatMessage({
                  id: 'trans__checkout__enquiry_form__error_messages__phone_format',
                });
                break;
              case 'identificationNumberRut':
              case 'userId':
                error.message = intl.formatMessage({
                  id: 'trans__checkout__enquiry_form__error_messages__identification_number_format',
                });
                break;
              case 'identificationNumber':
                error.message = intl.formatMessage({
                  id: 'trans__checkout__enquiry_form__error_messages__identification_number_format',
                });
                break;
              case 'email':
                error.message = intl.formatMessage({
                  id: 'trans__checkout__enquiry_form__error_messages__email_format',
                });
                break;
              case 'drivingLicenseDate':
                error.message = intl.formatMessage({
                  id: 'trans__checkout__enquiry_form__error_messages__drivers_license_date',
                });
                break;
              case 'maritalStatus':
                error.message = intl.formatMessage({
                  id: 'trans__checkout__enquiry_form__error_messages__marital_status',
                });
                break;
              case 'model':
                error.message = intl.formatMessage({
                  id: 'trans__checkout__enquiry_form__error_messages__alphabetic_numeric_format',
                });
                break;
              case 'year':
                error.message = intl.formatMessage({
                  id: 'trans__checkout__enquiry_form__error_messages__year_format',
                });
                break;
              case 'mileage':
                error.message = intl.formatMessage({
                  id: 'trans__checkout__enquiry_form__error_messages__mileage_number_format',
                });
                break;
              case 'registrationNumber':
                error.message = intl.formatMessage({
                  id: 'trans__checkout__enquiry_form__error_messages__registration_number_format',
                });
                break;
              default:
                error.message = intl.formatMessage({ id: 'trans__checkout__enquiry_form__error_messages__default' });
                break;
            }
            break;
          case 'minLength':
            switch (getErrorDomain(error.property)) {
              case 'year':
                const value = getInputValue(error.property, formData);
                if (/^\d+$/.test(value)) {
                  error.message = intl.formatMessage({
                    id: 'trans__checkout__enquiry_form__error_messages__min_year_length',
                  });
                } else {
                  error.message = intl.formatMessage({
                    id: 'trans__checkout__enquiry_form__error_messages__year_format',
                  });
                }
                break;
              case 'registrationNumber':
                error.message = intl.formatMessage({
                  id: 'trans__checkout__enquiry_form__error_messages__registration_number_format',
                });
                break;
              default:
                error.message = intl.formatMessage({ id: 'trans__checkout__enquiry_form__error_messages__default' });
            }
            break;
          case 'maxLength':
            error.message = intl.formatMessage(
              { id: 'trans__checkout__enquiry_form__error_messages__max_length' },
              { limit: error.params?.limit },
            );
            if (hasCustomErrorPriority) {
              error.message = prioritizeError(
                getErrorDomain(error.property),
                'maxLength',
                errors,
                intl.formatMessage(
                  { id: 'trans__checkout__enquiry_form__error_messages__max_length' },
                  { limit: error.params?.limit },
                ),
              );
            }
            break;
          default:
            error.message = intl.formatMessage({
              id: 'trans__checkout__enquiry_form__error_messages__default',
              defaultMessage: ' ',
            });
            break;
        }

        return error;
      }),
    [formData],
  );
};
