import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type RadioButtonClasses =
  | 'root'
  | 'radioButtonContainer'
  | 'radioButtonGroup'
  | 'errorMessage'
  | 'disclaimer'
  | 'labelContainer'
  | 'title';

const radioButtonStyles = makeStyles<ICheckoutTheme, RadioButtonClasses>(
  ({ typography, palette }) => ({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      width: '40px',
      height: '40px',
    },
    radioButtonContainer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: typography.fontSM,
    },
    radioButtonGroup: {
      display: 'flex',
      flexDirection: 'column',
    },
    errorMessage: {
      color: palette.error.main,
      fontSize: typography.fontXS,
      flexDirection: 'column',
      position: 'absolute',
      margin: '35px 0 0 10px',
    },
    disclaimer: {
      fontSize: typography.fontXS,
    },
    labelContainer: {},
    title: {
      color: palette.text.primary,
      marginBottom: 6,
    },
  }),
  { name: 'GqlCheckout_RadioButton' },
);

export default radioButtonStyles;
