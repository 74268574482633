import React, { FunctionComponent, useEffect, useState, useMemo } from 'react';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import payment2C2PStyles from './payment2C2PStyles';
import Payment2C2P from './Payment2C2P';
import DefaultButton from '../../../../../../common/components/presentation/Buttons/DefaultButton';
import Translation from '../../../../../../common/components/presentation/Translation/Translation';
import { usePayment2C2P } from '../../../hooks/usePayment2C2P';
import { TransactionErrorTypes } from '../../../../../../common/enum/TransactionErrorTypes';
import { GetDeal_deal } from '../../../../../common/graphql/queries/__generated__/GetDeal';
import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';
import { ScrollInViewBehaviourType } from '../../../../../../common/enum/ScrollInViewTypes';
import usePlatformConfig from '../../../../../../common/react/usePlatformConfig';
import { JourneyType } from '../../../../../../__generated__/globalTypes';
import SoftLockModal from '../../../../../checkout4/components/softLockModal/softLockModal';
import usePriceFormatter from '../../../../../../../../common/react/usePriceFormatter';
import useWidgetContext from '../../../../../../common/react/useWidgetContext';

export interface IPayment2C2PContainer {
  goToNextStep: () => void;
  deal: GetDeal_deal;
  loading?: boolean;
}

const Payment2C2PContainer: FunctionComponent<IPayment2C2PContainer> = ({ goToNextStep, deal, loading }) => {
  const styles = payment2C2PStyles();
  const {
    configuration: { dimensions },
  } = useWidgetContext();
  const [hideIframe, setHideIframe] = useState<boolean>(false);
  const {
    platformConfig: {
      platformFeatures: { isCheckout4Enabled },
      priceDisplay: {
        primary: { currency, decimalScale, currencyFormatConfig },
      },
      payment: { isCoeCategoryAmountEnabled },
    },
  } = usePlatformConfig();
  const priceFormatter = usePriceFormatter({
    currency,
    dimensions: {
      country: dimensions.country,
      language: dimensions.language,
    },
    decimals: decimalScale,
    currencyFormatConfig,
  });

  const checkTransaction = localStorage.getItem('isTransactionComplete');
  const isAuthorized = localStorage.getItem('lastPaymentStatus') === TransactionErrorTypes.AUTHORIZED;
  const {
    initData,
    initError,
    btnLoading,
    handlePayment,
    isPaymentFailed,
    isTransactionComplete,
    isStatusLoading,
    softLocked,
    softLockedErrorMsg,
    isErrorPaymentInitSoftLock,
    handleCloseSoftLockModal,
  } = usePayment2C2P(deal, loading);
  const theme = useTheme<ICheckoutTheme>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleIframeChange = () => {
    if (isTransactionComplete) {
      setHideIframe(true);
    }
  };

  useEffect(() => {
    if (!isAuthorized && checkTransaction === 'true' && hideIframe) {
      window.top.location = window.location;
    }
  }, [hideIframe]);

  useEffect(() => {
    localStorage.setItem('isTransactionComplete', 'false');
  }, []);

  useEffect(() => {
    if (isAuthorized && isTransactionComplete) {
      goToNextStep();
    }
  }, [isStatusLoading]);

  useEffect(() => {
    if (isCheckout4Enabled && !initData && isMobile) {
      const continueButton = document.querySelector('[data-test="button:checkout:2C2P"]');

      if (continueButton) {
        continueButton.scrollIntoView({
          block: 'center',
          inline: 'center',
          behavior: ScrollInViewBehaviourType.SMOOTH,
        });
      }
    }
  }, [initData, isStatusLoading, isMobile, isCheckout4Enabled]);

  const buttonText = useMemo(() => {
    if (isPaymentFailed) {
      return 'trans__checkout__payment_page__try_again_CTA';
    }

    if (deal?.journeyType === JourneyType.online_order) {
      return 'trans__checkout__next_step__payment_page__proceed_to_order_CTA';
    }

    return 'trans__checkout__payment_page__pay_CTA';
  }, [deal, isPaymentFailed]);

  const amount = useMemo(() => {
    if (isCoeCategoryAmountEnabled && deal.journeyType === JourneyType.online_order) {
      const categoryAmount = deal?.car?.attributes?.coeCategoryAmount?.find(
        ceoCategoryAmount => ceoCategoryAmount.currency === currency,
      );

      if (categoryAmount) {
        return priceFormatter.format(Number(categoryAmount.amount));
      }
    }

    return '';
  }, [deal, currency, isCoeCategoryAmountEnabled]);

  if (isStatusLoading || isAuthorized) return null;

  return (
    <Box className={styles.container}>
      <Payment2C2P initError={initError} isPaymentFailed={isPaymentFailed} paymentAmount={amount} />
      {!initData ? (
        <DefaultButton
          className={[styles.payButton]}
          dataTest="button:checkout:2C2P"
          variant="contained"
          disabled={btnLoading}
          onClick={handlePayment}
        >
          {amount ? <Translation id={buttonText} values={{ amount }} /> : <Translation id={buttonText} />}
        </DefaultButton>
      ) : (
        <Box className={hideIframe ? styles.hiddenFrame : styles.iframeContainer}>
          <iframe data-test="payment:2C2P:iframe" src={initData.url} onLoad={handleIframeChange} />
        </Box>
      )}
      <SoftLockModal
        isOpen={softLocked}
        isErrorPaymentInitSoftLock={isErrorPaymentInitSoftLock}
        softLockedErrorMsg={softLockedErrorMsg}
        onClose={() => handleCloseSoftLockModal(false)}
      />
    </Box>
  );
};

export default Payment2C2PContainer;
