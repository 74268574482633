import { gql } from '@apollo/client/core';

const dealer = {
  DEALER_DETAILS: gql`
    fragment DealerDetails on Dealer {
      externalId
      label
      postCode
      address {
        address
        city
        phone
      }
    }
  `,
};

export default dealer;
