import React, { FunctionComponent, useCallback } from 'react';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import paymentTermsAndConditionModalStyles from './paymentTermsAndConditionsModalStyles';

type PaymentTermsAndConditionsModalProps = {
  isOpen: boolean;
  setIsOpen: (newValue: boolean) => void;
  setIsAccepted: (newValue: boolean) => void;
};

const PaymentTermsAndConditionsModal: FunctionComponent<PaymentTermsAndConditionsModalProps> = ({
  isOpen,
  setIsOpen,
  setIsAccepted,
}) => {
  const styles = paymentTermsAndConditionModalStyles();

  const handleAccept = useCallback(() => {
    setIsAccepted(true);
    setIsOpen(false);
  }, [setIsAccepted, setIsOpen]);

  const handleReject = useCallback(() => {
    setIsAccepted(false);
    setIsOpen(false);
  }, [setIsAccepted, setIsOpen]);

  return (
    <Dialog
      open={isOpen}
      className={styles.dialog}
      classes={{
        paper: styles.dialogPaper,
      }}
      data-test={'checkout:payment:terms:modal'}
    >
      <DialogTitle className={styles.dialogTitle}>
        <Box className={styles.dialogTitleContent}>
          <Typography variant={'h4'} data-test={'checkout:payment:terms:title'} className={styles.title}>
            <Translation id={'trans__checkout__next_step__payment_page_disclaimer__terms_and_condition_label'} />
          </Typography>
          <IconButton
            className={styles.iconButton}
            onClick={handleReject}
            data-test={'checkout:payment:terms:modal:close'}
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent className={styles.dialogContent}>
        <Typography className={styles.disclaimerText} data-test={'checkout:payment:terms:content'}>
          <Translation id={'trans__checkout__next_step__payment_page_disclaimer__terms_and_condition_modal'} />
        </Typography>
      </DialogContent>
      <DialogActions className={styles.dialogActions}>
        <Button
          color="primary"
          variant="contained"
          className={styles.acceptButton}
          onClick={handleAccept}
          data-test={'checkout:payment:terms:modal:accept'}
        >
          <Typography variant={'caption'}>
            <Translation
              id={'trans__checkout__next_step__payment_page_disclaimer__terms_and_condition_modal__agreement_CTA'}
            />
          </Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentTermsAndConditionsModal;
