import React, { FunctionComponent } from 'react';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import paymentSummaryStyles from './paymentSummaryStyles';
import { Box, Typography } from '@material-ui/core';
import { LockRounded } from '@material-ui/icons';

type JourneyLabelProps = {
  summary?: boolean;
};

const PaymentSummary: FunctionComponent<JourneyLabelProps> = ({ summary }) => {
  const styles = paymentSummaryStyles({ summary });

  const paymentTransactionLabel = () => (
    <>
      <LockRounded className={styles.icon} />
      <Translation
        className={styles.transactionLabel}
        id="trans__checkout__next_step__payment_page__transaction_label"
      />
    </>
  );

  return (
    <Box className={styles.container} data-test="online_order:disclaimer_transaction_label">
      {summary ? (
        paymentTransactionLabel()
      ) : (
        <Typography className={styles.transactionLabelContainer}>{paymentTransactionLabel()}</Typography>
      )}
    </Box>
  );
};

export default PaymentSummary;
