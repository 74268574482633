import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import dealerDistanceStyles from './dealerDistanceStyles';
import Translation from '../../../../../../../common/components/presentation/Translation/Translation';
import useCurrentLocationPosition from '../../../../hooks/useCurrentLocationPosition';
import { distance } from '../../../../service/distance';
import { GEOLOCATION } from '../../../../constants/geolocation';
import { GetDealers_dealers_nodes } from '../../../../graphql/queries/__generated__/GetDealers';

export interface IDealerDistanceProps {
  dealer: GetDealers_dealers_nodes;
}

const DealerDistance: FunctionComponent<IDealerDistanceProps> = ({ dealer }) => {
  const styles = dealerDistanceStyles();
  const { currentPosition, error } = useCurrentLocationPosition();

  const getDistance = ({ latitude, longitude }: GetDealers_dealers_nodes) => {
    if (dealer && currentPosition) {
      return distance.calculateDistance(currentPosition, {
        latitude,
        longitude,
      });
    }

    return null;
  };

  return (
    <Box className={styles.dealerDistance} data-test={'text:dealer:distance'}>
      {currentPosition && (
        <>
          <Typography variant={'subtitle2'} component={'span'}>
            {(getDistance(dealer) / 1000).toFixed(2)}
          </Typography>
          <Typography variant={'subtitle2'} component={'span'} className={styles.dealerDistanceUnit}>
            <Translation id={'trans__checkout__dealers_page__distance_label'} />
          </Typography>
        </>
      )}
      {error === GEOLOCATION.NOT_SUPPORTED && (
        <Typography variant={'subtitle2'} component={'span'} className={styles.geoNotSupported}>
          <Translation id={'trans__checkout__dealers_page__geolocation_not_supported_message'} />
        </Typography>
      )}
    </Box>
  );
};

export default DealerDistance;
