import { Grid, Box, useTheme } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import Form from 'react-jsonschema-form';
import CustomComponentsMapper from '../CustomComponentsMapper';
import enquiryFormStyles from './enquiryFormStyles';
import BleedContainer from '../../../../../common/components/presentation/Misc/BleedContainer';
import { useCustomErrorMessagesMapper } from '../../hooks/useCustomErrorMessagesMapper';
import errorMessageHoc, {
  IErrorMessageHocProps,
} from '../../../../../common/components/presentation/Error/errorMessageHoc';
import ObjectFieldTemplate from '../objectFieldTemplate/ObjectFieldTemplate';
import MinimalLoader from '../../../../../common/components/presentation/MinimalLoader/MinimalLoader';
import ButtonSubmit from '../buttonSubmit/ButtonSubmit';
import { IEnquiryForm } from '../../../../../enquiryForm/model/IJsonSchema';
import { useEnquireFormData } from '../../hooks/useEnquiryFormData';
import useEnquiryFormValidate from '../../hooks/useEnquiryFormValidate';
import useEnquiryFormSubmit from '../../hooks/useEnquiryFormSubmit';
import collectedFilters from '../../../../../common/service/collectedFilters';
import ThemedErrorMessage from '../../../../../common/components/presentation/Error/ThemedErrorMessage';
import { JourneyType } from '../../../../../__generated__/globalTypes';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';
import { activeJourneyType } from '../../../../common/graphql/cache';
import StaticDisclaimer from './StaticDisclaimer';
import useRecaptcha from '../../../../../common/hooks/useRecaptcha';
import usePlatformConfig from '../../../../../common/react/usePlatformConfig';
import MyAccountMiniContainer from '../../../../../common/components/myAccountDxp/MyAccountMiniContainer';
import MyAccountChangeDetailsCta from './MyAccountChangeDetailsCta';
import useToken from '../../../../common/hooks/useToken';

interface IOwnProps {
  journeyType: JourneyType;
  stateId?: string;
  goToNextStep: () => void;
}

type Props = IErrorMessageHocProps & IOwnProps;

const EnquiryForm: FunctionComponent<Props> = ({ journeyType, stateId, goToNextStep }) => {
  const { getCurrentToken } = useToken();
  const authToken = getCurrentToken();
  const {
    platformConfig: {
      platformFeatures: { isCheckout4Enabled, myAccountDxpIntegration },
    },
  } = usePlatformConfig();
  const styles = enquiryFormStyles({ isCheckout4Enabled });
  const {
    checkoutLite: {
      enquiryForm: { hasCustomValidator },
    },
  } = useTheme<ICheckoutTheme>();
  const {
    filters: { token },
  } = collectedFilters();
  const { formData, handleFormChange, formSchema, uiSchema, requiredFields, error, refetch } = useEnquireFormData(
    token,
    stateId,
  );

  const {
    hasErrors,
    asyncErrors,
    handleValidationErrors,
    handleAsyncValidationErrors,
    resetAsyncErrors,
    customValidation,
  } = useEnquiryFormValidate(requiredFields);
  const { onSubmit, isSubmitting } = useEnquiryFormSubmit(handleAsyncValidationErrors, token, goToNextStep, stateId);
  const customErrorMessageMapper = useCustomErrorMessagesMapper(formData);
  const customWidgets = useMemo(() => CustomComponentsMapper(), []);

  const onChange = useCallback(
    (data: IEnquiryForm) => {
      if (asyncErrors !== null) {
        resetAsyncErrors();
        data.errors = null;
      }

      handleFormChange(data.formData);
      handleValidationErrors(data);
    },
    [asyncErrors, requiredFields],
  );

  useEffect(() => {
    if (journeyType) {
      activeJourneyType(journeyType);
    }
  }, []);
  useRecaptcha();

  if (error) {
    return <ThemedErrorMessage fullPage={true} error={error} />;
  }

  if (!formData || !formSchema || !uiSchema) {
    return <MinimalLoader />;
  }

  return (
    <BleedContainer>
      {myAccountDxpIntegration && (
        <Box className={styles.myAccountIntegrationContainer}>
          {!!authToken ? (
            <MyAccountChangeDetailsCta refetchEnquiryForm={refetch} />
          ) : (
            <Box className={styles.signinButton}>
              <MyAccountMiniContainer dataTestId={'checkout:enquiry:myaccountmini'} loginInModal={true} />
            </Box>
          )}
        </Box>
      )}
      <Grid
        container={true}
        data-test="container:checkout:enquiryForm"
        justifyContent="center"
        className={styles.formWrapper}
      >
        <Form
          schema={formSchema}
          uiSchema={uiSchema}
          formData={formData}
          submitOnEnter={true}
          onSubmit={onSubmit}
          onChange={onChange}
          liveValidate={true}
          showErrorList={false}
          transformErrors={customErrorMessageMapper}
          widgets={customWidgets}
          ObjectFieldTemplate={ObjectFieldTemplate}
          extraErrors={asyncErrors}
          validate={hasCustomValidator ? customValidation : undefined}
        >
          <ButtonSubmit disabled={hasErrors || isSubmitting} journeyType={journeyType} />
          <StaticDisclaimer />
        </Form>
      </Grid>
    </BleedContainer>
  );
};

export default errorMessageHoc(EnquiryForm);
