export const USD_CURRENCY_DEFAULT_LOCALE_CODE: string = 'en-GB';
// @TODO: Various versions of this service are implemented in FC, SPC.
// This one is implemented only in Checkout_lite and mySummary
// Implement this in all widgets
const locale = {
  getLocaleCode(language: string | null, country: string | null): string | null {
    if (!language || !country) {
      return null;
    }
    if (2 === language.length) {
      return `${ language.toLowerCase() }-${ country.toUpperCase() }`;
    }
    const languageParts = language.split('-');
    if (2 === languageParts.length) {
      return `${ languageParts[0].toLowerCase() }-${ languageParts[1].toUpperCase() }`;
    }

    return language;
  },

  normalizeLanguageCode(language: string | null): string | null {

    if (0 < language.indexOf('-')) {
      return language.split('-')[0];
    }

    return language;
  },

  getSymbolFromCurrency(
    currency: string,
    mapperFunction: (currency: string) => string): string {
      if (currency  === 'USD') {
        return 'US' + mapperFunction(currency);
      };

      if(currency === 'PEN') {
        return  'S/';
      };

      return mapperFunction(currency);
  },

  getCurrencyLocaleCode(language: string, country: string, currency: string): string {
    // Needed to display the dollar symbol in the required format.
    // @TODO: Consider moving this to platform config in case more cases like these are found.
    if ('USD' === currency && country !== 'pe' && country !== 'cr' && country !== 'pa') {
      return USD_CURRENCY_DEFAULT_LOCALE_CODE;
    }

    return locale.getLocaleCode(language, country);
  },
};

export default locale;
