const API_ROUTES = {
  PLATFORM_FEATURES: 'multi-dimensions/api/frontend/platform-features',
  ENQUIRY_FORM: '/checkout-light/enquiry-form',
  CURRENT_DEAL_PATH: '/current/deal',
  SAVE_DEAL: '/deals/save',
  IS_LOGGED: '/customers/is-logged',
  PAYMENT_ACCESS_CODE_PATH: '/payment/initialize',
  PAYMENT_VERIFY_PATH: '/payment/verify',
  CREATE_APPOINTMENT: '/checkout-light/finish-continue-in-dealership',
};

export default API_ROUTES;
