import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type InitPaymentErrorClasses = 'translation';

const initPaymentErrorStyles = makeStyles<ICheckoutTheme, InitPaymentErrorClasses>(
  ({ typography }) => ({
    translation: {
      minHeight: '50px',
      lineHeight: '40px',
      fontWeight: typography.fontWeightLight,
      display: 'block',
    },
  }),
  { name: 'Gql_InitPaymentError' },
);

export default initPaymentErrorStyles;
