import React, { FunctionComponent } from 'react';

const CartIcon: FunctionComponent = () => {
  return (
    <svg width="24" height="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 20">
      <path
        d="M0.969697 4.08935e-05C0.712517 4.08935e-05 0.465871 0.102205 0.284018 0.284059C0.102164 0.465912 0 0.712558 0 0.969738C0 1.22692 0.102164 1.47356 0.284018 1.65542C0.465871 1.83727 0.712517 1.93943 0.969697 1.93943H3.12242L5.66691 12.1213C5.88315 12.9843 6.656 13.5758 7.54521 13.5758H19.6373C20.513 13.5758 21.2567 12.994 21.4865 12.1503L24 2.90913H21.9695L19.6364 11.6364H7.54424L5.00073 1.45459C4.89566 1.03687 4.65345 0.666492 4.3129 0.402769C3.97235 0.139046 3.55314 -0.00276648 3.12242 4.08935e-05H0.969697ZM18.4242 13.5758C16.8291 13.5758 15.5152 14.8897 15.5152 16.4849C15.5152 18.08 16.8291 19.394 18.4242 19.394C20.0194 19.394 21.3333 18.08 21.3333 16.4849C21.3333 14.8897 20.0194 13.5758 18.4242 13.5758ZM9.69697 13.5758C8.10182 13.5758 6.78788 14.8897 6.78788 16.4849C6.78788 18.08 8.10182 19.394 9.69697 19.394C11.2921 19.394 12.6061 18.08 12.6061 16.4849C12.6061 14.8897 11.2921 13.5758 9.69697 13.5758ZM12.6061 4.08935e-05V4.84853H9.69697L13.5758 8.72731L17.4545 4.84853H14.5455V4.08935e-05H12.6061ZM9.69697 15.5152C10.2439 15.5152 10.6667 15.938 10.6667 16.4849C10.6667 17.0318 10.2439 17.4546 9.69697 17.4546C9.15006 17.4546 8.72727 17.0318 8.72727 16.4849C8.72727 15.938 9.15006 15.5152 9.69697 15.5152ZM18.4242 15.5152C18.9712 15.5152 19.3939 15.938 19.3939 16.4849C19.3939 17.0318 18.9712 17.4546 18.4242 17.4546C17.8773 17.4546 17.4545 17.0318 17.4545 16.4849C17.4545 15.938 17.8773 15.5152 18.4242 15.5152Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CartIcon;
