import React, { FunctionComponent } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { IObjectFieldProperty } from '../../model/IJsonSchema';
import EnquiryFormTitle from '../enquiryFormTitle/EnquiryFormTitle';
import objectFieldTemplateStyles from './objectFieldTemplateStyles';
export interface IObjectFieldProps {
  properties: IObjectFieldProperty[];
  title: string;
  idSchema: {
    $id: string;
  };
}

export const ObjectFieldTemplate: FunctionComponent<IObjectFieldProps> = props => {
  const styles = objectFieldTemplateStyles();
  const {
    properties,
    idSchema: { $id },
    title,
  } = props;
  const isRoot: boolean = $id === 'root';

  return (
    <>
      {isRoot && <EnquiryFormTitle title={title} />}
      <Grid container={true} direction={isRoot ? 'column' : 'row'}>
        {properties?.map((field: IObjectFieldProperty, index: number) => {
          const fieldUiSchema = field?.content?.props?.uiSchema?.['ui:options'];
          const width =
            typeof fieldUiSchema?.width === 'string' ? parseInt(fieldUiSchema?.width, 10) : fieldUiSchema?.width;
          const isHidden = !!fieldUiSchema?.hide;
          // Rows with a single field have a single string/boolean as form data and not objects as rows with multiple fields.
          const hasSingleField = ['string', 'boolean'].includes(typeof field?.content?.props?.formData);

          if (isHidden) {
            return null;
          }

          if (isRoot) {
            return (
              <Grid item={true} xs={12} key={index} className={hasSingleField ? styles.singleField : styles.root}>
                <Typography component={'span'} variant={'body2'}>
                  {field.content}
                </Typography>
              </Grid>
            );
          }

          return (
            <Grid
              item={true}
              key={index}
              xs={12}
              sm={width || (properties.length > 2 ? 4 : 6)}
              className={styles.child}
            >
              {field.content}
            </Grid>
          );
        })}
      </Grid>
    </>
  );
};

export default ObjectFieldTemplate;
