import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type ButtonSubmitClasses = 'submitButtonContainer' | 'btnContained' | 'submitButton' | 'newContainedPrimary';

const buttonSubmitStyles = makeStyles<ICheckoutTheme, ButtonSubmitClasses>(
  ({ palette, typography, breakpoints }) => ({
    submitButtonContainer: {
      display: 'block',
      float: 'right',
      margin: '20px 10px',

      [breakpoints.down('sm')]: {
        float: 'unset',
        display: 'flex',
        justifyContent: 'center',
      },
    },
    btnContained: {},
    submitButton: {
      color: palette.common.white,
      textTransform: 'capitalize',
      width: 250,
      '& > span': {
        fontSize: typography.fontMD,
      },
    },
    newContainedPrimary: {},
  }),
  { name: 'GqlCheckout_ButtonSubmit' },
);

export default buttonSubmitStyles;
