import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type DealerPageTitleClasses = 'titleContainer' | 'title';

const dealerPageTitleStyles = makeStyles<ICheckoutTheme, DealerPageTitleClasses>(
  theme => ({
    titleContainer: {
      display: 'flex',
      verticalAlign: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: '40px 0',
    },
    title: {
      fontWeight: 'bold',
      alignSelf: 'center',
      textTransform: 'uppercase',
      color: theme.palette.text.primary,
    },
  }),
  { name: 'GqlCheckout_DealerPageTitle' },
);

export default dealerPageTitleStyles;
