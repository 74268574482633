import React, { FC } from 'react';
import { Container, ContainerProps } from '@material-ui/core';
import bleedContainerStyles from './bleedContainerStyles';
import clsx from 'clsx';

const BleedContainer: FC<ContainerProps> = ({ children, ...props }) => {
  const style = bleedContainerStyles();
  const { classes } = props;
  return (
    <Container className={clsx([style.root, classes?.root])} {...props}>
      {children}
    </Container>
  );
};

export default BleedContainer;
