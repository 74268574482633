import { Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import OutOfStockPaymentErrorStyles from './initPaymentErrorStyles';

const OutOfStockPaymentError: FunctionComponent = () => {
  const styles = OutOfStockPaymentErrorStyles();
  return (
    <Typography data-test="checkout:webpay:out_of_stock" variant="subtitle2">
      <Translation className={styles.translation} id={'trans__checkout__payment_page__out_of_stock_message'} />
    </Typography>
  );
};

export default OutOfStockPaymentError;
