import { JourneyTypes } from '../../../../../../common/enum/JourneyTypes';
import { IActiveStep, INavigationStep } from '../model/INavigationSteps';

const navigation = {
  getActiveStep: (pathname: string, steps: INavigationStep[], reserveOnlineJourney: boolean): IActiveStep => {
    let activeStep = {
      index: 0,
      translationKey: '',
    };
    const journeyType = reserveOnlineJourney ? JourneyTypes.RESERVE_ONLINE : JourneyTypes.CONTINUE_IN_DEALERSHIP;

    steps.forEach((step, index) => {
      if (step.pathname === pathname) {
        activeStep = {
          index,
          translationKey: `trans__checkout__general__title_${journeyType}_${step.label}`,
        };
      }
    });

    return activeStep;
  },
};

export default navigation;
