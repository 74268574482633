import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';

export type FinanceAndInsuranceSummaryClasses = 'summaryLabel';

const financeAndInsuranceSummaryStyles = makeStyles<ICheckoutTheme, FinanceAndInsuranceSummaryClasses>(
  theme => ({
    summaryLabel: {
      fontFamily: theme.typography.fontFamily,
    },
  }),
  { name: 'Checkout4_FinanceAndInsuranceSummary' },
);

export default financeAndInsuranceSummaryStyles;
