import { CurrencyPosition } from "../enum/CurrencyPosition";
import { PricePart } from "../enum/PricePart";
import { ICurrencyFormatConfig } from "../model/ICurrencyFormatConfig";

const getFormatCurrency = (
  amount: number,
  priceArgs: Intl.NumberFormatOptions,
  currencyLocale: string,
  currencyFormatConfig: ICurrencyFormatConfig
) => {
  const { currency, currencyPosition } = currencyFormatConfig;
  const numberFormat = new Intl.NumberFormat(currencyLocale, priceArgs);
  let parts = numberFormat.formatToParts(amount);

  if (
    currency &&
    currencyPosition &&
    [CurrencyPosition.START, CurrencyPosition.END].includes(currencyPosition)
  ) {
    const currencyPartIndex = parts.findIndex(
      (part) => part.type === PricePart.CURRENCY
    );
    if (currencyPartIndex > -1) {
      const currencyPart = parts.splice(currencyPartIndex, 1)[0];

      if (currencyFormatConfig.currencyPosition === CurrencyPosition.START) {
        parts.unshift(currencyPart);
      } else {
        parts.push(currencyPart);
      }
    }
  }

  return parts.reduce((price, part) => {
    price += currencyFormatConfig[part.type as PricePart] || part.value;

    return price;
  }, "");
};

const priceFormatterFactory = {
  create(
    priceArgs: Intl.NumberFormatOptions,
    currencyLocale: string,
    currencyFormatConfig?: ICurrencyFormatConfig
  ): Pick<Intl.NumberFormat, "format"> {

    // use config management currency formatter if available
    if (!!currencyFormatConfig) {
      return {
        format(value: number): string {
          return getFormatCurrency(
            value,
            priceArgs,
            currencyLocale,
            currencyFormatConfig
          );
        },
      };
    }

    // to remove whitespace in CO currency
    if (currencyLocale === "es-CO") {
      return {
        format: (price: number) =>
          new Intl.NumberFormat(currencyLocale, priceArgs)
            .format(price)
            .replace(/\s+/g, "")
            // On Chrome Android instead of $, we get COP
            .replace("COP", "$"),
      };
    }

    if (currencyLocale === "es-CL") {
      return {
        format: (price: number) =>
          new Intl.NumberFormat(currencyLocale, priceArgs)
            .format(price)
            .replace(/\s+/g, "")
            // On Chrome Android instead of $, we get CLP
            .replace("CLP", "$"),
      };
    }

    // AR currency: add dot when >= 1000 and <10000
    if (currencyLocale === "es-AR" && priceArgs.currency === "ARS") {
      return {
        format: (price: number) => {
          let result = new Intl.NumberFormat(currencyLocale, priceArgs).format(
            price
          );
          // On iOS it's formatted correctly, it does not need a dot anymore
          if (
            price >= 1000 &&
            price < 10000 &&
            result.toString().indexOf(".") < 0
          ) {
            result = `${result.slice(0, result.length - 6)}.${result.slice(
              result.length - 6
            )}`;
          }
          return result.replace("ARS", "$");
        },
      };
    }

    if (priceArgs.currency === "HKD") {
      return {
        format: (price: number) =>
          new Intl.NumberFormat(currencyLocale, priceArgs)
            .format(price)
            .replace("HK", ""),
      };
    }

    //@TODO: To be removed once there is actual value and correct currency.
    if (priceArgs.currency === "AUD" && currencyLocale === "en-SG") {
      return {
        format: (price: number) =>
          new Intl.NumberFormat(currencyLocale, priceArgs)
            .format(price)
            .replace("A", ""),
      };
    }

    if (priceArgs.currency === "PEN") {
      return {
        format: (price: number) =>
          new Intl.NumberFormat(currencyLocale, priceArgs)
            .format(price)
            .replace("PEN", "S/"),
      };
    }

    if (priceArgs.currency === "BND") {
      return {
        format: (price: number) =>
          new Intl.NumberFormat(currencyLocale, priceArgs)
            .format(price)
            .replace(/\s+/g, "")
            .replace("BND", "B$"),
      };
    }

    // On Chrome Android Intl.NumberFormat it doesn't work properly for all country+currency codes
    if (priceArgs.currency === "USD" && currencyLocale === "es-PE") {
      return {
        format: (price: number) => {
          let result = new Intl.NumberFormat(currencyLocale, priceArgs).format(
            price
          );
          // On Chrome Android Intl.NumberFormat it doesn't work properly for all country+currency codes
          if (!result.includes("US")) {
            return result.replace("$", "US$");
          }
          return result.replace(/\s+/g, "").replace("USD", "US$");
        },
      };
    }

    // On Chrome Android Intl.NumberFormat it doesn't work properly for all country+currency codes
    if (priceArgs.currency === "USD" && currencyLocale === "es-AR") {
      return {
        format: (price: number) => {
          let result = new Intl.NumberFormat(currencyLocale, priceArgs).format(
            price
          );
          if (!result.includes("US")) {
            return result.replace(/\s+/g, "").replace("$", "US$");
          }
          return result.replace(/\s+/g, "");
        },
      };
    }

    if (
      priceArgs.currency === "USD" &&
      (currencyLocale === "es-CR" || currencyLocale === "es-PA")
    ) {
      return {
        format: (price: number) => {
          let result = new Intl.NumberFormat(currencyLocale, priceArgs)
            .format(price)
            .replace(/\s+/g, "");

          if (currencyLocale === "es-CR" && price >= 1000) {
            if (result.toString().indexOf(".") < 0) {
              result = `${result.slice(0, result.length - 3)},${result.slice(
                result.length - 3
              )}`;
            } else {
              result = result.replace(".", ",");
            }
          }

          return result.replace("USD", "$");
        },
      };
    }

    if (
      priceArgs.currency === "EUR" &&
      (currencyLocale === "fi-FI" ||
        currencyLocale === "lt-LT" ||
        currencyLocale === "lv-LV" ||
        currencyLocale === "et-EE")
    ) {
      return {
        format: (price: number) =>
          new Intl.NumberFormat(currencyLocale, priceArgs)
            .format(price)
            .replace(/\B(?=(\d{3})+(?!\d))/g, " ")
            .replace(",", ".")
            .replace(/\s+(?=\d)+/g, ","),
      };
    }

    return new Intl.NumberFormat(currencyLocale, priceArgs);
  },
};

export default priceFormatterFactory;
