import { gql } from '@apollo/client/core';

const appointment = {
  APPOINTMENT_DETAILS: gql`
    fragment AppointmentDetails on Appointment {
      preferredDate
      preferredTime
      comments
      attributes {
        salesConsultant
      }
    }
  `,
};

export default appointment;
