import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import dealerPageInfoStyles from './dealerPageInfoStyles';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import useCheckoutContext from '../../../../common/hooks/useCheckoutContext';

const DealerPageInfo: FunctionComponent = () => {
  const { isStock } = useCheckoutContext();
  const infoTranslationId = isStock
    ? 'trans__checkout__dealers_page__dealer_step_with_stock_description'
    : 'trans__checkout__dealers_page__dealer_step_non_stock_description';
  const styles = dealerPageInfoStyles();

  return (
    <Box className={styles.infoContainer} data-test="checkout:dealer:infoContainer">
      <Typography variant="caption" className={styles.info} data-test="text:dealer:info">
        <Translation id={infoTranslationId} />
      </Typography>
    </Box>
  );
};

export default DealerPageInfo;
