import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type ExpressionsOfInterestItemButtonClasses =
  | 'buttonWrapper'
  | 'button'
  | 'buttonLabel'
  | 'isSelected'
  | 'outlinedPrimary';

const itemButtonStyles = makeStyles<ICheckoutTheme, ExpressionsOfInterestItemButtonClasses>(
  ({ palette, typography }) => ({
    buttonWrapper: {
      padding: '15px',
    },
    button: {
      borderRadius: 0,
      borderColor: palette.primary.main,
      color: palette.primary.main,
      height: 40,
      maxWidth: '300px',
      padding: '0 40px',
      width: '100%',
    },
    isSelected: {
      backgroundColor: palette.primary.main,
      color: palette.common.white,

      '&:hover': {
        backgroundColor: palette.primary.main,

        '@media (hover: none)': {
          backgroundColor: palette.primary.main,
        },
      },
    },
    buttonLabel: {
      fontSize: typography.fontXS,
      fontWeight: 600,
    },
    outlinedPrimary: {},
  }),
  { name: 'GqlCheckout_ExpressionsOfInterest_ItemButton' },
);

export default itemButtonStyles;
