import { gql } from '@apollo/client/core';

const residualAmount = {
  RESIDUAL_AMOUNT: gql`
    fragment ResidualAmount on DealFinanceAttributes {
      residualAmount {
        currency
        amount
      }
    }
  `,
};
export default residualAmount;
