import { FunctionComponent, useMemo } from 'react';
import React from 'react';
import GoogleMapReact from 'google-map-react';
import Pin from './pin/Pin';
import dealersMapStyles from './dealersMapStyles';
import useCurrentLocationPosition from '../../../hooks/useCurrentLocationPosition';
import useGeolocationPermissionStatus from '../../../hooks/useGeolocationPermissionStatus';
import { Typography, Box, useTheme } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import { GeolocationPermissionStatus } from '../../../enum/GeolocationPermissionStatus';
import {
  GetDealers_dealers_defaultMapLocation,
  GetDealers_dealers_nodes,
} from '../../../graphql/queries/__generated__/GetDealers';
import useWidgetContext from '../../../../../../common/react/useWidgetContext';
import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';
import { newApiKeyMarkets } from '../../../../../common/constants/mapsApiKey';

export interface IDealersMapProps {
  defaultDealer: GetDealers_dealers_defaultMapLocation;
  dealers: GetDealers_dealers_nodes[];
  selectedDealerId: string;
  selectDealerId: (id: string) => void;
  disableDealerSelection?: boolean;
}

const DealersMap: FunctionComponent<IDealersMapProps> = ({
  defaultDealer,
  dealers,
  selectedDealerId,
  selectDealerId,
  disableDealerSelection,
}) => {
  const styles = dealersMapStyles();
  const {
    checkoutLite: {
      dealer: { hideDefaultCenterMap },
    },
  } = useTheme<ICheckoutTheme>();
  const defaultCenter = { lat: defaultDealer.latitude, lng: defaultDealer.longitude };
  const { currentPosition } = useCurrentLocationPosition();
  const { permissionStatus } = useGeolocationPermissionStatus();
  const { configuration } = useWidgetContext();
  const userCoords = currentPosition ? { lat: currentPosition.latitude, lng: currentPosition.longitude } : null;

  const getSelectedDealerCoords = useMemo(() => {
    const selectedDealer = dealers.find(dealer => dealer.externalId === selectedDealerId);

    return selectedDealer ? { lat: selectedDealer.latitude, lng: selectedDealer.longitude } : null;
  }, [dealers, selectedDealerId]);

  const getCenter = useMemo(() => {
    // hideDefaultCenterMap condition (do not remove)
    // this is a fix specific for iOS Chrome along with the google-map-react library
    if (hideDefaultCenterMap) {
      return getSelectedDealerCoords || userCoords;
    }
    return getSelectedDealerCoords || userCoords || defaultCenter;
  }, [getSelectedDealerCoords, userCoords, defaultCenter]);

  if (!currentPosition && permissionStatus === GeolocationPermissionStatus.GRANTED) {
    return (
      <Box display="flex" justifyContent="center">
        <Skeleton width={480} height={475} />
      </Box>
    );
  }

  return (
    <div className={styles.mapContainer} data-test="container:dealerMap">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: newApiKeyMarkets.includes(configuration.dimensions.brand)
            ? process.env.JLR_GOOGLE_MAPS_API_KEY
            : process.env.GOOGLE_MAPS_API_KEY,
        }}
        center={getCenter}
        zoom={7}
      >
        {dealers.map(({ latitude, longitude, externalId, label, address }, index) => (
          <Pin
            key={externalId}
            lat={latitude}
            lng={longitude}
            index={index + 1}
            tooltipContent={
              <Box data-test="dealer:pinTooltip">
                <Typography variant="subtitle2" data-test="dealer:pinDealerName">
                  {` ${label} `}
                </Typography>
                {address?.phone && (
                  <Typography variant="subtitle2" data-test="dealer:pinDealerPhone">
                    {` ${address.phone} `}
                  </Typography>
                )}
              </Box>
            }
            selected={selectedDealerId === externalId}
            disabled={disableDealerSelection}
            onClick={() => selectDealerId(externalId)}
          />
        ))}
      </GoogleMapReact>
    </div>
  );
};

export default DealersMap;
