import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../theme/ICheckoutTheme';

export type MainHeaderFullWidthClasses = 'step' | 'default' | 'simple' | 'headerTitle' | 'stickyHeader';

const mainHeaderFullWidthStyles = makeStyles<ICheckoutTheme, MainHeaderFullWidthClasses>(
  theme => ({
    stickyHeader: {
      position: 'sticky',
      top: 0,
      zIndex: 4,
      padding: 0,
    },
    step: {
      padding: `${theme.spacing(2)} 0 ${theme.spacing(2)} 0`,
      display: 'flex',
      justifyContent: 'center',
      [theme.breakpoints.down('sm')]: {
        padding: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
      },
    },
    default: {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.primary.contrastText,
      borderTop: `1px solid ${theme.palette.primaryDisabled.main}`,
      borderBottom: `1px solid ${theme.palette.primaryDisabled.main}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        flexDirection: 'column',
      },
      [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginTop: 0,
      },
      [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        borderTop: 'none',
      },
    },
    simple: {
      color: theme.palette.common.black,
      backgroundColor: theme.palette.primary.contrastText,
      borderTop: `1px solid ${theme.palette.primaryDisabled.main}`,
      borderBottom: `1px solid ${theme.palette.primaryDisabled.main}`,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
    },
    headerTitle: {
      display: 'inline',
      textAlign: 'center',
    },
  }),
  { name: 'Checkout_MainHeader_With_Back_Link' },
);

export default mainHeaderFullWidthStyles;
