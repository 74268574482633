import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type PaymentSummaryClasses = 'container' | 'transactionLabelContainer' | 'transactionLabel' | 'icon';

const paymentSummaryStyles = makeStyles<ICheckoutTheme, { summary: boolean }, PaymentSummaryClasses>(
  ({ breakpoints }) => ({
    container: {
      display: 'flex',

      [breakpoints.up('sm')]: {
        position: ({ summary }) => (!!summary ? 'static' : 'absolute'),
      },

      [breakpoints.down('xs')]: {
        position: 'static',
      },
      right: 0,

      // @todo remove this and add a component: JourneySelectionStep.labelId: 'translation__key'
      top: '-56px',
    },
    transactionLabelContainer: {
      display: 'flex',
    },
    transactionLabel: {
      lineHeight: 2,
    },
    icon: {},
  }),
  { name: 'Checkout4_PaymentSummaryClasses' },
);

export default paymentSummaryStyles;
