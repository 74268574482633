import React, { FunctionComponent } from 'react';
import Box from '@material-ui/core/Box';
import { Typography } from '@material-ui/core';
import enquiryFormTitleStyles from './enquiryFormTitleStyles';

export interface ITitleProps {
  title: string;
}

const EnquiryFormTitle: FunctionComponent<ITitleProps> = ({ title }) => {
  const styles = enquiryFormTitleStyles();
  return (
    <Box className={styles.title}>
      <Typography component={'h2'} variant={'h5'} data-test="enquiry_form:title" className={styles.titleContent}>
        {title}
      </Typography>
    </Box>
  );
};

export default EnquiryFormTitle;
