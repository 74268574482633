import * as H from 'history';

const queryParams = {
  getParam: (queryType: string, location: H.Location): string | null => {
    const search = location.search;
    const params = new URLSearchParams(search);

    return params.get(queryType);
  },
  changeParamValue: (location: H.Location, history: H.History, paramName: string, newValue: string): void => {
    const oldSearchParams = location.search;
    const newSearchParams = new URLSearchParams(oldSearchParams);
    newSearchParams.set(paramName, newValue);
    history.replace({
      ...location,
      search: newSearchParams.toString(),
    });
  },
  changeArrayValue: (location: H.Location, history: H.History, paramName: string, newValue: string[]): void => {
    const oldSearchParams = location.search;
    const newSearchParams = new URLSearchParams(oldSearchParams);
    newSearchParams.set(paramName, newValue.join());
    history.replace({
      ...location,
      search: newSearchParams.toString(),
    });
  },
  removeParam: (location: H.Location, history: H.History, paramName: string) => {
    const oldSearchParams = location.search;
    const newSearchParams = new URLSearchParams(oldSearchParams);
    newSearchParams.delete(paramName);
    history.replace({
      ...location,
      search: newSearchParams.toString(),
    });
  },
};

export default queryParams;
