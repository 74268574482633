import { FunctionComponent, useState } from 'react';
import React from 'react';
import tooltipStyles from './tooltipStyles';
import CloseIcon from '@material-ui/icons/Close';
import { Box } from '@material-ui/core';

const Tooltip: FunctionComponent = ({ children }) => {
  const [isVisible, setIsVisible] = useState(true);
  const styles = tooltipStyles();

  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.tooltipContainer}>
      {children}
      <CloseIcon onClick={() => setIsVisible(false)} />
      <Box className={styles.arrow} />
    </div>
  );
};

export default Tooltip;
