import { useCallback, useEffect, useState } from 'react';
import { IUserInformation } from '../../../../common/model/IUser';
import { IAppContext } from './AppContext';
import { TransactionErrorTypes } from '../enum/TransactionErrorTypes';
import { IDeal } from '../../../../common/model/IDeal';
import { ICarConfiguration } from '../../../../common/model/ICarConfiguration';

export function useGetAppContextValue({
  deal: initialDeal,
  dealError,
  user: initialUser,
}: {
  deal?: IDeal;
  dealError?: any;
  user: IUserInformation;
}): IAppContext {
  const [deal, setDealState] = useState<IDeal | null>(initialDeal);
  const [user, setUserState] = useState<IUserInformation>(initialUser);
  const [carConfig, setCarConfig] = useState<ICarConfiguration>(null);
  const [transactionError, setTransactionErr] = useState<TransactionErrorTypes>(TransactionErrorTypes.NONE);

  const setDeal = useCallback(
    (deal: IDeal) => {
      setDealState(deal);
    },
    [deal],
  );

  const setUser = useCallback(
    (user: IUserInformation) => {
      setUserState(user);
    },
    [user],
  );

  const setCarConfiguration = useCallback(
    (carConfig: ICarConfiguration) => {
      setCarConfig(carConfig);
    },
    [carConfig],
  );

  const setTransactionError = useCallback(
    (error: TransactionErrorTypes) => {
      setTransactionErr(error);
    },
    [transactionError],
  );

  useEffect(() => {
    setCarConfig(deal?.fullProductConfiguration);
  }, [deal]);

  return {
    deal,
    dealError,
    user,
    carConfig,
    transactionError,
    setDeal,
    setUser,
    setCarConfiguration,
    setTransactionError,
  };
}
