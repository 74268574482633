import { gql } from '@apollo/client/core';
import money from '../money';

const paymentFee = {
  PAYMENT_FEE: gql`
    ${money.MONEY}
    fragment PaymentFee on PaymentFee {
      reserveFee {
        ...Money
      }
      orderFee {
        ...Money
      }
      refundableState
    }
  `,
};

export default paymentFee;
