import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { ApolloError, useReactiveVar } from '@apollo/client';
import { useIntl } from 'react-intl';
import InitPaymentError from '../../ErrorPages/InitPaymentError';
import FailedPaymentError from '../../ErrorPages/FailedPaymentError';
import Translation from '../../../../../../common/components/presentation/Translation/Translation';
import payment2C2PStyles from './payment2C2PStyles';
import { activeJourneyType } from '../../../../../common/graphql/cache';
import { JourneyTypes } from '../../../../../../../../common/enum/JourneyTypes';
interface IPayment2C2PProps {
  initError: ApolloError;
  paymentAmount: string;
  isPaymentFailed: boolean;
}

const Payment2C2P: FC<IPayment2C2PProps> = ({ initError, isPaymentFailed, paymentAmount }) => {
  const styles = payment2C2PStyles();
  const intl = useIntl();
  const userJourney = useReactiveVar(activeJourneyType);

  if (initError) {
    return <InitPaymentError />;
  }

  if (isPaymentFailed) {
    return <FailedPaymentError />;
  }

  return (
    <>
      <Typography data-test="text:payment:subtitle" variant="subtitle1" className={styles.subtitle}>
        {paymentAmount || userJourney === JourneyTypes.ORDER_ONLINE ? (
          <Translation id="trans__checkout__payment_page__order_online__subtitle" values={{ amount: paymentAmount }} />
        ) : (
          <Translation id="trans__checkout__payment_page__subtitle" />
        )}
      </Typography>
      <Typography data-test="text:payment:description" variant="subtitle1" className={styles.description}>
        {paymentAmount ? (
          <Translation
            id="trans__checkout__payment_page__order_online__description1"
            values={{ amount: paymentAmount }}
          />
        ) : (
          <Translation id="trans__checkout__payment_page__description1" />
        )}
      </Typography>
      <Typography data-test="text:payment:description2" variant="subtitle1" className={styles.description2}>
        <Translation
          id="trans__checkout__payment_page__description2"
          values={{
            email: (
              <a
                className={styles.email}
                href={`mailto:${intl.formatMessage({
                  id: 'trans__checkout__payment_page__email',
                })}`}
              >
                <Translation id={'trans__checkout__payment_page__email'} />
              </a>
            ),
          }}
        />
      </Typography>
    </>
  );
};

export default Payment2C2P;
