import React, { FunctionComponent, useCallback, useState } from 'react';
import Box from '@material-ui/core/Box';
import { useIntl } from 'react-intl';
import { ISchemaFieldProps } from '../../model/IJsonSchema';
import { Radio, RadioGroup, Typography, useTheme } from '@material-ui/core';
import customRadioGroupStyles from './customRadioGroupStyles';
import { constants } from '../../../../../../../common/constants/constants';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import ShowIfTranslationExists from '../../../../../common/components/ShowIfTranslationExists';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export const CustomRadioGroup: FunctionComponent<ISchemaFieldProps> = ({ formData }) => {
  const styles = customRadioGroupStyles();
  const {
    value,
    id,
    label,
    onChange,
    options: { enumOptions },
    rawErrors,
    schema,
  } = formData;
  const {
    checkoutLite: {
      enquiryForm: { showOnlyLegalDisclaimerMandatoryLegend },
    },
  } = useTheme<ICheckoutTheme>();
  const [selectedValue, setSelectedValue] = useState(value);
  const intl = useIntl();

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(e.target.value);
    onChange(e.target.value);
  }, []);

  return (
    <Box className={styles.container}>
      {showOnlyLegalDisclaimerMandatoryLegend && (
        <ShowIfTranslationExists translationKey={'trans__checkout__enquiry_form__mandatory_legend'}>
          <>
            <Box className={styles.mandatoryLegend}>
              <Typography data-test={`mandatory:label:${id}`}>
                {intl.formatMessage({ id: 'trans__checkout__enquiry_form__mandatory_legend' })}
              </Typography>
            </Box>
          </>
        </ShowIfTranslationExists>
      )}
      <Typography
        className={styles.disclaimer}
        dangerouslySetInnerHTML={{ __html: label }}
        onClick={e => {
          if (!(e.target as HTMLElement).hasAttribute('href')) {
            e.preventDefault();
          }
        }}
        data-test={`text:enquiryForm:${id}`}
      />
      <Box className={styles.errorMessageContainer}>
        {value === constants.NO && (
          <Typography className={styles.errorMessage}>
            <Translation id="trans__checkout__enquiry_form__authorize__must_select_yes" />
          </Typography>
        )}
      </Box>
      <RadioGroup className={styles.radioButtonGroup}>
        {enumOptions.map((option, index) => (
          <Box className={styles.radioButtonContainer} key={index}>
            <Radio
              color={'primary'}
              id={id}
              checked={selectedValue === option.value}
              value={option.value}
              size={'medium'}
              onChange={handleChange}
              data-test={`radioButton:${option.value}`}
              name={option.value}
              classes={{
                root: styles.root,
              }}
            />
            <Box className={styles.labelContainer}>
              <Typography variant={'body2'} data-test={`text:enquiryForm:${option.value}`}>
                {option.label}
              </Typography>
              {schema?.disclaimers && (
                <Typography className={styles.disclaimer} data-test={`text:enquiryForm:${option.value}:disclaimer`}>
                  {schema.disclaimers[option.value]}
                </Typography>
              )}
              <Typography className={styles.errorMessage}>{rawErrors && rawErrors[index]} </Typography>
            </Box>
          </Box>
        ))}
      </RadioGroup>
    </Box>
  );
};

export default CustomRadioGroup;
