import { useEffect } from 'react';

interface IUseRemoveElementOnPageChangeProps {
  elementId: string;
}

type IUseRemoveElementOnPageChange = ({ elementId }: IUseRemoveElementOnPageChangeProps) => void;

const useRemoveElementOnPageChange: IUseRemoveElementOnPageChange = ({ elementId }) => {
  useEffect(() => {
    window.onpopstate = () => {
      const element: HTMLElement = document.getElementById(elementId);

      if (element) {
        element.remove();
      }
    };
  }, []);
};

export default useRemoveElementOnPageChange;
