import { IPositionCoordinates } from '../model/IPositionCoordinates';

export const distance = {
  calculateDistance: (currentPosition: IPositionCoordinates, dealerPosition: IPositionCoordinates) => {
    const latitude1 = currentPosition.latitude;
    const longitude1 = currentPosition.longitude;
    const latitude2 = dealerPosition.latitude;
    const longitude2 = dealerPosition.longitude;

    const latitude1Radians = (latitude1 * Math.PI) / 180;
    const latitude2Radians = (latitude2 * Math.PI) / 180;
    const longitudeDifferenceRadians = ((longitude2 - longitude1) * Math.PI) / 180;
    const EarthRadius = 6371e3;
    const distance =
      Math.acos(
        Math.sin(latitude1Radians) * Math.sin(latitude2Radians) +
          Math.cos(latitude1Radians) * Math.cos(latitude2Radians) * Math.cos(longitudeDifferenceRadians),
      ) * EarthRadius;

    return distance;
  },
};
