import { createContext } from 'react';
import { JourneyType } from '../../../../__generated__/globalTypes';
import { IStep, IJourney } from '../../model/IJourneySteps';

export interface IJourneyListContextProps {
  journeyList: IJourney[];
  updateJourneyList: (name: string) => void;
  currentJourney: JourneyType;
  steps: IStep[];
}

const JourneyListContext = createContext<IJourneyListContextProps>({
  journeyList: [],
  updateJourneyList: () => null,
  currentJourney: null,
  steps: [],
});

export default JourneyListContext;
