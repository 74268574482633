import { makeStyles } from '@material-ui/core/styles';
import { Theme } from '@material-ui/core';

export type ThemedErrorMessageClasses =
  | 'root'
  | 'errorHeader'
  | 'iconContainer'
  | 'icon'
  | 'message'
  | 'secondaryMessage';

const themedErrorMessageStyles = makeStyles<Theme, { fullPage: boolean }, ThemedErrorMessageClasses>(theme => ({
  root: {
    margin: 'auto',
    width: '60%',
    maxWidth: 700,
    minWidth: 200,
  },
  errorHeader: {
    display: 'flex',
    flexDirection: ({ fullPage }) => (fullPage ? 'column' : 'row'),
    alignItems: 'center',
    justifyContent: ({ fullPage }) => (fullPage ? 'center' : 'left'),
  },
  iconContainer: {
    padding: theme.spacing(2),
  },
  icon: {
    display: 'block',
    position: 'relative',
    width: 40,
    height: 40,
    border: `2px solid ${theme.palette.error.main}`,
    borderRadius: 9999,
    '&:after, &:before': {
      content: '" "',
      display: 'block',
      width: 4,
      height: '60%',
      position: 'absolute',
      left: '50%',
      top: '50%',
      background: theme.palette.error.main,
      borderRadius: 2,
      transform: 'translate(-50%, -50%) rotate(-45deg) ',
    },
    '&:before': {
      transform: 'translate(-50%, -50%) rotate(45deg)',
    },
    '&:after': {
      transform: 'translate(-50%, -50%) rotate(-45deg) ',
    },
  },
  message: {
    padding: theme.spacing(2),
    paddingLeft: ({ fullPage }) => (fullPage ? theme.spacing(2) : 0),
    paddingTop: ({ fullPage }) => (fullPage ? 0 : theme.spacing(2)),
    textAlign: ({ fullPage }) => (fullPage ? 'center' : 'left'),
    width: '100%',
  },
  secondaryMessage: {
    opacity: 0.7,
    transform: 'scale(0.9)',
  },
}));

export default themedErrorMessageStyles;
