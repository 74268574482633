import { useEffect, useState } from 'react';
import { IPositionCoordinates } from '../model/IPositionCoordinates';
import { GEOLOCATION } from '../constants/geolocation';

interface IUseCurrentLocationPositionReturn {
  currentPosition: IPositionCoordinates;
  error: string;
}

const useCurrentLocationPosition = (): IUseCurrentLocationPositionReturn => {
  const [currentPosition, setCurrentPosition] = useState(null);
  const [error, setError] = useState(null);

  const saveCurrentPosition = ({ coords }: any) => {
    setCurrentPosition({
      latitude: coords.latitude,
      longitude: coords.longitude,
    });
  };

  const onError = (error: any) => {
    setError(error.message);
  };

  useEffect(() => {
    const geolocation = navigator.geolocation;
    if (!geolocation) {
      setError(GEOLOCATION.NOT_SUPPORTED);
      return;
    }
    const watcher = geolocation.watchPosition(saveCurrentPosition, onError);

    return () => geolocation.clearWatch(watcher);
  }, []);

  return { currentPosition, error };
};

export default useCurrentLocationPosition;
