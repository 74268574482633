import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../theme/ICheckoutTheme';

export type ModalClasses = 'container';

const modalStyles = makeStyles<ICheckoutTheme, ModalClasses>(
  () => ({
    container: {
      boxShadow: '2px 2px 10px 2px rgba(0,0,0,0.7)',
    },
  }),
  { name: 'Checkout_Modal' },
);

export default modalStyles;
