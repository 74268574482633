import { gql } from '@apollo/client/core';
import money from '../money';
import price from '../price';
import offers from '../offers';

const dealCarPrice = {
  DEAL_CAR_PRICE: gql`
    ${price.PRICE}
    ${money.MONEY}
    ${offers.OFFERS}
    fragment DealCarPrice on DealCarPrice {
      originalPrice {
        ...Price
      }
      totalDiscount {
        ...Money
      }
      finalPrice {
        ...Price
      }
      offers {
        ...Offers
      }
    }
  `,
};
export default dealCarPrice;
