import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import paymentEwayStyles from './paymentEwayStyles';
import DefaultButton from '../../../../../../common/components/presentation/Buttons/DefaultButton';
import Translation from '../../../../../../common/components/presentation/Translation/Translation';
import PaymentEway from './PaymentEway';
import { usePaymentEway } from '../../../hooks/usePaymentEway';
import useCheckoutContext from '../../../../../common/hooks/useCheckoutContext';

const PaymentEwayContainer: FunctionComponent = () => {
  const styles = paymentEwayStyles();
  const { deal } = useCheckoutContext();
  const {
    handleInitPayment,
    initPaymentData,
    initPaymentError,
    loading,
    paymentAmount,
    isPaymentFailed,
  } = usePaymentEway();

  const buttonText = isPaymentFailed
    ? 'trans__checkout__payment_page__try_again_CTA'
    : 'trans__checkout__next_step__payment_page__proceed_to_order_CTA';

  return (
    <Box className={styles.container}>
      <PaymentEway
        initError={initPaymentError}
        paymentAmount={paymentAmount}
        isPaymentFailed={isPaymentFailed}
        loading={loading}
        refundableState={deal?.attributes?.paymentFee?.refundableState}
      />
      {!initPaymentData && (
        <DefaultButton
          className={[styles.payButton]}
          dataTest="button:checkout:eway"
          variant="contained"
          onClick={handleInitPayment}
          disabled={loading}
        >
          <Translation id={buttonText} values={{ reservationFee: paymentAmount }} />
        </DefaultButton>
      )}
    </Box>
  );
};

export default PaymentEwayContainer;
