import React, { FunctionComponent } from 'react';
import clsx from 'clsx';
import { Box, Grid } from '@material-ui/core';
import { ExpressionOfInterest } from '../../../../../common/graphql/fragments/deal/__generated__/ExpressionOfInterest';
import ItemDetails from '../itemDetails/ItemDetails';
import ItemButton from '../button/ItemButton';
import itemStyles from './itemStyles';

export interface ItemProps {
  item: ExpressionOfInterest;
  isSelected: boolean;
  handleSelectedItem: (code: string) => void;
}

const Item: FunctionComponent<ItemProps> = ({ item, isSelected, handleSelectedItem }) => {
  const styles = itemStyles();

  return (
    <Grid
      item
      xs={12}
      sm={6}
      lg={3}
      className={styles.itemWrapper}
      data-test={'checkout:extras:expression_of_interest:item'}
    >
      <Box
        className={clsx([
          styles.item,
          {
            [styles.itemSelected]: isSelected,
          },
        ])}
        data-test={`checkout:extras:expression_of_interest:item:${item.code}`}
      >
        <ItemDetails itemData={item.attributes} />
        <ItemButton isSelected={isSelected} onClick={() => handleSelectedItem(item.code)} />
      </Box>
    </Grid>
  );
};

export default Item;
