import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type ContinueButtonClasses = 'continueButton' | 'newContainedPrimary' | 'newContained' | 'loader';

const continueButtonStyles = makeStyles<ICheckoutTheme, ContinueButtonClasses>(
  theme => ({
    continueButton: {
      marginRight: '30px',
      borderRadius: '4px',
      [theme.breakpoints.down('xs')]: {
        marginRight: 0,
      },
    },
    newContainedPrimary: {},
    newContained: {},
    loader: {
      marginRight: '5px',
    },
  }),
  { name: 'Checkout4_ContinueButton' },
);

export default continueButtonStyles;
