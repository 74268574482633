import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../theme/ICheckoutTheme';

export type CheckoutLiteMainRoutesContainerStyles = 'root';

const checkoutLiteMainRoutesContainerStyles = makeStyles<ICheckoutTheme, CheckoutLiteMainRoutesContainerStyles>(
  () => ({
    root: {
      maxWidth: '1200px',
      margin: 'auto',
      width: '100%',
      paddingBottom: 40,
    },
  }),
  { name: 'Checkout_checkoutLiteMainRoutesContainer' },
);

export default checkoutLiteMainRoutesContainerStyles;
