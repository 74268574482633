import { Grid } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import navigationStyles from './navigationStyles';
import Navigation from './Navigation';
import useWidgetContext from '../../../react/useWidgetContext';
import useIsGqlMarket from '../../../../gql/common/hooks/useIsGqlMarket';
import GqlNavigation from './GqlNavigation';

const NavigationContainer: FunctionComponent = () => {
  const style = navigationStyles();
  const { configuration } = useWidgetContext();
  const isGqlMarket = useIsGqlMarket(configuration) || configuration.gqlApi;

  return (
    <Grid className={style.backButtonContainer} container data-test="container:navigation">
      {isGqlMarket ? <GqlNavigation /> : <Navigation />}
    </Grid>
  );
};

export default NavigationContainer;
