import { useLazyQuery } from '@apollo/client';
import { PreOrder, PreOrderVariables } from '../../graphql/queries/__generated__/PreOrder';
import preOrderOnlineExternal from '../../graphql/queries/preOrderNowExternal';
import useErrorHandler from '../../../common/hooks/useErrorHandler';

export const usePreOrderOnlineExternal = (dealId: string, postCode: string) => {
  const [fetchPreOrderOnlineExternal, { data, loading, error }] = useLazyQuery<PreOrder, PreOrderVariables>(
    preOrderOnlineExternal.PRE_ORDER_ONLINE_EXTERNAL,
    {
      variables: {
        dealId: dealId,
        postCode: postCode,
      },
      fetchPolicy: 'cache-first',
    },
  );

  useErrorHandler(error);

  return { fetchPreOrderOnlineExternal, data, loading, error };
};
