import continueButtonStyles from './continueButtonsStyles';
import React, { FunctionComponent, ReactElement } from 'react';
import DefaultButton from '../../../../common/components/presentation/Buttons/DefaultButton';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { useTheme } from '@material-ui/core';
import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';

export interface IContinueButtonProps {
  children: ReactElement;
  testId: string;
  withIcon?: boolean;
  onClick: () => void;
  disabled?: boolean;
  className?: string;
}

const ContinueButton: FunctionComponent<IContinueButtonProps> = ({
  onClick,
  testId,
  withIcon = false,
  disabled = false,
  children,
  className,
}) => {
  const styles = continueButtonStyles();
  const {
    checkoutLite: { checkout4 },
  } = useTheme<ICheckoutTheme>();

  return (
    <DefaultButton
      onClick={onClick}
      className={[styles.continueButton, className]}
      variant="contained"
      dataTest={testId}
      disabled={disabled}
      contained={styles.contained}
      containedPrimary={styles.containedPrimary}
      disableTouchRipple={checkout4?.disableTouchRipple}
    >
      <>
        {!!withIcon && <ArrowBackIosIcon className={styles.icon} />}
        {children}
      </>
    </DefaultButton>
  );
};

export default ContinueButton;
