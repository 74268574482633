import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type BackToFinanceButtonClasses = 'buttonWrapper' | 'buttonLabel';

const backToFinanceButtonStyles = makeStyles<ICheckoutTheme, BackToFinanceButtonClasses>(
  theme => ({
    buttonWrapper: {
      height: 40,
      borderRadius: 0,
      borderColor: theme.palette.secondary.main,
      color: theme.palette.secondary.main,
      maxWidth: '100%',
      padding: '0 40px',
      width: 'auto',

      '&:hover': {
        borderColor: theme.palette.secondary.main,
      },
    },
    buttonLabel: {
      fontSize: theme.typography.fontXS,
      fontWeight: 500,
    },
  }),
  { name: 'Checkout4_BackToFinanceButton' },
);

export default backToFinanceButtonStyles;
