import React, { FunctionComponent } from 'react';
import BaseDealerLayout from './layouts/baseDealerLayout/BaseDealerLayout';
import DealerSelector from './dealerSelector/DealerSelector';
import DealerPageTitle from './dealerPageTitle/DealerPageTitle';
import DealerPageInfo from './dealerPageInfo/DealerPageInfo';

const Dealer: FunctionComponent = () => {
  return (
    <BaseDealerLayout
      pageInfoSlot={<DealerPageInfo />}
      pageTitleSlot={<DealerPageTitle />}
      mainContentSlot={<DealerSelector />}
    />
  );
};

export default Dealer;
