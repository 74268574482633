import { FunctionComponent } from 'react';
import React from 'react';
import pinStyles from './pinStyles';
import MapPin from '../../../../assets/svg/MapPin';
import clsx from 'clsx';
import Tooltip from '../tooltip/Tooltip';
import { Box, Typography } from '@material-ui/core';

export interface IDealersMapProps {
  lat: number;
  lng: number;
  index: number;
  selected?: boolean;
  disabled?: boolean;
  tooltipContent?: React.ReactElement;
  onClick: () => void;
}

const Pin: FunctionComponent<IDealersMapProps> = ({
  onClick,
  index,
  selected = false,
  disabled = false,
  tooltipContent,
}) => {
  const styles = pinStyles();
  const disableClick = disabled || selected;

  return (
    <Box data-test="dealer:pinContainer" data-test-is-selected={selected} data-test-is-disabled={disabled}>
      {selected ? <Tooltip> {tooltipContent} </Tooltip> : null}
      <div
        className={clsx([
          styles.pinContainer,
          { [styles.selected]: selected },
          { [styles.disabled]: disabled && !selected },
        ])}
        onClick={() => !disableClick && onClick()}
        data-test={`dealer:pin_${index}`}
      >
        <MapPin />
        <div className={styles.indexContainer}>
          <Typography variant="body2">{index}</Typography>
        </div>
      </div>
    </Box>
  );
};

export default Pin;
