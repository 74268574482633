import { gql } from '@apollo/client/core';

const reserveForm = {
  INITIALIZE_PAYMENT: gql`
    mutation InitializePayment($identifier: String!, $paymentType: PaymentType!) {
      initPayment(identifier: $identifier, paymentType: $paymentType) {
        id
        url
        errors {
          code
          message
        }
        ... on VisaNetPaymentResponse {
          sessionToken
          merchantId
          purchaseNumber
          amount
          cardHolderName
          cardHolderLastName
          cardHolderEmail
        }
      }
    }
  `,
};

export default reserveForm;
