import React, { FC, useCallback } from 'react';
import { Button, Dialog, DialogContent, Typography } from '@material-ui/core';
import dealUnavailablePageStyles from './dealUnavailablePageStyles';
import useWidgetContext from '../../../react/useWidgetContext';
import { useLocation } from 'react-router';
import Translation from '../Translation/Translation';
import useGqlRestSwitch from '../../../../gql/common/hooks/useGqlRestSwitch';

const DealUnavailablePage: FC = () => {
  const styles = dealUnavailablePageStyles();
  const location = useLocation<{ isExpired: boolean }>();
  const { configuration } = useWidgetContext();
  const { isGqlApi } = useGqlRestSwitch();

  const openSpc = useCallback(() => {
    window.open(isGqlApi ? configuration.homePageURL : configuration.spcURL, '_self');
  }, [isGqlApi]);

  return (
    <Dialog open={true} classes={{ paper: styles.dialog }} data-test={'modal:deal_unavailable'}>
      <DialogContent>
        <Typography variant={'body1'} className={styles.message}>
          <Translation
            id={
              isGqlApi
                ? 'trans__checkout__errors__no_configuration_available'
                : location.state.isExpired
                ? 'exception.dealUnavailable.messageExpired'
                : 'exception.dealUnavailable.message'
            }
          />
        </Typography>
        <Button
          variant={'contained'}
          className={styles.actionButton}
          onClick={openSpc}
          data-test={'button:deal_unavailable:action'}
        >
          <Translation
            id={isGqlApi ? 'trans__checkout__errors__configure_vehicle_button' : 'exception.dealUnavailable.action'}
          />
        </Button>
      </DialogContent>
    </Dialog>
  );
};

export default DealUnavailablePage;
