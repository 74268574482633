import { gql } from '@apollo/client/core';

const translations = {
  GET_TRANSLATIONS: gql`
    query GetTranslations {
      translations {
        code
        value
      }
    }
  `,
};

export default translations;
