import React, { FC } from 'react';
import Exception from './Exception';
import { Container } from '@material-ui/core';

const NotFoundPage: FC = () => {
  return (
    <Container data-test="container:checkout:not_found">
      <Exception messageId={'exception.notFound.message'} />
    </Container>
  );
};

export default NotFoundPage;
