import { gql } from '@apollo/client/core';

const engine = {
  SPECK_PACK: gql`
    fragment SpecPack on SpecPack {
      code
      attributes {
        shortname
      }
    }
  `,
};

export default engine;
