import React, { FunctionComponent } from 'react';
import Image from 'material-ui-image';
import { Box, Grid, Typography } from '@material-ui/core';
import financeSectionStyles from './financeSectionStyles';
import Translation from '../../../../common/components/presentation/Translation/Translation';
import BackToFinanceButton from '../../../common/components/steps/backToFinanceButton/BackToFinanceButton';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';
import ContinueButton from '../buttons/continueButton/ContinueButton';

const FinanceSection: FunctionComponent = () => {
  const styles = financeSectionStyles();
  const { deal } = useCheckoutContext();
  const startConfigurationSourceUrl = deal?.car.startConfigurationSourceUrl;
  const image = deal?.car.images[0].url;

  return (
    <Grid container spacing={2} data-test={'checkout:finance_section'} className={styles.financeWrapper}>
      <Grid item sm={12} md={6}>
        <Image
          src={image}
          color="transparent"
          className={styles.image}
          imageStyle={{ position: 'unset', height: 'auto' }}
          style={{ paddingTop: 0, position: 'unset' }}
          animationDuration={0}
          data-test={'checkout:finance_section:image'}
        />
      </Grid>
      <Grid item sm={12} md={6} className={styles.financeDetails}>
        <Typography variant={'h4'} data-test={'checkout:finance_section:title'}>
          <Translation id={'trans__checkout__extras_page__finance_title'} className={styles.title} />
        </Typography>
        <Typography data-test={'checkout:finance_section:details'}>
          <Translation id={'trans__checkout__extras_page__finance_description'} className={styles.details} />
        </Typography>
        <Box className={styles.backToFinanceButtonContainer}>
          <BackToFinanceButton startConfigurationSourceUrl={startConfigurationSourceUrl} />
        </Box>
      </Grid>
      <Grid item sm={12}>
        <Box className={styles.continueButtonContainer}>
          <ContinueButton testId="checkout:continue_button:finance" activateNextStep={true} />
        </Box>
      </Grid>
    </Grid>
  );
};

export default FinanceSection;
