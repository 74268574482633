import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type PaymentDisclaimerModalClasses = 'root';

const paymentDisclaimerModalStyles = makeStyles<ICheckoutTheme, PaymentDisclaimerModalClasses>(
  ({ typography }) => ({
    root: {
      display: 'grid',
      fontFamily: typography.fontFamily,
      padding: '16px',
    },
  }),
  { name: 'Checkout4_PaymentDisclaimerModal' },
);

export default paymentDisclaimerModalStyles;
