import React, { FunctionComponent, SetStateAction, Dispatch } from 'react';
import ModalWithHeader from '../../../../../common/components/presentation/ModalWithHeader/ModalWithHeader';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import { Typography } from '@material-ui/core';
import { useIntl } from 'react-intl';
import termsAndConditionsModalStyles from './termsAndConditionsModalStyles';

interface ITermsAndConditionsModalProps {
  isOpen: boolean;
  toggleModal: Dispatch<SetStateAction<boolean>>;
  id: string;
}

const TermsAndConditionsModal: FunctionComponent<ITermsAndConditionsModalProps> = ({ isOpen, toggleModal, id }) => {
  const styles = termsAndConditionsModalStyles();
  const intl = useIntl();

  return (
    <ModalWithHeader
      open={isOpen}
      toggleModal={toggleModal}
      title={<Translation id="trans__checkout__enquiry_form__reserve_online_terms_and_conditions_title_page" />}
    >
      <Typography
        data-test={`terms_and_conditions_list:${id}`}
        className={styles.root}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'trans__checkout__enquiry_form__reserve_online_terms_and_conditions_page',
          }),
        }}
      />
    </ModalWithHeader>
  );
};

export default TermsAndConditionsModal;
