import { constants } from '../../../../../common/constants/constants';

const useUserComingFromMyAccount = () => {
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const from = urlParams.get(constants.FROM);
  const isUserComingFromMyAccount = from === 'my-account';

  return { isUserComingFromMyAccount };
};

export default useUserComingFromMyAccount;
