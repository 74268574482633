import { gql } from '@apollo/client/core';

const deal = {
  SELECT_DEALER: gql`
    mutation SelectDealer($dealId: String!, $dealerId: String!) {
      selectDealer(dealId: $dealId, dealerId: $dealerId) {
        uuid
      }
    }
  `,
};

export default deal;
