import IPlatformConfig from '../model/IPlatformConfig';
import CONSTANTS from '../constants/constants';
import platformConfigService from '../../../../common/service/platformConfigService';
import getJsonnetData from '../../../../common/utilities/getJsonnetData';

const {
  APP_CONFIG_BASE_URL,
  DIMENSIONS: { brand, region, country, language },
} = CONSTANTS;

const jsonnetApi = {
  getConfiguration: async (): Promise<void | IPlatformConfig> => {
    try {
      const response = await (await getJsonnetData(APP_CONFIG_BASE_URL, brand, region, country, language)).json();
      const { platformFeatures } = response;

      if (!platformFeatures) {
        throw new Error(`Missing Checkout Lite feature flags platform configuration.`);
      }

      return {
        paymentProvider: response?.payment?.provider,
        payment: {
          isCoeCategoryAmountEnabled: response?.payment?.isCoeCategoryAmountEnabled === true,
        },
        priceDisplay: platformConfigService.fromJsonnet(response?.priceDisplay),
        tradeInIntegration: platformConfigService.getPXIntegrationType(response),
        platformFeatures: response.platformFeatures,
        checkout: response.checkout30,
        myAccount: {
          miniMyAccountConfig: response?.myAccount?.miniMyAccountConfig,
          myFinance: response?.myAccount?.myFinance,
        },
        dxpDataLayer: platformFeatures.dxpDataLayer === true,
      };
    } catch (e) {
      throw new Error(`Unable to get platform configuration for ${brand} ${country} ${region} ${language}.`);
    }
  },
};

export default jsonnetApi;
