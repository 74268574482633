import React from 'react';
import IPlatformConfig from '../model/IPlatformConfig';
import PlatformConfigContext from '../react/PlatformConfigContext';
import IInitializerComponent from '../components/IInitializerComponent';
import multiDimensionApi from '../api/multiDimensionApi';

const PlatformConfigProvider: IInitializerComponent<{ config: IPlatformConfig }> = {
  initialize: () => {
    return multiDimensionApi.getConfiguration().then((config: IPlatformConfig) => ({ config }));
  },

  component: ({ children, config }) => {
    return <PlatformConfigContext.Provider value={config}>{children}</PlatformConfigContext.Provider>;
  },
};

export default PlatformConfigProvider;
