import React, { FunctionComponent, memo } from 'react';
import Typography from '@material-ui/core/Typography';
import Modal from '../Modal/Modal';
import { DialogContent, DialogTitle } from '@material-ui/core';
import modalWithHeaderStyles from './modalWithHeaderStyles';
import CloseIcon from '@material-ui/icons/Close';
import SectionLoader from '../Loader/SectionLoader';

interface IModalWithHeaderProps {
  loading?: boolean;
  open: boolean;
  toggleModal?: (shouldOpen: boolean) => void;
  modalProps?: unknown;
  titleProps?: unknown;
  contentProps?: unknown;
  title: React.ReactNode;
  className?: string;
}

const ModalWithHeader: FunctionComponent<IModalWithHeaderProps> = memo(
  ({ children, open, toggleModal, modalProps, titleProps, contentProps, title, className, loading = false }) => {
    const modalStyles = modalWithHeaderStyles();

    if (loading) {
      return <SectionLoader />;
    }

    return (
      <Modal
        PaperProps={{ className: modalStyles.modalRoot }}
        labelledby="modal-title"
        open={open}
        toggleModal={toggleModal}
        hideCloseIcon={true}
        {...modalProps}
      >
        <DialogTitle className={modalStyles.closeContainer} disableTypography={true}>
          <Typography
            data-test="text:modal:title"
            align={'center'}
            className={modalStyles.title}
            variant="h4"
            {...titleProps}
          >
            {title}
          </Typography>
          <span className={modalStyles.closeButton} onClick={() => toggleModal(false)}>
            <CloseIcon data-test="button:close" className={modalStyles.icon} />
          </span>
        </DialogTitle>
        <DialogContent className={className} {...contentProps}>
          {children}
        </DialogContent>
      </Modal>
    );
  },
);

export default ModalWithHeader;
