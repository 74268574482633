import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type DealersMapClasses = 'mapContainer' | 'pin';

const dealersMapStyles = makeStyles<ICheckoutTheme, DealersMapClasses>(
  theme => ({
    mapContainer: {
      width: '480px',
      height: '475px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      '& .gm-control-active.gm-fullscreen-control': {
        '& img': {
          display: 'none',
        },
        '& img:first-child': {
          display: 'block',
          maxWidth: 'none',
          boxSizing: 'content-box',
          top: '50%',
          left: '50%',
          pointerEvents: 'none',
          position: 'absolute',
          transform: 'translate(-50%,-50%)',
        },
      },
      '& .gmnoprint .gm-control-active': {
        '&[aria-label="Zoom in"]': {
          '& img': {
            display: 'none',
          },
          '& img:first-child': {
            display: 'block',
            maxWidth: 'none',
            boxSizing: 'content-box',
            top: '50%',
            left: '50%',
            pointerEvents: 'none',
            position: 'absolute',
            transform: 'translate(-50%,-50%)',
          },
        },
        '&[aria-label="Zoom out"]': {
          '& img': {
            display: 'none',
          },
          '& img:first-child': {
            display: 'block',
            maxWidth: 'none',
            boxSizing: 'content-box',
            top: '50%',
            left: '50%',
            pointerEvents: 'none',
            position: 'absolute',
            transform: 'translate(-50%,-50%)',
          },
        },
      },
    },
    pin: {},
  }),
  { name: 'Checkout_DealersMap' },
);

export default dealersMapStyles;
