import React from 'react';
import universal, { UniversalComponent } from 'react-universal-component';
import errorHandler from '../../../../common/service/errorHandler';
import MinimalLoader from '../../../../common/components/presentation/MinimalLoader/MinimalLoader';
import ThemedErrorMessage from '../../../../common/components/presentation/Error/ThemedErrorMessage';
import IThemableComponent from '../../../../common/theme/IThemableComponent';
import { CheckoutConfirmationScreenClasses } from './checkoutConfirmationScreenStyles';
import { CarConfirmationDetailsClasses } from './orderJourney/carConfirmationDetailsStyle';
import { ConnectWithMyAccountClasses } from './orderJourney/connectWithMyAccountStyles';
import { OnlineApplicationStylesClasses } from './orderJourney/onlineApplicationStyles';

export interface ICheckout4ConfirmationThemableComponents {
  Checkout4_CheckoutConfirmationScreen: IThemableComponent<CheckoutConfirmationScreenClasses>;
  Checkout4_CarConfirmationDetails: IThemableComponent<CarConfirmationDetailsClasses>;
  Checkout4_ConnectMyAccount: IThemableComponent<ConnectWithMyAccountClasses>;
  Checkout4_OnlineApplication: IThemableComponent<OnlineApplicationStylesClasses>;
}

const CheckoutConfirmation: UniversalComponent<any> = universal<any>(() => import('./CheckoutConfirmationScreen'), {
  loading: (): JSX.Element => <MinimalLoader />,
  error: (props: { error: Error }) => {
    errorHandler.handleError(props.error);
    return (
      <ThemedErrorMessage
        fullPage={true}
        message={'Failed loading checkout confirmation screen.'}
        error={props.error}
      />
    );
  },
});

export default CheckoutConfirmation;
