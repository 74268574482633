import { createContext } from 'react';
import { IStep } from '../../model/IJourneySteps';

export interface IJourneyStepsContextProps {
  steps: IStep[];
  activeStep: number;
  handleNext: () => void;
  changeActiveStep: (step: number) => void;
}

const JourneyStepsContext = createContext<IJourneyStepsContextProps>({
  steps: [],
  activeStep: 0,
  handleNext: () => null,
  changeActiveStep: () => null,
});

export default JourneyStepsContext;
