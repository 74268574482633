import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';

interface IShowIfTranslationExists {
  translationKey: string;
  children: React.ReactElement;
}

const ShowIfTranslationExists: FunctionComponent<IShowIfTranslationExists> = ({ translationKey, children }) => {
  const intl = useIntl();
  const translated = intl.formatMessage({ id: translationKey });
  if (translated.trim() === '' || translated === translationKey) {
    return null;
  }

  return children;
};

export default ShowIfTranslationExists;
