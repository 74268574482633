import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../theme/ICheckoutTheme';

export type MyAccountMiniContainerClasses = 'container' | 'buttonWrapper';

const myAccountMiniContainerStyles = makeStyles<ICheckoutTheme, MyAccountMiniContainerClasses>(
  theme => ({
    container: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
      zIndex: 2,
    },
    buttonWrapper: {
      display: 'flex',
      textAlign: 'left',
      fontSize: theme.typography.fontMD,
      backgroundColor: theme.palette.common.white,
      maxWidth: '96%',
    },
  }),
  { name: 'Checkout4_MyAccountMiniContainer' },
);

export default myAccountMiniContainerStyles;
