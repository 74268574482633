import { gql } from '@apollo/client/core';
import badge from './badge';
import money from './money';

const discount = {
  DISCOUNT: gql`
    ${badge.BADGE}
    ${money.MONEY}
    fragment Discount on Discount {
      code
      badge {
        ...Badge
      }
      disclaimer
      label
      value {
        ...Money
      }
    }
  `,
};
export default discount;
