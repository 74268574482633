import React, { FunctionComponent, useEffect, useState } from 'react';
import { Box, InputLabel, MenuItem, Select, Typography } from '@material-ui/core';
import dropdownSelectStyles from './dropdownSelectStyles';
import { IOption, ISchemaFieldProps } from '../../model/IJsonSchema';
import clsx from 'clsx';

const DropdownSelect: FunctionComponent<ISchemaFieldProps> = ({ formData }) => {
  const styles = dropdownSelectStyles();
  const {
    options: { inputLabel, enumOptions },
    label,
    id,
    required,
    value,
    onChange,
    placeholder,
    formContext,
    disabled,
    schema: { readOnly },
  } = formData;
  const [enumOptionsList, setEnumOptionsList] = useState(enumOptions);

  useEffect(() => {
    if (id === 'root_location_dealer' && formContext.availableDealers.length) {
      setEnumOptionsList(formContext.availableDealers);
    }
  }, [formContext.availableDealers]);

  // TODO: find nicer way to fix it XPD-1248, XPD-20261
  useEffect(() => {
    setEnumOptionsList(enumOptions);
  }, [enumOptions]);
  if (id === 'root_housing_housingDistrict') {
    window.addEventListener('root_housing_housingDepartment_change', () => {
      setEnumOptionsList([]);
    });
  }
  if (id === 'root_provinceRegionDistrict_province') {
    window.addEventListener('root_provinceRegionDistrict_region_change', () => {
      setEnumOptionsList([]);
    });
  }

  const handleChange = (e: React.ChangeEvent<{ value: unknown }>) => {
    if (id === 'root_housing_housingDepartment') {
      window.dispatchEvent(new Event('root_housing_housingDepartment_change'));
    }
    if (id === 'root_provinceRegionDistrict_region') {
      window.dispatchEvent(new Event('root_provinceRegionDistrict_region_change'));
    }
    onChange(e.target.value as string);
  };

  return (
    <Box className={styles.fieldWrapper}>
      {inputLabel !== false && (
        <Typography variant={'subtitle1'} data-test={`dropdown_select:label:${id}`} className={styles.hideSalutation}>
          <InputLabel className={styles.label} htmlFor={id} required={required}>
            {label}
          </InputLabel>
        </Typography>
      )}
      <Select
        id={id}
        className={clsx(styles.select, !value && styles.itemDisabled)}
        disabled={!!readOnly || disabled}
        labelId={id}
        value={value || '-1'}
        label={label}
        variant="outlined"
        data-test={`dropdown_select:${id}`}
        onChange={handleChange}
        classes={{
          root: styles.root,
          select: styles.select,
          outlined: styles.outlined,
          disabled: styles.disabled,
        }}
      >
        {!!placeholder && (
          <MenuItem
            value={'-1'}
            disabled={true}
            classes={{
              root: clsx(styles.itemDisabled),
            }}
          >
            {placeholder}
          </MenuItem>
        )}
        {// todo: maybe an upgrade of "react-jsonschema-form": "2.0.0-alpha.1" would fix this filter empty hack.
        enumOptionsList ? (
          enumOptionsList
            .filter(option => option.value)
            .map((option: IOption, index: number) => {
              return (
                <MenuItem
                  key={index}
                  value={option?.value}
                  data-test={`enquiry_form:dropdown_select:option:${option?.label}`}
                  classes={{
                    root: styles.item,
                  }}
                >
                  {option?.label}
                </MenuItem>
              );
            })
        ) : (
          <MenuItem />
        )}
      </Select>
    </Box>
  );
};

export default DropdownSelect;
