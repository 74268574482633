import React, { useEffect } from 'react';
import { matchPath, useHistory, useLocation } from 'react-router';
import { Routes } from '../../../common/enum/Routes';
import { JourneyStepsRoutes } from '../enum/JourneyStepsRoutes';
import { UrlParamTypes } from '../../../common/enum/UrlParamTypes';
import usePlatformConfig from '../../../common/react/usePlatformConfig';

const Checkout4Redirects: React.FunctionComponent = () => {
  const {
    platformConfig: {
      platformFeatures: { isCheckout4Enabled },
    },
  } = usePlatformConfig();
  const history = useHistory();
  const { pathname, search: queryString } = useLocation();
  const searchParams = new URLSearchParams(queryString);
  const from = searchParams.get(UrlParamTypes.FROM) || '';
  const token = searchParams.get(UrlParamTypes.TOKEN) || '';

  const confirmationRouteMatch = matchPath(pathname, {
    path: [Routes.CONFIRMATION_REQUEST_QUOTE, Routes.CONFIRMATION_RESERVE_ONLINE],
  });
  const paymentRouteMatch = matchPath(pathname, {
    path: [Routes.PAYMENT],
  });
  const nextStepsRouteMatch = matchPath(pathname, {
    path: [Routes.REQUEST_QUOTE, Routes.RESERVE_ONLINE],
  });

  useEffect(() => {
    if (isCheckout4Enabled) {
      const search = `${UrlParamTypes.TOKEN}=${token}${from ? `&${UrlParamTypes.FROM}=${from}` : ''}`;

      if (confirmationRouteMatch) {
        history.replace({
          pathname: JourneyStepsRoutes.CONFIRMATION,
          search,
        });
      } else if (paymentRouteMatch) {
        history.replace({
          pathname: JourneyStepsRoutes.PAYMENT,
          search,
        });
      } else if (nextStepsRouteMatch) {
        history.replace({
          pathname: JourneyStepsRoutes.NEXT_STEPS,
          search,
        });
      }
    }
  }, [history, isCheckout4Enabled, paymentRouteMatch, confirmationRouteMatch, nextStepsRouteMatch, pathname]);

  return null;
};

export default Checkout4Redirects;
