import { gql } from '@apollo/client/core';

const model = {
  MODEL: gql`
    fragment Model on Model {
      code
      attributes {
        oemcode
        shortname
        modelyear
        brand
        label
        fullSpecsUrl
        longdescription
      }
    }
  `,
};
export default model;
