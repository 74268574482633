import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type SelectDealerButtonClasses = 'buttonLabel' | 'isSelected' | 'newOutlinedPrimary';

const selectDealerButtonStyles = makeStyles<ICheckoutTheme, SelectDealerButtonClasses>(
  theme => ({
    buttonLabel: {
      height: 34,
      width: 120,
      borderRadius: 0,
      fontWeight: 300,
      textTransform: 'uppercase',
      '& span': {
        fontSize: theme.typography.fontXS,
      },
      '&:disabled': {
        color: theme.palette.common.white,
      },
    },
    isSelected: {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
      '&:focus': {
        backgroundColor: `${theme.palette.primary.main}!important`,
      },
      color: theme.palette.common.white,
    },
    newOutlinedPrimary: {},
  }),
  { name: 'Checkout_SelectDealerButton' },
);

export default selectDealerButtonStyles;
