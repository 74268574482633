import React, { FunctionComponent } from 'react';
import { ISchemaFieldProps } from 'src/enquiryForm/model/IJsonSchema';
import snarkdown from 'snarkdown';
import markdownTextStyles from './markdownTextStyles';
import { Typography } from '@material-ui/core';

const MarkdownText: FunctionComponent<ISchemaFieldProps> = ({ formData: { label } }) => {
  const styles = markdownTextStyles();

  return (
    <Typography
      className={styles.text}
      dangerouslySetInnerHTML={{ __html: snarkdown(label) }}
      data-test="text:enquiryForm:markdownText"
    />
  );
};

export default MarkdownText;
