import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type ExpressionsOfInterestClasses = 'container' | 'title' | 'description' | 'itemsWrapper';

const expressionsOfInterestStyles = makeStyles<ICheckoutTheme, ExpressionsOfInterestClasses>(
  ({ breakpoints }) => ({
    container: {
      marginBottom: '10px',
      textAlign: 'center',
      width: '100%',

      [breakpoints.down('sm')]: {
        padding: '0 15px',
      },
    },
    title: {
      display: 'block',
      fontWeight: 700,
      marginBottom: '10px',
    },
    description: {
      display: 'block',
      marginBottom: '30px',
    },
    itemsWrapper: {
      justifyContent: 'center',
    },
  }),
  { name: 'GqlCheckout_ExpressionsOfInterest' },
);

export default expressionsOfInterestStyles;
