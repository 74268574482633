import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';
import { constants } from '../../../../../common/constants/constants';
import queryParams from '../../../../../common/service/queryParams';
import useCheckoutContext from './useCheckoutContext';

export const useGqlNav = () => {
  const location = useLocation();
  const history = useHistory();
  const token = queryParams.getParam(constants.TOKEN, location);
  const { deal } = useCheckoutContext();
  const configurationSourceUrl = useMemo(() => deal?.car?.configurationSourceUrl, [deal]);

  return {
    location,
    history,
    token,
    configurationSourceUrl,
  };
};
