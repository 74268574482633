import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';

export type ContinueButtonClasses = 'continueButton' | 'icon' | 'containedPrimary' | 'contained';

const continueButtonsStyles = makeStyles<ICheckoutTheme, ContinueButtonClasses>(
  theme => ({
    continueButton: {
      height: 40,
      width: 180,
      borderRadius: 0,
      color: theme.palette.common.white,

      '& > span': {
        fontSize: theme.typography.fontXS,
        fontWeight: '300',
        textTransform: 'uppercase',
      },
    },
    icon: {
      color: theme.palette.common.white,
      fontSize: theme.typography.fontMD,
    },
    containedPrimary: {},
    contained: {},
  }),
  { name: 'GqlCheckout_DealershipContinueButton' },
);

export default continueButtonsStyles;
