import { Validator, ValidatorResult } from 'jsonschema';
import ValidationError from '../error/ValidationError';
import IWidgetConfiguration from '../../../../common/model/IWidgetConfiguration';

export const plainConfigValidator = {
  validate(object: IWidgetConfiguration, mapping: any): true | any {
    const validator = new Validator();
    const valid: ValidatorResult = validator.validate(object, mapping);

    if (valid.errors.length) {
      const errors = valid.errors.map((error: any) => {
        return { key: error.property, message: error.message };
      });

      return new ValidationError(`Invalid widget configuration.`, errors);
    }

    if (!document.getElementById(object.containerId)) {
      return new ValidationError(`Invalid widget configuration.`, [
        { key: 'containerId', message: `Html element with id '${object.containerId}' not found in DOM.` },
      ]);
    }

    return true;
  },
};
