import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';

export type textTooltipClasses = 'tooltipArrow' | 'tooltip';

const textTooltipStyles = makeStyles<ICheckoutTheme, textTooltipClasses>(
  theme => ({
    tooltipArrow: {
      color: theme.palette.primary.main,
      fontSize: theme.typography.fontXS, // controls the arrow size
    },
    tooltip: {
      border: '1.5px solid',
      borderColor: theme.palette.primary.main,
      borderRadius: '10px',
      background: theme.palette.common.white,
      padding: theme.spacing(1.5),
      color: theme.palette.common.black,
      fontSize: theme.typography.fontXS,
    },
  }),
  { name: 'Summary_Text_Tooltip' },
);

export default textTooltipStyles;
