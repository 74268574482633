import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from 'src/common/theme/ICheckoutTheme';

export type NavigationStepperClasses =
  | 'stepper'
  | 'stepConnector'
  | 'iconContainer'
  | 'stepLabel'
  | 'stepIconText'
  | 'active'
  | 'completed'
  | 'root'
  | 'stepConnectorCompleted'
  | 'stepButton'
  | 'subHeaderText';

const navigationStepperStyles = makeStyles<ICheckoutTheme, NavigationStepperClasses>(
  ({ typography, palette, breakpoints }) => ({
    stepper: {
      margin: '60px auto',
      maxWidth: 780,
      width: '100%',
      overflow: 'hidden',

      [breakpoints.down(breakpoints.values.md)]: {
        padding: '0 15px',
      },
    },
    stepConnector: {
      borderColor: palette.text.disabled,
      [breakpoints.down(breakpoints.values.md)]: {
        marginBottom: 20,
      },
    },
    stepConnectorCompleted: {
      '& span': {
        borderColor: palette.primary.main,
        borderWidth: 3,
      },
    },
    iconContainer: {
      '& svg': {
        width: 40,
        height: 40,

        [breakpoints.down(breakpoints.values.sm)]: {
          width: 32,
          height: 32,
        },
      },
    },
    stepIconText: {
      fontWeight: typography.fontWeightBold,
    },
    stepLabel: {
      fontSize: typography.fontLG,
      color: palette.text.disabled,

      '& span': {
        fontWeight: typography.fontWeightBold,
      },

      [breakpoints.down(breakpoints.values.sm)]: {
        fontSize: typography.fontMD,
      },
    },
    active: {},
    completed: {},
    root: {},
    stepButton: {
      border: 'none',
      '&:hover': {
        border: 'none',
      },
    },
    subHeaderText: {
      width: '100%',
      textAlign: 'center',
      paddingTop: '30px',
    },
  }),
  { name: 'Checkout_NavigationStepper' },
);

export default navigationStepperStyles;
