import { makeStyles } from '@material-ui/core';
import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';

export type ExpressionOfInterestSummaryClasses = 'item';

const expressionOfInterestSummaryStyles = makeStyles<ICheckoutTheme, ExpressionOfInterestSummaryClasses>(
  ({ typography, palette }) => ({
    item: {
      fontSize: typography.fontSM,
      color: palette.text.secondary,
    },
  }),
  { name: 'GqlCheckout_ExpressionOfInterestSummary' },
);
export default expressionOfInterestSummaryStyles;
