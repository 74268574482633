import React, { FC } from 'react';
import Exception from './Exception';
import { Container } from '@material-ui/core';

const AccessDeniedPage: FC = () => {
  return (
    <Container>
      <Exception messageId={'exception.accessDenied.message'} />
    </Container>
  );
};

export default AccessDeniedPage;
