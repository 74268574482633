import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { SelectDealer, SelectDealerVariables } from '../../graphql/mutations/__generated__/SelectDealer';
import deal from '../../graphql/mutations/selectDealer';
import useErrorHandler from '../../../../common/hooks/useErrorHandler';

export const useSelectDealer = (dealId: string, dealerId: string) => {
  const [selectDealer, { error, loading }] = useMutation<SelectDealer, SelectDealerVariables>(deal.SELECT_DEALER);
  const select = useCallback(async () => {
    const dealData = await selectDealer({
      variables: {
        dealerId,
        dealId,
      },
    });

    return dealData;
  }, [dealerId, dealId]);

  useErrorHandler(error);

  return {
    selectDealer: select,
    loading,
    error,
  };
};
