import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';

export type PaymentEwayStylesClasses =
  | 'container'
  | 'payButton'
  | 'subtitle'
  | 'description'
  | 'email'
  | 'description2';

const paymentEwayStyles = makeStyles<ICheckoutTheme, PaymentEwayStylesClasses>(
  ({ typography, palette }) => ({
    container: {},
    initButton: {},
    subtitle: {
      fontWeight: typography.fontWeightBold,
      fontSize: '20px',
      textAlign: 'center',
      paddingBottom: '10px',
    },
    description: {
      fontSize: '16px',
      textAlign: 'center',
      paddingBottom: '10px',
      whiteSpace: 'pre-wrap',
    },
    payButton: {
      margin: '30px auto',
      display: 'block',
      maxWidth: 'unset',
      width: 'fit-content',
      minWidth: '270px',
    },
    email: {
      display: 'inline',
      color: palette.primary.main,
      textDecoration: 'underline',
    },
    description2: {
      fontSize: typography.fontMD,
      textAlign: 'center',
      marginTop: '20px',
      display: 'block',
    },
  }),
  { name: 'Gql_PaymentEway' },
);

export default paymentEwayStyles;
