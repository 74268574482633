import { gql } from '@apollo/client/core';

const cancelVehicleReservationMutation = {
  CANCEL_VEHICLE_RESERVATION: gql`
    mutation CancelVehicleReservation($dealId: String!) {
      cancelVehicleReservation(dealId: $dealId)
    }
  `,
};

export default cancelVehicleReservationMutation;
