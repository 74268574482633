import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../theme/ICheckoutTheme';

export type ModalPaperClasses = 'closeIcon' | 'closeButton' | 'overlayPaper' | 'insetPaper';

const modalPaperStyles = makeStyles<ICheckoutTheme, ModalPaperClasses>(
  theme => ({
    closeButton: {
      '&:not(:disabled)': {
        zIndex: 1000,
        position: 'absolute',
        padding: 0,

        '&:hover': {
          background: 'transparent',
        },

        [theme.breakpoints.down('sm')]: {
          transform: 'scale(0.88)',
          top: 5,
          right: 5,
        },
        [theme.breakpoints.up('md')]: {
          transform: 'scale(1.3)',
          top: 0,
          right: -40,
          color: theme.palette.common.white,
        },
      },
    },
    closeIcon: {
      width: 40,
      height: 40,
      fill: theme.palette.primary.main,
    },
    overlayPaper: {
      width: 750,
      overflow: 'visible',
      margin: '48px',
      [theme.breakpoints.down('sm')]: {
        margin: 0,
      },
    },
    insetPaper: {
      margin: 0,
      boxShadow: 'none',
    },
  }),
  { name: 'Checkout_ModalPaper' },
);

export default modalPaperStyles;
