import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type DealerListHeaderClasses = 'headerContainer' | 'headerTitle';

const dealerListHeaderStyles = makeStyles<ICheckoutTheme, DealerListHeaderClasses>(
  theme => ({
    headerContainer: {
      display: 'flex',
      verticalAlign: 'center',
      justifyContent: 'center',
      width: '100%',
      minHeight: 40,
      background: theme.palette.background.header,
    },
    headerTitle: {
      textTransform: 'uppercase',
      fontWeight: 400,
      alignSelf: 'center',
      color: theme.palette.common.white,
    },
  }),
  { name: 'Checkout_DealerListHeader' },
);

export default dealerListHeaderStyles;
