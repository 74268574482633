import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import MyAccountMiniDxp from './MyAccountMiniDxp';
import { MyAccountDisplayMode } from '../../enum/MyAccountDisplayMode';
import useToken from '../../../gql/common/hooks/useToken';
import { MyAccountEvents } from '../../enum/MyAccountEvents';
import { customEvent } from '../../service/customEvent';
import myAccountMiniContainerStyles from './myAccountMiniContainerStyles';

interface MyAccountMiniContainerProps {
  dataTestId: string;
  loginInModal?: boolean;
  dealId?: string;
  displayMode?: MyAccountDisplayMode;
  className?: string;
  introducedEmail?: string;
  shouldProceedFlow?: string;
}
const MyAccountMiniContainer: FunctionComponent<MyAccountMiniContainerProps> = ({
  dataTestId,
  loginInModal = false,
  dealId,
  displayMode,
  introducedEmail,
  shouldProceedFlow,
}) => {
  const { getCurrentToken } = useToken();
  const styles = myAccountMiniContainerStyles();
  const [accessToken, setAccessToken] = useState(getCurrentToken());
  const updateAccessToken = useCallback(() => {
    setAccessToken(getCurrentToken());
  }, [accessToken]);

  useEffect(() => {
    if (!getCurrentToken()) {
      customEvent.listen(MyAccountEvents.ON_STORE_ACCESS_TOKEN, updateAccessToken);
    }

    return () => {
      if (getCurrentToken()) {
        customEvent.removeListener(MyAccountEvents.ON_STORE_ACCESS_TOKEN, updateAccessToken);
      }
    };
  }, [accessToken]);

  return (
    <Box className={styles.container}>
      <Box className={styles.buttonWrapper} data-test={'dxp:mini_my_account:container'}>
        <MyAccountMiniDxp
          dealId={dealId}
          loginInModal={loginInModal}
          displayMode={displayMode}
          data-test={dataTestId}
          introducedEmail={introducedEmail}
          shouldProceedFlow={shouldProceedFlow}
        />
      </Box>
    </Box>
  );
};

export default MyAccountMiniContainer;
