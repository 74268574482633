import { Routes } from '../enum/Routes';

export const MY_ACCOUNT_CONFIG = {
  widgetUrl: process.env.REACT_APP_MY_ACCOUNT_BASE_WIDGET_URL + 'library/widgets.js' || '',
  dxpWidgetUrl:
    process.env.REACT_APP_DXP_WIDGETS_BASE_PATH +
    `${process.env.REACT_APP_COUNTRY}/${process.env.REACT_APP_BRAND_CODE}/widgets/my-account/dist/my-account.esm.js`,
};

export const getPersonalDetailsUrl = (myAccountUrl: string) => `${myAccountUrl}${Routes.MYACCOUNT_PERSONAL_DETAILS}`;
