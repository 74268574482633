import usePlatformConfig from '../../../../common/react/usePlatformConfig';
import priceFormatter from '../../service/priceFormatter';
import useWidgetContext from '../../../../common/react/useWidgetContext';

export const gqlPriceFormatter = () => {
  const {
    platformConfig: {
      priceDisplay: {
        primaryOptions: { currency, decimalScale, currencyFormatConfig },
      },
    },
  } = usePlatformConfig();
  const {
    configuration: {
      dimensions: { language, country },
    },
  } = useWidgetContext();

  return priceFormatter.getFormatter({
    language,
    country,
    currency,
    decimalScale,
    currencyFormatConfig,
  });
};
