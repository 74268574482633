import React, { FunctionComponent } from 'react';
import selectDealerButtonStyles from './selectDealerButtonStyles';
import Translation from '../../../../../../common/components/presentation/Translation/Translation';
import DefaultButton from '../../../../../../common/components/presentation/Buttons/DefaultButton';

export interface ISelectDealerButtonProps {
  isSelected: boolean;
  onClick: () => void;
  testId: string;
  disableDealerSelection?: boolean;
}

const SelectDealerButton: FunctionComponent<ISelectDealerButtonProps> = ({
  isSelected,
  onClick,
  testId,
  disableDealerSelection,
}) => {
  const styles = selectDealerButtonStyles();
  const disableButton = isSelected || !!disableDealerSelection;
  const translationId = isSelected
    ? 'trans__checkout__dealers_page__selected_CTA'
    : 'trans__checkout__dealers_page__select_CTA';

  return (
    <DefaultButton
      disabled={disableButton}
      onClick={onClick}
      className={[isSelected && styles.isSelected, styles.buttonLabel]}
      dataTest={testId}
      variant="outlined"
      outlinedPrimary={styles.newOutlinedPrimary}
    >
      <Translation id={translationId} />
    </DefaultButton>
  );
};

export default SelectDealerButton;
