import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type FailedPaymentErrorClasses = 'icon' | 'subTitle' | 'email' | 'description';

const failedPaymentErrorStyles = makeStyles<ICheckoutTheme, FailedPaymentErrorClasses>(
  () => ({
    icon: {},
    subTitle: {
      marginBottom: '25px',
    },
    email: {},
    description: {},
  }),
  { name: 'Gql_FailedPaymentError' },
);

export default failedPaymentErrorStyles;
