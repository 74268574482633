import { IPriceDisplay } from "../model/IPriceDisplay";
import { TradeInIntegrationType } from "../enum/tradeInIntegrationType";

interface TradeInFeatureConfiguration {
  type?: string;
}

const platformConfigService = {
  getPXIntegrationType(data: any): TradeInIntegrationType {
    const tradeInFeatureConfiguration: TradeInFeatureConfiguration = Object.values(
      (data as any).partExchange || {}
    ).pop();
    if (
      Object.values(TradeInIntegrationType).includes(
        tradeInFeatureConfiguration?.type as TradeInIntegrationType
      )
    ) {
      return tradeInFeatureConfiguration.type as TradeInIntegrationType;
    }

    return TradeInIntegrationType.NONE;
  },

  normalizePriceDisplay(data: any): IPriceDisplay {
    const priceDisplay: any = { secondaryOptions: false };

    Object.entries(data).forEach(([, value]: any) => {
      const priceDisplayOptions = value;

      Object.entries(priceDisplayOptions).forEach(([key, value]: any) => {
        priceDisplay[key] = value;
      });
    });

    return priceDisplay;
  },
  fromJsonnet(data: any): IPriceDisplay {
    const priceDisplay: any = { secondaryOptions: false };

    Object.entries(data).forEach(([key, value]: any) => {
      priceDisplay[key] = value;
    });

    return priceDisplay;
  }
};

export default platformConfigService;
