import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';

export type FinanceAndInsuranceClasses =
  | 'container'
  | 'image'
  | 'imageRoot'
  | 'details'
  | 'summaryLabel'
  | 'buttonsWrapper'
  | 'financeDetails';

const financeAndInsuranceStyles = makeStyles<ICheckoutTheme, FinanceAndInsuranceClasses>(
  theme => ({
    container: {
      padding: '20px 0',
    },
    imageRoot: {
      padding: 0,
      [theme.breakpoints.up('lg')]: {
        margin: '0 0 30px 0',
      },
      [theme.breakpoints.down('sm')]: {
        margin: '0 auto',
      },
    },
    image: {
      marginBottom: '10px',
      objectFit: 'contain',
    },
    details: {
      marginBottom: '15px',
      fontFamily: theme.typography.fontFamily,
    },
    summaryLabel: {
      fontWeight: 900,
    },
    buttonsWrapper: {
      display: 'flex',

      [theme.breakpoints.down('xs')]: {
        alignItems: 'center',
        flexDirection: 'column',
      },
    },
    financeDetails: {},
  }),
  { name: 'Checkout4_FinanceAndInsurance' },
);

export default financeAndInsuranceStyles;
