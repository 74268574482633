export const paymentApiWebpay = {
  initializePaymentTransaction(webPayToken: string, submissionURL: string): void {
    const form: HTMLFormElement = document.createElement('form');
    form.setAttribute('method', 'POST');
    form.setAttribute('enctype', 'x-www-form-urlencoded');
    form.setAttribute('action', submissionURL);
    form.setAttribute('style', 'display: none');

    const formInput: any = document.createElement('input');
    formInput.setAttribute('name', 'token_ws');
    formInput.setAttribute('type', 'text');
    formInput.setAttribute('value', webPayToken);

    form.appendChild(formInput);
    document.body.appendChild(form);
    form.submit();
  },
};
