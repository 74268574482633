import React from 'react';
import universal, { UniversalComponent } from 'react-universal-component';
import ThemedErrorMessage from '../../../common/components/presentation/Error/ThemedErrorMessage';
import errorHandler from '../../../common/service/errorHandler';
import MinimalLoader from '../../../common/components/presentation/MinimalLoader/MinimalLoader';
import IThemableComponent from '../../../common/theme/IThemableComponent';
import { BackToFinanceButtonClasses } from './components/financeSection/button/backToFinanceButtonStyles';
import { FinanceSectionClasses } from './components/financeSection/financeSectionStyles';
import { ExpressionsOfInterestItemClasses } from '../../commonSteps/extras/components/expressionsOfInterest/item/itemStyles';
import { ExpressionsOfInterestItemButtonClasses } from '../../commonSteps/extras/components/expressionsOfInterest/button/itemButtonStyles';

export interface IGqlExtrasThemableComponents {
  Checkout_FinanceSection: IThemableComponent<FinanceSectionClasses>;
  Checkout_BackToFinanceButton: IThemableComponent<BackToFinanceButtonClasses>;
  GqlCheckout_ExpressionsOfInterest_Item: IThemableComponent<ExpressionsOfInterestItemClasses>;
  GqlCheckout_ExpressionsOfInterest_ItemButton: IThemableComponent<ExpressionsOfInterestItemButtonClasses>;
}

const DeferredExtras: UniversalComponent<any> = universal<any>(
  () => import(/* webpackChunkName: "extras" */ './components/Extras'),
  {
    loading: (): JSX.Element => <MinimalLoader />,
    error: (props: { error: Error }) => {
      errorHandler.handleError(props.error);
      return <ThemedErrorMessage fullPage={true} message={'Failed loading extras screen.'} error={props.error} />;
    },
  },
);

export default DeferredExtras;
