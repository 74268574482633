import { useEffect, useState } from 'react';
import { GeolocationPermissionStatus } from '../enum/GeolocationPermissionStatus';

interface IUseGeolocationPermissionStatusReturn {
  permissionStatus: GeolocationPermissionStatus;
}

const useGeolocationPermissionStatus = (): IUseGeolocationPermissionStatusReturn => {
  const [status, setStatus] = useState(null);

  useEffect(() => {
    (async function getPermissions() {
      const permissionStatus = await navigator?.permissions?.query({ name: 'geolocation' });
      if (permissionStatus?.state) {
        setStatus(permissionStatus.state);
      }
    })();
  });

  return { permissionStatus: status };
};

export default useGeolocationPermissionStatus;
