import { FunctionComponent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import React from 'react';
import useWidgetContext from '../../react/useWidgetContext';
import { MY_ACCOUNT_CONFIG } from '../../constants/myAccount';
import { assetManager } from '../../../../../common/service/assetManager';
import CONSTANTS from '../../constants/constants';
import errorHandler from '../../service/errorHandler';
import MinimalLoader from '../presentation/MinimalLoader/MinimalLoader';
import { MyAccountDisplayMode } from '../../enum/MyAccountDisplayMode';

interface MyAccountMiniDxpProps {
  loginInModal?: boolean;
  displayMode: MyAccountDisplayMode;
  dealId: string;
  'data-test'?: string;
  introducedEmail?: string;
  shouldProceedFlow?: string;
}
const MyAccountMiniDxp: FunctionComponent<MyAccountMiniDxpProps> = ({
  loginInModal = false,
  displayMode = MyAccountDisplayMode.LOGIN_BUTTON,
  dealId,
  'data-test': dataTest,
  introducedEmail,
  shouldProceedFlow,
}) => {
  const widgetId = useMemo(() => 'dxp-myaccount-mini-container' + Math.floor(Math.random() * 100), [
    loginInModal,
    displayMode,
    dealId,
  ]);
  const widgetInstance: any = useRef(null);
  const myAccountLibrary: any = useRef(null);
  const { configuration } = useWidgetContext();
  const [isLoading, setLoadingStatus] = useState(false);

  const ensureLibraryLoaded = () => {
    const widgetUrl: string = MY_ACCOUNT_CONFIG.dxpWidgetUrl;

    if (assetManager.isScriptLoaded(widgetUrl)) {
      if (!myAccountLibrary.current && (window as any).DXPMyAccount) {
        myAccountLibrary.current = (window as any).DXPMyAccount;
      }

      return Promise.resolve();
    }

    return Promise.resolve()
      .then(() => {
        // keep in mind there's a slight difference between the react versions of dxp and auto-widgets
        if (!assetManager.isScriptLoaded(CONSTANTS.REACT_DEPENDENCIES.react18)) {
          return assetManager
            .loadScript(CONSTANTS.REACT_DEPENDENCIES.react18)
            .then(() => assetManager.loadScript(CONSTANTS.REACT_DEPENDENCIES.reactDom18));
        }
      })
      .then(() => assetManager.loadScript(widgetUrl, 'module'))
      .then(() => {
        myAccountLibrary.current = (window as any).DXPMyAccount;
      });
  };

  const getLibrary = useCallback(() => {
    if (!myAccountLibrary.current) {
      throw new Error(`Attempted to use DXP MyAccount library without loading it first`);
    }

    return myAccountLibrary.current;
  }, [myAccountLibrary.current]);

  const initializeWidget = () => {
    if (widgetInstance.current) {
      widgetInstance.current.destroy();
    }

    setLoadingStatus(true);

    const widgetConfig: any = {
      containerId: widgetId,
      dimensions: configuration.dimensions,
      cookieDomain: process.env.REACT_APP_SESSION_COOKIE_DOMAIN,
      loginInModal,
      displayMode,
      dealId,
      ...(shouldProceedFlow && {
        activeTab: shouldProceedFlow,
      }),
      ...(introducedEmail && {
        introducedEmail,
      }),
      myAccountUrl: configuration.myAccountUrl,
    };

    if (configuration.cookieDomain) {
      widgetConfig.cookieDomain = configuration.cookieDomain;
    }

    const dxpWidget = getLibrary();
    dxpWidget.createMiniWidget(widgetConfig);
  };

  useEffect(() => {
    if (!widgetInstance.current) {
      ensureLibraryLoaded()
        .then(() => initializeWidget())
        .catch(errorHandler.promise())
        .finally(() => setLoadingStatus(false));
    }

    return () => {
      if (widgetInstance.current) {
        widgetInstance.current.destroy();
      }
    };
  }, []);

  return (
    <>
      {isLoading && <MinimalLoader />}
      <div id={widgetId} data-test={dataTest} />
    </>
  );
};

export default MyAccountMiniDxp;
