import { gql } from '@apollo/client/core';

const enquiryForm = {
  GET_ENQUIRY_FORM_SCHEMA: gql`
    query GetEnquiryFormSchema($token: String!, $state: String) {
      enquiryForm(token: $token, state: $state) {
        schema
        uiSchema
        formData
      }
    }
  `,
};

export default enquiryForm;
