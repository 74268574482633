import React from 'react';
import universal, { UniversalComponent } from 'react-universal-component';
import errorHandler from '../common/service/errorHandler';
import ThemedErrorMessage from '../common/components/presentation/Error/ThemedErrorMessage';
import MinimalLoader from '../common/components/presentation/MinimalLoader/MinimalLoader';

const DeferredReserveOnline: UniversalComponent<any> = universal<any>(
  () => import(/* webpackChunkName: "enquiry-form" */ './components/ReserveOnline'),
  {
    loading: (): JSX.Element => <MinimalLoader />,
    error: (props: { error: Error }) => {
      errorHandler.handleError(props.error);
      return (
        <ThemedErrorMessage fullPage={true} message={'Failed loading reserve online screen.'} error={props.error} />
      );
    },
  },
);

export default DeferredReserveOnline;
