import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type DealersListClasses = 'list';

const dealersListStyles = makeStyles<ICheckoutTheme, DealersListClasses>(
  theme => ({
    list: {
      backgroundColor: theme.palette.background.paper,
      position: 'relative',
      overflow: 'auto',
      height: 435,

      [theme.breakpoints.down('sm')]: {
        maxHeight: 800,
      },
    },
  }),
  { name: 'Checkout_DealersList' },
);

export default dealersListStyles;
