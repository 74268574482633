import { gql } from '@apollo/client/core';
import baseImage from './baseImage';

const background = {
  BACKGROUND: gql`
    ${baseImage.BASE_IMAGE}

    fragment Background on Background {
      code
      shortname
      attributes {
        oemcode
      }
      image {
        ...BaseImage
      }
    }
  `,
};

export default background;
