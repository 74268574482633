import { useCallback } from 'react';
import { useReactiveVar } from '@apollo/client';
import useCheckoutContext from './useCheckoutContext';
import { JourneyType } from '../../../__generated__/globalTypes';
import { UrlParamTypes } from '../../../common/enum/UrlParamTypes';
import { activeJourneyType } from '../graphql/cache';

const useGetConfigurationUrl = () => {
  const { deal } = useCheckoutContext();
  const { code, configurationSourceUrl } = deal?.car || {};
  const journeyType = useReactiveVar(activeJourneyType);

  const getConfigurationUrl = useCallback(() => {
    if (journeyType === JourneyType.online_order || journeyType === JourneyType.reserve_online) {
      return `${configurationSourceUrl}?${UrlParamTypes.REVERT_TO_BYO}=${code}&${UrlParamTypes.NO_MODAL}=true`;
    }

    return configurationSourceUrl;
  }, [journeyType, code, configurationSourceUrl]);

  return { getConfigurationUrl };
};

export default useGetConfigurationUrl;
