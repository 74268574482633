import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';

export type Payment2C2PClasses =
  | 'subtitle'
  | 'description'
  | 'payButton'
  | 'iframeContainer'
  | 'email'
  | 'description2'
  | 'hiddenFrame'
  | 'container';

const payment2C2PStyles = makeStyles<ICheckoutTheme, Payment2C2PClasses>(
  ({ palette, typography, breakpoints }) => ({
    subtitle: {
      fontWeight: typography.fontWeightBold,
      fontSize: '20px',
      textAlign: 'center',
      paddingBottom: '10px',
    },
    description: {
      fontSize: '16px',
      textAlign: 'center',
      paddingBottom: '10px',
      whiteSpace: 'pre-wrap',
    },
    payButton: {
      fontWeight: 400,
      padding: '8px 40px',
      margin: '30px auto',
      display: 'block',
      maxWidth: 'unset',
      width: 'fit-content',
      '& span': {
        fontSize: '14px',
      },
      borderRadius: '4px',
      minWidth: '200px',
    },
    iframeContainer: {
      marginTop: '60px',
      minHeight: '700px',
      margin: '0 auto',
      width: '100%',
      [breakpoints.up('lg')]: {
        width: '75%',
      },
      '& iframe': {
        width: '100%',
        minHeight: 'inherit',
      },
    },
    email: {
      display: 'inline',
      color: palette.primary.main,
      textDecoration: 'underline',
    },
    description2: {
      fontSize: typography.fontMD,
      textAlign: 'center',
      marginTop: '20px',
      display: 'block',
    },
    hiddenFrame: {
      display: 'none',
    },
    container: {},
  }),
  { name: 'Gql_Payment2C2P' },
);

export default payment2C2PStyles;
