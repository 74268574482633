import CONSTANTS from '../constants/constants';

const locale = {
  getLocaleCode(language: string | null, country: string | null): string | null {
    if (!language || !country) {
      return null;
    }
    if (2 === language.length) {
      return `${language.toLowerCase()}-${country.toUpperCase()}`;
    }
    const languageParts = language.split('-');
    if (2 === languageParts.length) {
      return `${languageParts[0].toLowerCase()}-${languageParts[1].toUpperCase()}`;
    }

    return language;
  },

  getCurrencyLocaleCode(language: string, country: string, currency: string): string {
    // Needed to display the dollar symbol in the required format.
    // @TODO: Consider moving this to platform config in case more cases like these are found.
    if ('USD' === currency && country !== 'ar' && country !== 'pe') {
      return CONSTANTS.USD_CURRENCY_DEFAULT_LOCALE_CODE;
    }

    return locale.getLocaleCode(language, country);
  },
};

export default locale;
