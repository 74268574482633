import { Typography } from '@material-ui/core';
import React, { FunctionComponent } from 'react';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import initPaymentErrorStyles from './initPaymentErrorStyles';

const InitPaymentError: FunctionComponent = () => {
  const styles = initPaymentErrorStyles();
  return (
    <Typography data-test="checkout:payment:init:failed" variant="subtitle2">
      <Translation className={styles.translation} id="trans__checkout__payment_page__failed_init" />
    </Typography>
  );
};

export default InitPaymentError;
