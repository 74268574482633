import React from 'react';
import { IJourney, IJourneysSteps, IStep } from '../model/IJourneySteps';
import { JourneyStepsRoutes } from '../enum/JourneyStepsRoutes';
import { JourneyType } from '../../../__generated__/globalTypes';
import JourneyList from '../components/journeyList/JourneyList';
import BubbleHelpIcon from '../assets/svg/BubbleHelpIcon';
import CarTvIcon from '../assets/svg/CarTvIcon';
import CartIcon from '../assets/svg/CartIcon';
import JourneySummary from '../components/journeySummary/JourneySummary';
import CustomerInformation from '../components/customerInformation/CustomerInformation';
import CustomerInformationSummary from '../components/customerInformationSummary/CustomerInformationSummary';
import FinanceAndInsuranceSummary from '../components/financeAndInsuranceSummary/FinanceAndInsuranceSummary';
import FinanceAndInsurance from '../components/financeAndInsurance/FinanceAndInsurance';
import PaymentSummary from '../components/payment/paymentSummary/PaymentSummary';
import PaymentContainer from '../components/payment/PaymentContainer';
import IdentityVerificationContainer from '../components/identityVerification/IdentityVerificationContainer';
import FinanceSection from '../components/finance/FinanceSection';
import DealerContainer from '../components/dealer/DealerContainer';
import DealerSummary from '../components/dealer/dealerSummary/DealerSummary';
import { JourneySteps } from '../enum/JourneySteps';
import Extras from '../components/extras/components/Extras';
import ExpressionOfInterestSummary from '../components/extras/components/expressionOfInterestSummary/ExpressionOfInterestSummary';

const JourneySelectionStep: IStep = {
  labelId: 'trans__checkout__your_next_step_label',
  pathname: JourneyStepsRoutes.NEXT_STEPS,
  component: <JourneyList />,
  summaryComponent: <JourneySummary />,
};

const DealerStep: IStep = {
  labelId: 'trans__checkout__dealers_page__dealer_step_title',
  pathname: JourneyStepsRoutes.DEALER,
  component: <DealerContainer />,
  summaryComponent: <DealerSummary />,
};

const ExtrasStep: IStep = {
  labelId: 'trans__checkout__next_step__extras_step_label',
  pathname: JourneyStepsRoutes.EXTRAS,
  component: <Extras />,
  summaryComponent: <ExpressionOfInterestSummary />,
};

const FinanceInsuranceStep: IStep = {
  labelId: 'trans__checkout__next_step__order_now__finance_and_insurance_label',
  pathname: JourneyStepsRoutes.FINANCE_INSURANCE,
  component: <FinanceAndInsurance />,
  summaryComponent: <FinanceAndInsuranceSummary />,
};

const FinanceStep: IStep = {
  labelId: 'trans__checkout__next_step__finance_step_label',
  pathname: JourneyStepsRoutes.FINANCE,
  component: <FinanceSection />,
};

const PaymentStep: IStep = {
  labelId: 'trans__checkout__next_step__order_now__payment_method_label',
  pathname: JourneyStepsRoutes.PAYMENT,
  component: <PaymentContainer />,
  summaryComponent: <PaymentSummary summary={true} />,
};

const EnquiryFormStep: IStep = {
  labelId: 'trans__checkout__next_step__customer_information_label',
  pathname: JourneyStepsRoutes.YOUR_DETAILS,
  component: <CustomerInformation />,
  summaryComponent: <CustomerInformationSummary />,
};

const IdentityVerificationStep: IStep = {
  labelId: 'trans__checkout__next_step__customer_information_label',
  pathname: JourneyStepsRoutes.IDENTITY_VERIFICATION,
  component: <IdentityVerificationContainer />,
  summaryComponent: <CustomerInformationSummary />,
};

export const JourneysSteps: IJourneysSteps = {
  [JourneySteps.JourneySelection]: JourneySelectionStep,
  [JourneySteps.Dealer]: DealerStep,
  [JourneySteps.Finance]: FinanceStep,
  [JourneySteps.FinanceInsurance]: FinanceInsuranceStep,
  [JourneySteps.Extras]: ExtrasStep,
  [JourneySteps.EnquiryForm]: EnquiryFormStep,
  [JourneySteps.IdentityVerification]: IdentityVerificationStep,
  [JourneySteps.Payment]: PaymentStep,
};

export const JourneysLockedSteps = [JourneySteps.FinanceInsurance];

export const Journeys: IJourney[] = [
  {
    title: 'trans__checkout__next_step__enquire_label',
    description: 'trans__checkout__next_step__enquire_description',
    journeyType: JourneyType.continue_in_dealership,
    icon: <BubbleHelpIcon />,
  },
  {
    title: 'trans__checkout__next_step__reserve_your_car_label',
    description: 'trans__checkout__next_step__reserve_your_car_description',
    journeyType: JourneyType.reserve_online,
    icon: <CarTvIcon />,
  },
  {
    title: 'trans__checkout__next_step__order_now_label',
    description: 'trans__checkout__next_step__order_now_description',
    journeyType: JourneyType.online_order,
    icon: <CartIcon />,
  },
  {
    title: 'trans__checkout__next_step__pre_order_now_label',
    description: 'trans__checkout__next_step__pre_order_now_description',
    journeyType: JourneyType.pre_order_now,
    icon: <CartIcon />,
  },
];

export const DefaultJourneys = [
  {
    journeyType: JourneyType.continue_in_dealership,
    steps: [JourneySteps.JourneySelection, JourneySteps.EnquiryForm],
  },
  {
    journeyType: JourneyType.reserve_online,
    steps: [JourneySteps.JourneySelection, JourneySteps.EnquiryForm, JourneySteps.Payment],
  },
  {
    journeyType: JourneyType.online_order,
    steps: [
      JourneySteps.JourneySelection,
      JourneySteps.FinanceInsurance,
      JourneySteps.EnquiryForm,
      JourneySteps.Payment,
    ],
  },
];
