import { gql } from '@apollo/client/core';
import baseImage from './baseImage';

const variant = {
  VARIANT: gql`
    ${baseImage.BASE_IMAGE}
    fragment Variant on Variant {
      code
      attributes {
        name
        year
        modelName
        fullSpecsUrl
      }
    }
  `,
};
export default variant;
