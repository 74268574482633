import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type objectFieldTemplateClasses = 'root' | 'child' | 'singleField' | 'header';

const objectFieldTemplateStyles = makeStyles<ICheckoutTheme, objectFieldTemplateClasses>(
  ({ breakpoints }) => ({
    header: {
      margin: '16px auto 28px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column-reverse',
      [breakpoints.up('sm')]: {
        display: 'block',
      },
    },
    root: {
      padding: '0 10px',
      flexBasis: 'initial',
    },
    child: {
      padding: '0 15px',
      marginBottom: 25,
    },
    singleField: {
      padding: '0 25px',
      marginBottom: '20px',
    },
  }),
  { name: 'GqlCheckout_objectFieldTemplate' },
);

export default objectFieldTemplateStyles;
