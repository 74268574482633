import { useLocation } from 'react-router-dom';
import queryParams from '../../../common/service/queryParams';
import { UrlParamTypes } from '../../../common/enum/UrlParamTypes';

const useUrlDealId = () => {
  const location = useLocation();

  const token = queryParams.getParam(UrlParamTypes.TOKEN, location);

  return token;
};

export default useUrlDealId;
