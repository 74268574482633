import React from 'react';
import universal, { UniversalComponent } from 'react-universal-component';
import errorHandler from '../common/service/errorHandler';
import MinimalLoader from '../common/components/presentation/MinimalLoader/MinimalLoader';
import ThemedErrorMessage from '../common/components/presentation/Error/ThemedErrorMessage';

const DeferredPaymentPage: UniversalComponent<any> = universal<any>(
  () => import(/* webpackChunkName: "payment" */ './components/PaymentPage'),
  {
    loading: (): JSX.Element => <MinimalLoader />,
    error: (props: { error: Error }) => {
      errorHandler.handleError(props.error);
      return <ThemedErrorMessage fullPage={true} message={'Failed loading payment screen.'} error={props.error} />;
    },
  },
);

export default DeferredPaymentPage;
