import { gql } from '@apollo/client/core';

const selectExpressionsOfInterestMutation = {
  SELECT_EXPRESSIONS_OF_INTEREST: gql`
    mutation SelectExpressionsOfInterest($dealId: String!, $codes: [String!]!) {
      selectExpressionsOfInterest(dealId: $dealId, codes: $codes) {
        expressionsOfInterest {
          code
          enabled
          attributes {
            picture
            label
            description
          }
        }
      }
    }
  `,
};

export default selectExpressionsOfInterestMutation;
