import useAppContext from './useAppContext';
import { useEffect } from 'react';
import { JourneyTypes } from '../../../../common/enum/JourneyTypes';
import { useHistory } from 'react-router';
import { Routes } from '../enum/Routes';
import errorHandler from '../service/errorHandler';

export default function useDealStatusAssertion(journeyMinStatus: { [key in JourneyTypes]: number | false }): void {
  const history = useHistory();
  const { deal } = useAppContext();

  useEffect(() => {
    if (!deal) {
      return;
    }

    if (
      journeyMinStatus[deal.journeyType as JourneyTypes] === false ||
      Number.parseInt(deal.status, 10) < journeyMinStatus[deal.journeyType as JourneyTypes]
    ) {
      errorHandler.warn(
        `Expected ${journeyMinStatus[deal.journeyType as JourneyTypes]} min deal status. Got ${deal.status}.`,
      );
      history.push(Routes.ACCESS_DENIED);
    }
  }, [!!deal]);
}
