import { useEffect, useState } from 'react';
import collectedFilters from '../../../../common/service/collectedFilters';
import {
  GetDealers_dealers_defaultMapLocation,
  GetDealers_dealers_nodes,
} from '../graphql/queries/__generated__/GetDealers';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';
import { useSelectDealer } from './mutations/useSelectDealer';
import { useDealers } from './useDealers';

const useDealerSelectorDataPrep = () => {
  const [defaultDealer, setDefaultDealer] = useState<GetDealers_dealers_defaultMapLocation>({
    __typename: 'MapLocation',
    latitude: 0,
    longitude: 0,
  });
  const { deal } = useCheckoutContext();
  const { data, loading, error } = useDealers();
  const { filters } = collectedFilters();
  const initialDealerId = deal?.dealer?.externalId;
  const [selectedDealerId, setSelectedDealerId] = useState(initialDealerId);
  const [dealers, setDealers] = useState<GetDealers_dealers_nodes[]>([]);
  const currentDealer = dealers.find(({ externalId }) => externalId === selectedDealerId);
  const {
    selectDealer: selectDealerMutation,
    loading: loadingSelectDealer,
    error: selectDealerError,
  } = useSelectDealer(filters.token, `${currentDealer?.id || ''}`);
  const disabled = loadingSelectDealer || !currentDealer;

  useEffect(() => {
    setSelectedDealerId(initialDealerId);
  }, [initialDealerId]);

  useEffect(() => {
    if (!loading && !error) {
      setDealers(data.dealers.nodes);
      setDefaultDealer(data.dealers.defaultMapLocation);
    }
  }, [loading, data]);

  return {
    selectDealerMutation,
    disabled,
    defaultDealer,
    dealers,
    selectedDealerId,
    setSelectedDealerId,
    loading,
    error: error || selectDealerError,
    currentDealer,
  };
};

export default useDealerSelectorDataPrep;
