import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type CheckboxesClasses = 'root' | 'checkboxesContainer' | 'checkboxContainer' | 'errorMessage' | 'label';

const checkboxStyles = makeStyles<ICheckoutTheme, CheckboxesClasses>(
  ({ typography, palette }) => ({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      width: '40px',
      height: '40px',
    },
    checkboxContainer: {
      display: 'flex',
      alignItems: 'center',
      fontSize: typography.fontSM,
    },
    checkboxesContainer: {
      display: 'flex',
      flexDirection: 'column',
    },
    errorMessage: {
      color: palette.error.main,
      fontSize: typography.fontXS,
      flexDirection: 'column',
      position: 'absolute',
      margin: '35px 0 0 10px',
    },
    label: {},
  }),
  { name: 'GqlCheckout_Checkboxes' },
);

export default checkboxStyles;
