export const constants = {
  PRICE_FORMAT: '%[,###.##]',
  DEAL: 'deal',
  DEALER_ID: 'dealerId',
  TOKEN: 'token',
  NEW: 'new',
  DEALER: 'dealer',
  EXTRAS: 'extras',
  REQUEST_QUOTE: 'request-quote',
  POSTCODE: 'postcode',
  NO: 'no',
  FROM: 'from',
  OPEN_FC: 'openFc'
};
