import { createContext } from 'react';
import { IUserInformation } from '../../../../common/model/IUser';
import { TransactionErrorTypes } from '../enum/TransactionErrorTypes';
import { IDeal } from '../../../../common/model/IDeal';
import { ICarConfiguration } from '../../../../common/model/ICarConfiguration';

export interface IAppContext {
  // @TODO: Remove after Summary refactoring
  deal?: IDeal | any;
  dealError?: any;
  user: IUserInformation;
  carConfig: ICarConfiguration;
  transactionError: TransactionErrorTypes;
  setTransactionError: (error: TransactionErrorTypes) => void;
  setDeal: (deal: IDeal) => void;
  setUser: (user: IUserInformation) => void;
  setCarConfiguration: (carConfig: ICarConfiguration) => void;
}
const AppContext = createContext<IAppContext>({
  deal: null,
  user: null,
  carConfig: null,
  transactionError: null,
  setTransactionError: () => null,
  setDeal: () => null,
  setUser: () => null,
  setCarConfiguration: () => null,
});

export default AppContext;
