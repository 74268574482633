import { GetDealers_dealers_nodes } from '../graphql/queries/__generated__/GetDealers';
import useCurrentLocationPosition from './useCurrentLocationPosition';
import { distance } from '../service/distance';

const useSortDealers = (dealers: GetDealers_dealers_nodes[]) => {
  const { currentPosition } = useCurrentLocationPosition();

  return [...dealers].sort((firstDealer, secondDealer) =>
    currentPosition
      ? distance.calculateDistance(currentPosition, firstDealer) -
        distance.calculateDistance(currentPosition, secondDealer)
      : (firstDealer.label || '').localeCompare(secondDealer.label),
  );
};

export default useSortDealers;
