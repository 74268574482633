import { gql } from '@apollo/client/core';

const freebie = {
  FREEBIES: gql`
    fragment Freebies on Freebies {
      code
      label
    }
  `,
};

export default freebie;
