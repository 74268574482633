export const customEvent = {
  emit(eventType: string, data: any): void {
    const event = new CustomEvent(eventType, { detail: data });
    document.dispatchEvent(event);
  },
  listen(evenType: string, listener: EventListener): void {
    document.addEventListener(evenType, listener);
  },
  removeListener(eventType: string, listener: EventListener): void {
    document.removeEventListener(eventType, listener);
  },
};
