import { gql } from '@apollo/client/core';

const stockApiPing = {
  GET_STOCKAPIPING: gql`
    query GetStockPingApi {
      stockApiPing
    }
  `,
};

export default stockApiPing;
