export enum MyAccountRoutes {
  INDEX = '/',
  MYACCOUNT_BASE = '/my-account',
  MYACCOUNT_ORDERS = '/my-account/orders',
  MYACCOUNT_RESERVATIONS = '/my-account/reservations',
  MYACCOUNT_ENQUIRY = '/my-account/enquiry',
  MYACCOUNT_PERSONAL_DETAILS = '/my-account/personal-details',
  MYACCOUNT_DELIVERY_ADDRESS = '/my-account/delivery-address',
  MYACCOUNT_DRIVERS_LICENSE_DETAILS = '/my-account/drivers-license-details',
  MYACCOUNT_CHANGE_PASSWORD = '/my-account/change-password',
  MYACCOUNT_REGISTRATION_CONFIRMATION = '/registration-confirmation',
  MYACCOUNT_FINANCE = '/my-account/finance/*',
  MYACCOUNT_FINANCE_REDIRECTION = '/my-account/finance',
  MYACCOUNT_FORGOT_PASSWORD = '/my-account/forgot-password',
  MYACCOUNT_RESET_PASSWORD = '/my-account/reset-password',
  MYACCOUNT_INSURANCE = '/my-account/insurance',
  MYACCOUNT_LOGIN = '/my-account/login',
  MYACCOUNT_SET_PASSWORD = '/my-account/set-password',
  MYACCOUNT_SAVED_CARS = '/my-account/saved-cars',
}
