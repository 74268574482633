import React, { FC, ReactElement } from 'react';
import { Grid } from '@material-ui/core';
import baseDealerLayoutStyles from './baseDealerLayoutStyles';

export interface IBaseConfiguratorLayoutProps {
  mainContentSlot: ReactElement;
  disclaimerSlot?: ReactElement;
  pageTitleSlot: ReactElement;
  pageInfoSlot?: ReactElement;
  classes?: string;
}

const BaseDealerLayout: FC<IBaseConfiguratorLayoutProps> = ({
  mainContentSlot,
  disclaimerSlot,
  pageTitleSlot,
  pageInfoSlot,
}) => {
  const styles = baseDealerLayoutStyles();

  return (
    <>
      <Grid container={true} classes={{ container: styles.layoutContainer }}>
        <Grid item={true} xs={12}>
          {pageInfoSlot}
        </Grid>
        <Grid item={true} xs={12}>
          {pageTitleSlot}
        </Grid>
        <Grid item={true} xs={12}>
          {mainContentSlot}
        </Grid>
      </Grid>
      {disclaimerSlot}
    </>
  );
};

export default BaseDealerLayout;
