import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';

export type JourneySummaryClasses = 'summaryTitle' | 'summaryDescription';

const journeySummaryStyles = makeStyles<ICheckoutTheme, JourneySummaryClasses>(
  theme => ({
    summaryTitle: {
      color: theme.palette.text.primary,
    },
    summaryDescription: {
      color: theme.palette.text.primary,
    },
  }),
  { name: 'Checkout4_JourneySummary' },
);

export default journeySummaryStyles;
