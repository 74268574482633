import generateApolloClient from '../graphql/generateApolloClient';
import useWidgetContext from '../../../common/react/useWidgetContext';

const useApolloClient = () => {
  const { configuration } = useWidgetContext();
  const client = generateApolloClient(configuration.dimensions);

  return { client };
};

export default useApolloClient;
