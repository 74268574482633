import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type DropdownSelectClasses =
  | 'fieldWrapper'
  | 'root'
  | 'select'
  | 'errorText'
  | 'outlined'
  | 'selectError'
  | 'label'
  | 'disabled'
  | 'item'
  | 'itemDisabled'
  | 'hideSalutation';

const dropdownSelectStyles = makeStyles<ICheckoutTheme, DropdownSelectClasses>(
  ({ palette, typography, spacing, transitions, breakpoints }) => ({
    fieldWrapper: {},
    root: {
      background: palette.common.white,
    },
    select: {
      width: '100%',
      height: 'fit-content',
      '&:focus': {
        background: palette.common.white,
      },
    },
    errorText: {
      margin: spacing(1),
      fontSize: typography.fontXS,
      color: palette.error.main,
    },
    outlined: {
      borderRadius: '3px',
      borderWidth: 1,

      '& $notchedOutline': {
        borderColor: palette.text.secondary,
      },
    },
    selectError: {
      borderColor: palette.error.main,
    },
    label: {
      fontFamily: typography.fontFamily2,
      color: palette.text.primary,
      marginBottom: 6,
      lineHeight: '20px',
    },
    disabled: {
      background: palette.background.alternate,
    },
    item: {
      transition: transitions.create(['background', 'color']),
      color: palette.text.primary,
      fontSize: typography.fontMD,
      backgroundColor: palette.common.white,

      '&:hover': {
        backgroundColor: palette.primary.main,

        '&:not([aria-selected])': {
          color: palette.common.white,

          [breakpoints.down(breakpoints.values.sm)]: {
            backgroundColor: palette.common.white,
            color: palette.text.primary,
          },
        },
      },
    },
    itemDisabled: {
      borderColor: palette.text.secondary,
      color: palette.primaryDisabled.main,

      '&.Mui-disabled': {
        opacity: 1,
        backgroundColor: palette.common.white,
      },
    },
    hideSalutation: {},
  }),
  { name: 'GqlCheckout_DropdownSelect' },
);

export default dropdownSelectStyles;
