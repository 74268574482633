import { useEffect } from 'react';
import { assetManager } from '../service/assetManager';
import CONSTANTS from '../constants/constants';

const useRecaptcha = () => {
  useEffect(() => {
    assetManager.removeStyle(CONSTANTS.RECAPTCHA_CSS_URL, true);
    assetManager.loadScript(CONSTANTS.RECAPTCHA_SITE_URL);

    return () => {
      assetManager.removeScript(CONSTANTS.RECAPTCHA_SITE_URL, true);
      assetManager.loadStyle(CONSTANTS.RECAPTCHA_CSS_URL);
    };
  }, []);
};

export default useRecaptcha;
