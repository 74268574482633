import { Box } from '@material-ui/core';
import React, { ElementType, FunctionComponent } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { FormatXMLElementFn } from 'intl-messageformat';
import translationStyles from './translationStyles';
import clsx from 'clsx';

interface ITranslationProps extends MessageDescriptor {
  component?: ElementType<string>;
  values?: Record<string, string | number | boolean | null | undefined | Date | JSX.Element | FormatXMLElementFn>;
  className?: string;
}

const Translation: FunctionComponent<ITranslationProps> = props => {
  const intl = useIntl();
  const styles = translationStyles();

  return (
    <Box
      className={clsx(props.className, styles.container)}
      component={props.component ?? 'span'}
      data-translationkey={props.id}
    >
      {(intl.formatMessage({ id: props.id }) !== props.id || props.defaultMessage) && (
        <FormattedMessage {...(props as MessageDescriptor)} />
      )}
    </Box>
  );
};

export default Translation;
