import ReactDOM from 'react-dom';
import { WidgetEvents } from '../event/WidgetEvents';
import IWidgetConfiguration from '../../../../common/model/IWidgetConfiguration';
import EventContainer from '../../../../common/event/EventContainer';

const widgetFacadeFactory = {
  create(config: IWidgetConfiguration, eventContainer: EventContainer): any {
    return {
      destroy: (): void => {
        eventContainer.emit(WidgetEvents.DESTROY);

        ReactDOM.unmountComponentAtNode(document.getElementById(config.containerId) as Element);

        eventContainer.removeAllListeners();
      },

      on: (event: string, callback: () => void): void => {
        eventContainer.on(event, callback);
      },

      onEvery: (callback: (event: string) => void): void => {
        eventContainer.onEvery(callback);
      },
    };
  },
};

export default widgetFacadeFactory;
