import React from 'react';
import { IntlProvider } from 'react-intl';
import useWidgetContext from '../react/useWidgetContext';
import IInitializerComponent from './IInitializerComponent';
import { translations as getTranslations } from '@inchcape/automotive-core-platform-sdk-package/dist/repository/translations';
import IWidgetConfiguration from '../../../../common/model/IWidgetConfiguration';

const TranslationProvider: IInitializerComponent<
  { messages: Record<string, string> },
  { configuration: IWidgetConfiguration }
> = {
  hooks: () => {
    return useWidgetContext();
  },

  initialize: args => {
    const translationsConfig = {
      withCredentials: !!+process.env.REACT_APP_WITH_CREDENTIALS,
      multiDimensions: args.configuration.dimensions,
      baseURL: process.env.PLATFORM_API_BASE_URL,
      apiBasePath: process.env.PLATFORM_API_URL.replace(process.env.PLATFORM_API_BASE_URL, ''),
      cmsApiPath: '/cms/api',
    };
    return Promise.all([
      getTranslations('common', translationsConfig),
      getTranslations('checkout', translationsConfig),
      getTranslations('my-summary', translationsConfig),
    ]).then(([commonTranslations, checkoutTranslations, mySummaryTranslations]) => {
      return {
        messages: {
          ...commonTranslations,
          ...checkoutTranslations,
          ...mySummaryTranslations,
        },
      };
    });
  },

  component: ({ messages, children }) => {
    const { configuration } = useWidgetContext();

    return (
      <IntlProvider locale={configuration.dimensions.language} messages={messages}>
        {children}
      </IntlProvider>
    );
  },
};

export default TranslationProvider;
