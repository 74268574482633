import React, { FunctionComponent } from 'react';
import { Box, Typography } from '@material-ui/core';
import { Journeys } from '../../constants/JourneySteps';
import { activeJourneyType } from '../../../common/graphql/cache';
import Translation from '../../../../common/components/presentation/Translation/Translation';
import journeySummaryStyles from './journeySummaryStyles';

const JourneySummary: FunctionComponent = () => {
  const styles = journeySummaryStyles();
  const userJourney = activeJourneyType();
  const summaryDetails = Journeys.find(x => x.journeyType === userJourney);

  if (!summaryDetails) {
    return null;
  }

  return (
    <Box data-test={'checkout:journey_list:summary'}>
      <Typography variant="h5" className={styles.summaryTitle}>
        <Translation id={summaryDetails.title} />
      </Typography>
      <Typography className={styles.summaryDescription}>
        <Translation id={summaryDetails?.description} />
      </Typography>
    </Box>
  );
};

export default JourneySummary;
