import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type TextareaAutosizeInputClasses = 'root';

const textareaAutosizeInputStyles = makeStyles<ICheckoutTheme, TextareaAutosizeInputClasses>(
  ({ palette, typography }) => ({
    root: {
      background: palette.common.white,
      width: '100%',
      padding: '8px',
      outline: 'none',
      resize: 'none',
      borderColor: palette.primary.light,

      '&:hover': {
        borderColor: palette.primary.main,
      },

      '&:focus': {
        borderColor: palette.primary.main,
      },

      '&::placeholder': {
        fontSize: typography.caption.fontSize,
      },
    },
  }),
  { name: 'GqlCheckout_TextareaAutosizeInput' },
);

export default textareaAutosizeInputStyles;
