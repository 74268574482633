export enum Routes {
  INDEX = '/',

  ACCESS_DENIED = '/access-denied',
  DEAL_UNAVAILABLE = '/deal-unavailable',

  RESERVE_ONLINE = '/reserve-online',
  EXTRAS = '/extras',
  DEALER = '/dealer',
  REQUEST_QUOTE = '/request-quote',
  PAYMENT = '/reserve-online/payment',
  FINANCE = '/finance',
  CONFIRMATION_REQUEST_QUOTE = '/request-quote/confirmation',
  ENQUIRY_REQUEST_QUOTE = '/request-quote/enquiry',
  CONFIRMATION_RESERVE_ONLINE = '/reserve-online/confirmation',
  NEXT_STEPS = '/next-steps',
  MYACCOUNT_LOGIN = '/my-account/login',
  MYACCOUNT_SET_PASSWORD = '/my-account/set-password',
  MYACCOUNT_ORDERS = '/my-account/orders',
  MYACCOUNT_RESERVATIONS = '/my-account/reservations',
  MYACCOUNT_ENQUIRY = '/my-account/enquiry',
  MYACCOUNT_SAVED_CARS = '/my-account/saved-cars',
  MYACCOUNT_PERSONAL_DETAILS = '/my-account/personal-details',
  MYACCOUNT_FINANCE = '/my-account/finance',
  MYACCOUNT_INSURANCE = '/my-account/insurance',
}
