import React, { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router';
import { Box, Grid, Typography } from '@material-ui/core';
import queryParams from '../../../../../common/service/queryParams';
import { UrlParamTypes } from '../../../../../common/enum/UrlParamTypes';
import collectedFilters from '../../../../../common/service/collectedFilters';
import useCheckoutContext from '../../../../common/hooks/useCheckoutContext';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import Item from './item/Item';
import expressionsOfInterestStyles from './expressionsOfInterestStyles';

export interface ExpressionsOfInterestProps {
  dealExpressionsOfInterest: string[];
}

const ExpressionsOfInterest: FunctionComponent<ExpressionsOfInterestProps> = ({ dealExpressionsOfInterest }) => {
  const history = useHistory();
  const {
    filters: { expressionsOfInterest: eoi },
  } = collectedFilters();
  const { expressionOfInterest } = useCheckoutContext();
  const eoiFilters = eoi?.split(',');
  const selectedItems = useMemo(() => eoiFilters || [], [eoiFilters]);
  const styles = expressionsOfInterestStyles();

  const handleSelectedItem = useCallback(
    (code: string) => {
      const selectedCodes =
        selectedItems && selectedItems?.includes(code)
          ? selectedItems.filter(item => item !== code)
          : [...selectedItems, code];

      queryParams.changeArrayValue(history.location, history, UrlParamTypes.EXPRESSIONS_OF_INTEREST, selectedCodes);
    },
    [selectedItems],
  );

  useEffect(() => {
    if (selectedItems?.length === 1 && selectedItems[0] === '') {
      queryParams.removeParam(history.location, history, UrlParamTypes.EXPRESSIONS_OF_INTEREST);
    }
  }, [selectedItems]);

  useEffect(() => {
    if (!selectedItems.length && dealExpressionsOfInterest.length) {
      queryParams.changeArrayValue(
        history.location,
        history,
        UrlParamTypes.EXPRESSIONS_OF_INTEREST,
        dealExpressionsOfInterest,
      );
    }
  }, [dealExpressionsOfInterest]);

  if (!expressionOfInterest?.length) {
    return null;
  }

  return (
    <Box className={styles.container} data-test={'checkout:extras:expression_of_interest:container'}>
      <Typography variant={'h4'} data-test={'checkout:extras:expression_of_interest:title'}>
        <Translation id={'trans__checkout__extras_page__eoi_title'} className={styles.title} />
      </Typography>
      <Typography data-test={'checkout:extras:expression_of_interest:description'}>
        <Translation id={'trans__checkout__extras_page__eoi_description'} className={styles.description} />
      </Typography>
      <Grid
        container
        spacing={1}
        className={styles.itemsWrapper}
        data-test={'checkout:extras:expression_of_interest:items'}
      >
        {expressionOfInterest?.length &&
          expressionOfInterest.map(item => (
            <Item
              item={item}
              isSelected={selectedItems?.includes(item.code)}
              handleSelectedItem={handleSelectedItem}
              key={item.code}
            />
          ))}
      </Grid>
    </Box>
  );
};

export default ExpressionsOfInterest;
