import { forwardRef, useCallback, useEffect, useMemo, useRef } from 'react';
import React from 'react';
import useWidgetContext from '../../react/useWidgetContext';
import { SINGPASS_CONFIG } from '../../constants/singpass';
import { assetManager } from '../../../../../common/service/assetManager';
import CONSTANTS from '../../constants/constants';
import errorHandler from '../../service/errorHandler';
import useUrlDealId from '../../../gql/common/hooks/useUrlDealId';

interface SingpassDxpProps {
  setLoadingStatus: (status: boolean) => void;
}
const SingpassDxp = forwardRef<HTMLDivElement, SingpassDxpProps>(({ setLoadingStatus }, ref) => {
  const widgetId = useMemo(() => 'dxp-singpass-container' + Math.floor(Math.random() * 100), []);
  const widgetInstance: any = useRef(null);

  const singpassLibrary: any = useRef(null);
  const { configuration } = useWidgetContext();
  const dealId = useUrlDealId();

  const ensureLibraryLoaded = () => {
    const widgetUrl: string = SINGPASS_CONFIG.dxpWidgetUrl;

    if (assetManager.isScriptLoaded(widgetUrl)) {
      if (!singpassLibrary.current && (window as any).DXPSingpass) {
        singpassLibrary.current = (window as any).DXPSingpass;
      }

      return Promise.resolve();
    }

    return Promise.resolve()
      .then(() => {
        // keep in mind there's a slight difference between the react versions of dxp and auto-widgets
        if (!assetManager.isScriptLoaded(CONSTANTS.REACT_DEPENDENCIES.react18)) {
          return assetManager
            .loadScript(CONSTANTS.REACT_DEPENDENCIES.react18)
            .then(() => assetManager.loadScript(CONSTANTS.REACT_DEPENDENCIES.reactDom18));
        }
      })
      .then(() => assetManager.loadScript(widgetUrl, 'module'))
      .then(() => {
        singpassLibrary.current = (window as any).DXPSingpass;
      });
  };

  const getLibrary = useCallback(() => {
    if (!singpassLibrary.current) {
      throw new Error(`Attempted to use DXP Singpass library without loading it first`);
    }

    return singpassLibrary.current;
  }, [singpassLibrary.current]);

  const initializeWidget = () => {
    if (widgetInstance.current) {
      widgetInstance.current.destroy();
    }

    setLoadingStatus(true);

    const widgetConfig: any = {
      containerId: widgetId,
      cookieDomain: process.env.REACT_APP_SESSION_COOKIE_DOMAIN,
      dealId: dealId,
    };

    if (configuration.cookieDomain) {
      widgetConfig.cookieDomain = configuration.cookieDomain;
    }

    const dxpWidget = getLibrary();
    dxpWidget.createWidget(widgetConfig);
  };

  useEffect(() => {
    if (!widgetInstance.current) {
      ensureLibraryLoaded()
        .then(() => initializeWidget())
        .catch(errorHandler.promise())
        .finally(() => setLoadingStatus(false));
    }

    return () => {
      if (widgetInstance.current) {
        widgetInstance.current.destroy();
      }
    };
  }, []);

  return <div ref={ref} id={widgetId} data-test={'checkout:singpass:root'} />;
});

export default SingpassDxp;
