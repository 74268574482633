import { Grid, Typography } from '@material-ui/core';
import React, { FunctionComponent, useCallback } from 'react';
import { useHistory } from 'react-router';
import navigationStyles from './navigationStyles';
import { Routes } from '../../../enum/Routes';
import useAppContext from '../../../react/useAppContext';
import Translation from '../Translation/Translation';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import clsx from 'clsx';
import { Variant } from '@material-ui/core/styles/createTypography';

export interface INavigationProps {
  withIcon?: boolean;
  backTextClassName?: string;
  backTextVariant?: Variant;
}

const Navigation: FunctionComponent<INavigationProps> = ({
  withIcon = true,
  backTextClassName = '',
  backTextVariant = 'body1',
}) => {
  const style = navigationStyles();
  const history = useHistory();
  const { deal } = useAppContext();

  const navigateBack = useCallback(() => {
    const pathName = window.location.pathname.split('/');

    switch (pathName[pathName.length - 1]) {
      case Routes.PAYMENT.substr(1):
        return history.replace(Routes.RESERVE_ONLINE);
      case Routes.RESERVE_ONLINE.substr(1):
        return (window.location.href = deal?.fullProductConfiguration?.configurationSourceUrl);
      default:
        return history.goBack();
    }
  }, [history]);

  return (
    <Grid className={style.innerBackButtonContainer} item>
      {!!withIcon && <ArrowBackIosIcon className={style.icon} />}
      <Typography
        onClick={navigateBack}
        component="span"
        variant={backTextVariant}
        data-test="text:navigation:backButton"
        className={clsx([style.label, backTextClassName])}
      >
        <Translation id="label.back" />
      </Typography>
    </Grid>
  );
};

export default Navigation;
