import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type ExpressionsOfInterestItemClasses = 'itemWrapper' | 'item' | 'itemSelected';

const itemStyles = makeStyles<ICheckoutTheme, ExpressionsOfInterestItemClasses>(
  ({ palette, breakpoints }) => ({
    itemWrapper: {
      marginBottom: '40px',
    },
    item: {
      border: `1px solid ${palette.grey['300']}`,
      display: 'flex',
      height: '100%',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [breakpoints.down('sm')]: {
        height: 'fit-content',
      },
    },
    itemSelected: {
      borderColor: palette.primary.main,
    },
  }),
  { name: 'GqlCheckout_ExpressionsOfInterest_Item' },
);

export default itemStyles;
