import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type ExpressionsOfInterestItemDetailsClasses = 'image' | 'details' | 'title' | 'description';

export const imageStyle = {
  width: '100%',
  height: 'unset',
  position: 'relative',
  top: 'unset',
  left: 'unset',
};

const itemDetailsStyles = makeStyles<ICheckoutTheme, ExpressionsOfInterestItemDetailsClasses>(
  ({ typography }) => ({
    image: {
      display: 'block',
      marginBottom: '15px',
      maxWidth: '100%',
    },
    details: {
      padding: '0 15px',
      textAlign: 'left',
    },
    title: {
      fontWeight: 700,
      fontSize: typography.fontMD,
      marginBottom: '5px',
    },
    description: {
      lineHeight: '18px',
    },
  }),
  { name: 'GqlCheckout_ExpressionsOfInterest_ItemDetails' },
);

export default itemDetailsStyles;
