import storageService from '../../../../common/service/storageService';
import IDimensions from '../model/IDimensions';
import { IPriceDisplay } from '../../../../common/model/IPriceDisplay';

function buildId(dimension: IDimensions): string {
  return `${dimension.country}_${dimension.brand}_dxp_priceDisplay`;
}

export const dxpPriceDisplay = {
  get: (dimension: IDimensions) => {
    return storageService.get(buildId(dimension));
  },
  set: (dimension: IDimensions, value: IPriceDisplay) => {
    return storageService.set(buildId(dimension), JSON.stringify(value));
  },
};
