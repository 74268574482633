import React, { FunctionComponent, useState, useEffect, useRef } from 'react';
import { Grid, Box, Typography } from '@material-ui/core';
import SingpassDxp from '../../../../../common/components/singpassDxp/SingpassDxp';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import TermsAndConditions from '../termsAndConditions/TermsAndConditions';
import { SingpassEvents } from './enum/SingpassEvents';
import { SingpassEvent, SingpassEventData } from './model/SingpassEvent';
import { customEvent } from '../../../../../common/service/customEvent';
import singpassContainerStyles from './singpassContainerStyles';
import { SingpassAccess } from './enum/SingpassAccess';

type SingpassContainerProps = {
  isAccepted: boolean;
  verificationData?: SingpassEventData;
  setIsAccepted: (accepted: boolean) => void;
  setVerificationData: (data?: SingpassEventData) => void;
};

const SingpassContainer: FunctionComponent<SingpassContainerProps> = ({
  setVerificationData,
  setIsAccepted,
  isAccepted,
  verificationData,
}) => {
  const singpassRef = useRef<HTMLDivElement | null>(null);
  const [, setIsLoading] = useState<boolean>(false);

  const styles = singpassContainerStyles();

  useEffect(() => {
    const eventListener = (event: Event) => {
      const { detail } = event as SingpassEvent;
      setVerificationData(detail);
    };

    customEvent.listen(SingpassEvents.SINGPASS_AUTHORIZATION, eventListener);

    return () => {
      customEvent.removeListener(SingpassEvents.SINGPASS_AUTHORIZATION, eventListener);
    };
  }, [setVerificationData]);

  return (
    <Box className={styles.root} data-test="checkout:identityVerification:container">
      <Grid container className={styles.titleContainer}>
        <Grid item xs={12}>
          {verificationData?.progress !== SingpassAccess.Granted && (
            <Typography variant={'body1'} className={styles.description} data-test="checkout:singpass:label">
              <Translation id={'trans__checkout__next_step__customer_information__singpass_login_label'} />
            </Typography>
          )}
        </Grid>
      </Grid>
      <Box className={styles.disclaimerContainer} data-test="checkout:disclaimer:container">
        <TermsAndConditions isAccepted={isAccepted} setIsAccepted={setIsAccepted} />
      </Box>
      {verificationData?.progress !== SingpassAccess.Granted && (
        <Box className={styles.singpassContainer} data-test="checkout:singpass:container">
          <SingpassDxp ref={singpassRef} setLoadingStatus={setIsLoading} />
        </Box>
      )}
    </Box>
  );
};

export default SingpassContainer;
