import { IAsyncValidationError } from '../model/IJsonSchema';

const enquiryFormErrorTransformer = {
  from(errors: any, intl: any): IAsyncValidationError {
    const validationErrors = {};

    for (const path in errors) {
      let errorMessage = errors[path] ?? '';
      const pathArray = path.includes('.') ? path.split('.') : [path];
      const lastNestedField = pathArray[pathArray.length - 1];

      if (intl.messages?.[errorMessage]) {
        errorMessage = intl.formatMessage({ id: errorMessage });
      }
      if (!path || !errorMessage) {
        throw new Error('The validation error is not formatted correctly!');
      }

      pathArray.reduce(
        (field: any, nestedField: any) =>
          (field[nestedField] = nestedField === lastNestedField ? { __errors: [errorMessage] } : {}),
        validationErrors,
      );
    }

    return validationErrors;
  },
};

export default enquiryFormErrorTransformer;
