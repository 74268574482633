import { gql } from '@apollo/client/core';

const paymentInfo = {
  PAYMENT_INFO: gql`
    fragment PaymentInfo on PaymentInfo {
      errorCode
      errorMessage
      actionCode
      actionDescription
      transactionId
      transactionTime
      card
      brand
    }
  `,
};
export default paymentInfo;
