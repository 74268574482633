import React, { FunctionComponent, useMemo } from 'react';
import { Button } from '@material-ui/core';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import useCheckoutContext from '../../../../common/hooks/useCheckoutContext';
import updateOrderButtonStyles from './updateOrderButtonStyles';
import { useCancelVehicleReservation } from '../../../hooks/mutations/useCancelVehicleReservation';

const UpdateOrderButton: FunctionComponent = () => {
  const styles = updateOrderButtonStyles();
  const { deal } = useCheckoutContext();
  const { removeSoftLock, loading: loadingRemoveSoftLock } = useCancelVehicleReservation();
  const configurationSourceUrl = useMemo(() => deal?.car?.configurationSourceUrl, [deal]);

  const onClickUpdateOrder = async () => {
    await removeSoftLock();
    window.location.href = configurationSourceUrl;
  };

  return (
    <Button
      onClick={onClickUpdateOrder}
      className={styles.updateOrderButton}
      color="secondary"
      type="button"
      variant="outlined"
      data-test={'checkout:step:update_order_button'}
      disabled={loadingRemoveSoftLock}
    >
      <Translation id="trans__checkout__next_step__finance_and_insurance_update_my_order_CTA" />
    </Button>
  );
};

export default UpdateOrderButton;
