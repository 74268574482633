import React from 'react';
import universal, { UniversalComponent } from 'react-universal-component';
import errorHandler from '../../common/service/errorHandler';
import MinimalLoader from '../../../common/components/presentation/MinimalLoader/MinimalLoader';
import ThemedErrorMessage from '../../../common/components/presentation/Error/ThemedErrorMessage';

const GqlDeferredContinueInDealership: UniversalComponent<any> = universal<any>(
  () => import(/* webpackChunkName: "enquiry-form" */ './components/EnquiryScreen'),
  {
    loading: (): JSX.Element => <MinimalLoader />,
    error: (props: { error: Error }) => {
      errorHandler.handleError(props.error);
      return (
        <ThemedErrorMessage
          fullPage={true}
          message={'Failed loading continue in dealership screen.'}
          error={props.error}
        />
      );
    },
  },
);

export default GqlDeferredContinueInDealership;
