import { gql } from '@apollo/client/core';
import dealData from '../../../common/graphql/fragments/deal/dealData';

const selectJourneyTypeMutation = {
  SELECT_JOURNEY_TYPE: gql`
    ${dealData.DEAL_DATA}
    mutation SelectJourneyType($dealId: String!, $journeyType: JourneyType!) {
      selectJourneyType(dealId: $dealId, journeyType: $journeyType) {
        ...DealData
      }
    }
  `,
};

export default selectJourneyTypeMutation;
