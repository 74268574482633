import { gql } from '@apollo/client';

const bodyStyle = {
  BODY_STYLE: gql`
    fragment BodyStyle on BodyStyle {
      code
      attributes {
        shortname
        longdescription
      }
    }
  `,
};

export default bodyStyle;
