import { useCallback } from 'react';
import useJourneyListContext from '../context/journeys/useJourneyListContext';
import { JourneyType } from '../../../__generated__/globalTypes';

const useHasJourney = () => {
  const { journeyList } = useJourneyListContext();

  const hasJourney = useCallback(
    (journeyType: JourneyType) => {
      return journeyList.some(journey => journey.journeyType === journeyType);
    },
    [journeyList],
  );

  return { hasJourney };
};

export default useHasJourney;
