import { ICheckoutTheme } from '../../../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type TooltipClasses = 'tooltipContainer' | 'arrow';

const tooltipStyles = makeStyles<ICheckoutTheme, TooltipClasses>(
  theme => ({
    tooltipContainer: {
      minWidth: '50px',
      transform: 'translate(-50%,-195%)',
      padding: '10px 20px',
      backgroundColor: theme.palette.common.white,
      borderRadius: '8px',
      position: 'absolute',
      width: 'max-content',
      whiteSpace: 'nowrap',
      boxShadow: '0 1px 8px rgba(0,0,0,0.5)',
      zIndex: 1,
      '& svg': {
        position: 'absolute',
        right: 0,
        top: 0,
        height: '15px',
        cursor: 'pointer',
      },
    },
    arrow: {
      position: 'absolute',
      top: '100%',
      left: '50%',
      marginLeft: '-12px',
      width: '24px',
      height: '12px',
      overflow: 'hidden',
      '&::after': {
        content: '""',
        position: 'absolute',
        width: '12px',
        height: '12px',
        left: '50%',
        transform: 'translate(-50%, -50%) rotate(45deg)',
        backgroundColor: theme.palette.common.white,
        boxShadow: '0 1px 8px rgba(0, 0, 0, 0.5)',
      },
    },
  }),
  { name: 'GqlCheckout_DealerMapTooltip' },
);

export default tooltipStyles;
