import axios from 'axios';
import { isApolloError, ApolloError } from '@apollo/client';
import logger from './logger';
import RequestCancelledError from '../error/RequestCancelledError';

const errorHandler = {
  /**
   * @param error
   * @return boolean
   *   Whether the error was handled.
   */
  handleError(error: any): boolean {
    if (axios.isCancel(error) || error.name === RequestCancelledError.code) {
      logger.warn(error.message);
      return true;
    }

    if (isApolloError(error)) {
      if (error.graphQLErrors) {
        error.graphQLErrors.forEach(({ message, path }) =>
          /* tslint:disable:no-console */
          console.error(`[GraphQL error]: Message: ${message}, Path: ${path}`),
        );
      }

      if (error.networkError) {
        /* tslint:disable:no-console */
        console.error(`[Network error]: ${error.networkError}`);
      }

      return false;
    }

    /* tslint:disable:no-console */
    console.error(error);
    return false;
  },

  handleSoftLock(error: ApolloError) {
    if (isApolloError(error) && error.graphQLErrors) {
      return {
        isStockLocked: error.graphQLErrors[0]?.extensions?.stock_lock,
        translation: error.graphQLErrors[0]?.message,
        path: error.graphQLErrors[0]?.path,
      };
    }
  },

  promise(): (error: any) => void {
    return error => errorHandler.handleError(error);
  },
};

export default errorHandler;
