import { makeStyles } from '@material-ui/core/styles';

export type BleedContainerStylesClasses = 'root';

const bleedContainerStyles = makeStyles(
  theme => ({
    root: {
      [theme.breakpoints.down('sm')]: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
  }),
  { name: 'GqlCheckout_BleedContainer' },
);

export default bleedContainerStyles;
