import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import clsx from 'clsx';
import { Box } from '@material-ui/core';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';
import Translation from '../../../../common/components/presentation/Translation/Translation';
import financeAndInsuranceSummaryStyles from './financeAndInsuranceStyles';
import { FinanceFormType } from '../../../../__generated__/globalTypes';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';

interface IFinanceAndInsuranceSummaryProps {
  className?: string;
}

const FinanceAndInsuranceSummary: FunctionComponent<IFinanceAndInsuranceSummaryProps> = ({ className }) => {
  const intl = useIntl();
  const styles = financeAndInsuranceSummaryStyles();
  const { deal } = useCheckoutContext();
  const {
    platformConfig: {
      platformFeatures: { insuranceIntegration },
    },
  } = usePlatformConfig();
  const hasFinance = !!(deal?.finance?.type === FinanceFormType.finance);
  const hasInsurance = !!deal?.insurance;

  const labelId = () => {
    if (!hasFinance) {
      if (!hasInsurance) {
        return 'trans__checkout__next_step__order_now__finance_and_insurance_label';
      }

      return 'trans__checkout__next_step__finance_and_insurance__finance_label';
    }

    return 'trans__checkout__next_step__finance_and_insurance_insurance_label';
  };
  const sectionLabel = intl.formatMessage({ id: labelId() });

  if (hasFinance && ((insuranceIntegration && hasInsurance) || !insuranceIntegration)) {
    return null;
  }

  return (
    <Box data-test={'checkout:finance_insurance:summary'}>
      <Translation
        id="trans__checkout__next_step__finance_and_insurance_label1"
        className={clsx(className, styles.summaryLabel)}
        values={{ sectionLabel }}
      />
    </Box>
  );
};

export default FinanceAndInsuranceSummary;
