import React, { ChangeEvent, FunctionComponent, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';

type CustomCheckboxProps = {
  label: string;
  value?: boolean;
  onChange?: (newValue: boolean) => void;
  onClick?: () => void;
  'data-test'?: string;
};

const CustomCheckbox: FunctionComponent<CustomCheckboxProps> = ({
  label,
  value,
  onChange,
  onClick,
  'data-test': dataTest,
}) => {
  const intl = useIntl();

  const handleChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
    onChange && onChange(event.target.checked);
  }, []);

  return (
    <FormControlLabel
      data-test={dataTest}
      label={
        <Typography
          dangerouslySetInnerHTML={{
            __html: intl.formatMessage({ id: label }),
          }}
        />
      }
      control={<Checkbox value={value} onClick={onClick} onChange={handleChange} />}
    />
  );
};

export default CustomCheckbox;
