import React, { FunctionComponent, useCallback, useMemo, useState } from 'react';
import PaymentDisclaimer from './paymentDisclaimer/PaymentDisclaimer';
import PaymentPage from './PaymentPage';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';
import { DealStatus } from '../../enum/DealStatus';

const PaymentContainer: FunctionComponent = () => {
  const { deal } = useCheckoutContext();
  const isPaymentProcessed = useMemo(
    () => deal?.status === DealStatus.AUTHORIZED || deal?.status === DealStatus.FAILED,
    [deal],
  );
  const [displayPayment, setDisplayPayment] = useState(isPaymentProcessed);

  const showPayment = useCallback(() => {
    setDisplayPayment(true);
  }, [displayPayment]);

  if (displayPayment) {
    return <PaymentPage deal={deal} />;
  }

  return <PaymentDisclaimer handleClick={showPayment} />;
};

export default PaymentContainer;
