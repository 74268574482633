import React, { FunctionComponent, useMemo, useState } from 'react';
import Box from '@material-ui/core/Box';
import { ISchemaFieldProps } from '../../model/IJsonSchema';
import { Checkbox, Typography, useTheme } from '@material-ui/core';
import checkboxStyles from './customCheckboxStyles';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ICheckoutTheme } from 'src/common/theme/ICheckoutTheme';
import ShowIfTranslationExists from '../../../../../common/components/ShowIfTranslationExists';
import { useIntl } from 'react-intl';
import { EnquiryFormFields } from '../../enum/EnquiryFormField';
import { activeJourneyType } from '../../../../common/graphql/cache';
import { useReactiveVar } from '@apollo/client';
import { JourneyTypes } from '../../../../../../../common/enum/JourneyTypes';
import TermsAndConditionsModal from '../termsAndConditionsModal/TermsAndConditionsModal';
import Translation from '../../../../../common/components/presentation/Translation/Translation';

export const CustomCheckbox: FunctionComponent<ISchemaFieldProps> = ({ formData }) => {
  const styles = checkboxStyles();
  const journeyType = useReactiveVar(activeJourneyType);
  const { value, label, id, onChange } = formData;
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onChange(e.target.checked);
  };
  const {
    checkoutLite: {
      enquiryForm: {
        checkboxIconCircle,
        showMarketingInformationDisclaimer,
        useModalTermsAndConditions,
        showOnlyLegalDisclaimerMandatoryLegend,
      },
    },
  } = useTheme<ICheckoutTheme>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const termsWithUrl: React.ReactNode = useMemo(() => {
    return (
      <Typography
        className={styles.disclaimer}
        dangerouslySetInnerHTML={{ __html: label }}
        onClick={e => {
          if (!(e.target as HTMLElement).hasAttribute('href')) {
            e.preventDefault();
          }
        }}
        data-test={`text:enquiryForm:${id}`}
      />
    );
  }, [label]);
  const intl = useIntl();

  const renderMandatoryCondition = showOnlyLegalDisclaimerMandatoryLegend
    ? id === EnquiryFormFields.LEGAL_DISCLAIMER
    : true;

  return (
    <Box className={styles.container}>
      {id !== EnquiryFormFields.MARKETING_INFORMATION && renderMandatoryCondition && (
        <ShowIfTranslationExists translationKey={'trans__checkout__enquiry_form__mandatory_legend'}>
          <>
            <Box className={styles.note}>
              <Typography data-test={`mandatory:label:${id}`}>
                {intl.formatMessage({ id: 'trans__checkout__enquiry_form__mandatory_legend' })}
              </Typography>
            </Box>
            {showMarketingInformationDisclaimer && journeyType === JourneyTypes.RESERVE_ONLINE && (
              <Typography
                className={styles.marketingInformationDisclaimer}
                dangerouslySetInnerHTML={{
                  __html: intl.formatMessage({
                    id: 'trans__checkout__confirmation_page__summary__reservation_reservation_tooltip',
                  }),
                }}
              />
            )}
          </>
        </ShowIfTranslationExists>
      )}
      <Box className={styles.formControlContainer}>
        <Checkbox
          icon={checkboxIconCircle ? <RadioButtonUncheckedOutlinedIcon /> : <CheckBoxOutlineBlankIcon />}
          checkedIcon={checkboxIconCircle ? <CheckCircleIcon /> : <CheckBoxIcon />}
          color={'primary'}
          id={id}
          checked={!!value}
          value={value}
          size={'small'}
          onChange={handleChange}
          data-test={`checkbox:${id}`}
          classes={{
            root: styles.root,
          }}
        />
        <Typography className={styles.label} component={'span'} data-test={`checkbox:label:${id}`}>
          {termsWithUrl}
        </Typography>
      </Box>
      {journeyType === JourneyTypes.RESERVE_ONLINE && useModalTermsAndConditions && (
        <Typography className={styles.modalTitle}>
          <Translation id="trans__checkout__enquiry_form__reserve_online_terms_and_conditions_2" />
          &nbsp;
          <Box
            component="span"
            data-test={`enquiryForm:modal:${id}`}
            className={styles.clickable}
            onClick={() => setIsModalOpen(true)}
          >
            <Translation id="trans__checkout__enquiry_form__reserve_online_terms_and_conditions_title_page" />
          </Box>
        </Typography>
      )}
      <TermsAndConditionsModal isOpen={isModalOpen} toggleModal={setIsModalOpen} id={id} />
    </Box>
  );
};

export default CustomCheckbox;
