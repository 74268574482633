import { useState, useEffect } from 'react';
import useWidgetContext from '../../../common/react/useWidgetContext';

/**
 * @param fileName - string, name of file without extension
 */

const useImageFromAssets = (fileName?: string): string => {
  const [image, setImage] = useState<string>('');

  const {
    configuration: {
      dimensions: { brand, country },
    },
  } = useWidgetContext();

  useEffect(() => {
    if (!fileName) return;
    const importFile = async () => {
      try {
        const image = await import(`../assets/images/${brand}/${country}/${fileName}.png`);
        setImage(image.default);
      } catch {
        setImage(require('../assets/images/banner-bg.png'));
      }
    };

    importFile();
  }, [brand, country, fileName]);

  return image ?? '';
};

export default useImageFromAssets;
