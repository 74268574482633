import React, { FunctionComponent } from 'react';
import { useIntl } from 'react-intl';
import { Box, Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';
import Image from 'material-ui-image';
import financeAndInsuranceStyles from './financeAndInsuranceStyles';
import FinanceAndInsuranceSummary from '../financeAndInsuranceSummary/FinanceAndInsuranceSummary';
import Translation from '../../../../common/components/presentation/Translation/Translation';
import UpdateOrderButton from '../buttons/updateOrderButton/UpdateOrderButton';
import ContinueButton from '../buttons/continueButton/ContinueButton';
import useCheckoutContext from '../../../common/hooks/useCheckoutContext';
import { FinanceFormType } from '../../../../__generated__/globalTypes';
import usePlatformConfig from '../../../../common/react/usePlatformConfig';
import useImageFromAssets from '../../hooks/useImageFromAssets';

const FinanceAndInsurance: FunctionComponent = () => {
  const intl = useIntl();
  const title = intl.formatMessage({ id: 'trans__checkout__next_step__order_now__finance_and_insurance_label' });
  const financeInsuranceLabel3 = intl.formatMessage({
    id: 'trans__checkout__next_step__finance_and_insurance_label3',
    defaultMessage: ' ',
  });
  const imgSrc = useImageFromAssets('finance-insurance');
  const styles = financeAndInsuranceStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { deal } = useCheckoutContext();
  const {
    platformConfig: {
      platformFeatures: { insuranceIntegration },
    },
  } = usePlatformConfig();
  const hasFinance = !!(deal?.finance?.type === FinanceFormType.finance);
  const hasInsurance = !!deal?.insurance;

  const renderUpdateOrderButton = () => {
    if (hasFinance && ((insuranceIntegration && hasInsurance) || !insuranceIntegration)) {
      return null;
    }

    return <UpdateOrderButton />;
  };

  return (
    <Box data-test={'checkout:finance_insurance'}>
      <FinanceAndInsuranceSummary className={styles.summaryLabel} />
      <Grid spacing={2} container className={styles.container}>
        <Grid item sm={12} md={4} className={styles.imageRoot}>
          <Image
            src={imgSrc}
            color="transparent"
            className={styles.image}
            imageStyle={{
              position: isMobile ? 'relative' : 'absolute',
              height: isMobile ? 230 : 'unset',
            }}
            style={{
              paddingTop: isMobile ? 0 : '56.25%',
              height: isMobile ? 'auto' : 0,
            }}
            animationDuration={0}
            alt={title}
            data-test={'checkout:finance_insurance:image'}
          />
        </Grid>
        <Grid item sm={12} md={8} data-test={'checkout:finance_insurance:details'}>
          <Typography className={styles.details}>
            <Translation id={'trans__checkout__next_step__finance_and_insurance_label2'} />
          </Typography>
          <Typography className={styles.financeDetails} dangerouslySetInnerHTML={{ __html: financeInsuranceLabel3 }} />
        </Grid>
      </Grid>
      <Box className={styles.buttonsWrapper}>
        <ContinueButton testId="checkout:continue_button:finance_and_insurance" activateNextStep={true} />
        {renderUpdateOrderButton()}
      </Box>
    </Box>
  );
};

export default FinanceAndInsurance;
