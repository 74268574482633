import { FunctionComponent, useCallback, useEffect } from 'react';
import usePlatformConfig from '../../react/usePlatformConfig';
import { assetManager } from '../../../../../common/service/assetManager';
import { MY_ACCOUNT_CONFIG } from '../../constants/myAccount';
import errorHandler from '../../service/errorHandler';
import useWidgetContext from '../../react/useWidgetContext';

/**
 * This component will start mini-myAccount
 */
const MiniMyAccountComponent: FunctionComponent = () => {
  const {
    platformConfig: { myAccount },
  } = usePlatformConfig();
  const { configuration } = useWidgetContext();
  const miniMyAccountContainer = 'dxp-myaccount-mini-container';
  const selector = myAccount?.miniMyAccountConfig?.dom;

  const ensureLibraryLoaded = () => {
    const widgetUrl: string = MY_ACCOUNT_CONFIG.dxpWidgetUrl;

    if (assetManager.isScriptLoaded(widgetUrl)) {
      if ((window as any).DXPMyAccount) {
        return Promise.resolve();
      }
    }

    return Promise.resolve().then(() => assetManager.loadScript(widgetUrl, 'module'));
  };

  const getLibrary = useCallback(() => {
    if (!(window as any).DXPMyAccount) {
      throw new Error(`Attempted to use DXP MyAccount library without loading it first`);
    }

    return (window as any).DXPMyAccount;
  }, [(window as any).DXPMyAccount]);

  const initializeWidget = () => {
    if (!document.getElementById(miniMyAccountContainer)) return;

    const widgetConfig = {
      cookieDomain: configuration.cookieDomain,
      containerId: miniMyAccountContainer,
      myAccountUrl: configuration.myAccountUrl,
      dimensions: configuration.dimensions,
    };

    const dxpWidget = getLibrary();
    dxpWidget.createMiniWidget(widgetConfig);
  };

  const selectedDOM = useCallback(() => {
    const queryDOM = document.querySelector(selector) as HTMLInputElement | null;

    if (selector && queryDOM !== null) {
      return queryDOM;
    }
    return document.querySelector('.header_inset') as HTMLInputElement | null;
  }, [selector]);

  useEffect(() => {
    if (!document.getElementById(miniMyAccountContainer)) {
      const miniMyAccount = document.createElement('div');
      miniMyAccount.id = miniMyAccountContainer;
      if (myAccount?.miniMyAccountConfig?.style && !!document.querySelector(selector)) {
        miniMyAccount.setAttribute('style', myAccount.miniMyAccountConfig.style);
      }
      selectedDOM()?.appendChild(miniMyAccount);
    }
    if (!(window as any).DXPMyAccount) {
      ensureLibraryLoaded()
        .then(() => initializeWidget())
        .catch(errorHandler.promise());
    }
  }, []);

  return null;
};

export default MiniMyAccountComponent;
