import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import exceptionStyles from './exceptionStyles';
import Translation from '../Translation/Translation';

interface IExceptionProps {
  messageId: string;
  color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error';
}

const Exception: FC<IExceptionProps> = ({ messageId, color }) => {
  const styles = exceptionStyles();

  return (
    <Typography
      variant={'h3'}
      color={color || 'textPrimary'}
      className={styles.root}
      data-test={'text:exception_message'}
    >
      <Translation id={messageId} />
    </Typography>
  );
};

export default Exception;
