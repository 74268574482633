import { assetManager } from '../../../../../../common/service/assetManager';
import { IPaymentData } from '../../../../../../common/model/IPaymentData';
import CONSTANTS from '../../../../../../common/constants/constants';
interface IPaymentProviderVisaNet {
  parentNodeId: string;
  merchantLogo: string;
  paymentData: IPaymentData;
  className: string;
}

const renderPaymentProviderVisaNet: ({
  parentNodeId,
  paymentData,
  className,
}: IPaymentProviderVisaNet) => Promise<void> = ({ parentNodeId, merchantLogo, className, paymentData }) => {
  return Promise.resolve().then(() => {
    if (!paymentData) {
      return null;
    }

    const {
      formUrl,
      sessionToken,
      merchantId,
      purchaseNumber,
      amount,
      cardHolderEmail,
      cardHolderLastName,
      cardHolderName,
    } = paymentData;

    const paymentContainer = document.querySelectorAll(`[data-test='${parentNodeId}']`)[0];

    const form = document.createElement('form');
    form.dataset.test = 'form:checkout:payHereForm';
    form.action = formUrl;
    form.method = 'POST';
    form.className = className;
    form.id = 'form:checkout:payHereForm';

    const scriptDataSet: { [key: string]: string | number } = {
      sessiontoken: sessionToken,
      channel: 'web',
      merchantid: merchantId,
      merchantlogo: merchantLogo,
      purchasenumber: purchaseNumber,
      amount,
      amoexpirationminutesunt: '5',
      timeouturl: 'timeout.html',
      cardholdername: cardHolderName,
      cardholderlastname: cardHolderLastName,
      cardholderemail: cardHolderEmail,
      buttonsize: 'large',
      buttoncolor: 'navy',
      formbuttoncolor: '#00adee',
      showamount: 'true',
      merchantname: 'Subaru',
      test: 'script:checkout:visaNet',
    };

    // If user goes Payment->Enquiry->Payment using back/forward buttons
    // The VisaNet button will not render again
    // For this, we delete its info from the window.

    if ((window as any).VisanetCheckout) {
      delete (window as any).VisanetCheckout;
    }

    paymentContainer.appendChild(form);

    return assetManager.appendScriptToElement(
      'form:checkout:payHereForm',
      CONSTANTS.VISANET_CHECKOUT_RESOURCE_URL,
      scriptDataSet,
    );
  });
};

export default renderPaymentProviderVisaNet;
