import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type StaticDisclaimerClasses = 'disclaimer';

const staticDisclaimerStyles = makeStyles<ICheckoutTheme, { isCheckout4Enabled?: boolean }, StaticDisclaimerClasses>(
  () => ({
    disclaimer: {
      display: 'inline-block',
      position: 'relative',
      margin: '20px 20px 40px',
      textAlign: 'justify',
    },
  }),
  { name: 'GqlCheckout_StaticDisclaimer' },
);

export default staticDisclaimerStyles;
