import { useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { JourneyType } from '../../../../__generated__/globalTypes';
import { SelectJourneyType } from '../../graphql/mutations/__generated__/SelectJourneyType';
import selectJourneyTypeMutation from '../../graphql/mutations/selectJourneyType';
import useToken from '../../../common/hooks/useToken';

export const useSelectJourney = () => {
  const { getCurrentToken } = useToken();
  const [selectJourneyType, { loading, error }] = useMutation<SelectJourneyType>(
    selectJourneyTypeMutation.SELECT_JOURNEY_TYPE,
    {
      context: {
        headers: {
          Authorization: getCurrentToken(),
        },
      },
    },
  );
  const selectJourney = useCallback(
    async (token: string, journeyType: JourneyType) => {
      const dealData = await selectJourneyType({
        variables: {
          dealId: token,
          journeyType: journeyType,
        },
      });
      return dealData?.data;
    },
    [selectJourneyType],
  );

  return { selectJourney, loading, error };
};
