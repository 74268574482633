import { Box } from '@material-ui/core';
import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import paymentStyles from './PaymentVisaNetStyles';
import SubaruLogo from '../../../../../../common/assets/images/SubaruLogo.png';
import BMWLogo from '../../../../../../common/assets/images/BMWLogo.png';
import PaymentProviderVisaNet from './PaymentProviderVisaNet';
import { IPaymentData } from '../../../../../../common/model/IPaymentData';
import DefaultText from '../../staticText/DefaultText';
import errorMessageHoc, {
  IErrorMessageHocProps,
} from '../../../../../../common/components/presentation/Error/errorMessageHoc';
import MinimalLoader from '../../../../../../common/components/presentation/MinimalLoader/MinimalLoader';
import { dimensions } from '../../../../../../../../common/constants/dimensions';
import { useMutation } from '@apollo/client';
import { InitializePaymentVariables } from '../../../graphql/mutations/__generated__/InitializePayment';
import reserveForm from '../../../graphql/mutations/initPayment';
import collectedFilters from '../../../../../../common/service/collectedFilters';
import useDealStatusAssertion from '../../../../../../common/react/useDealStatusAssertion';
import { JourneyTypes } from '../../../../../../../../common/enum/JourneyTypes';
import { IVisanetPaymentResponse } from 'src/gql/commonSteps/payment/model/IVisanetPaymentResponse';
import { GetDeal_deal, GetDeal_deal_paymentInfo } from '../../../../../common/graphql/queries/__generated__/GetDeal';
import { PaymentType } from '../../../../../../__generated__/globalTypes';
import { paymentService } from '../../../../../common/service/paymentService';
import PaymentFailedMessage from './errorMessages/PaymentFailedMessage';
import { useLocation } from 'react-router-dom';
import errorHandler from '../../../../../common/service/errorHandler';
import OutOfStockPaymentError from '../../ErrorPages/OutOfStockPaymentError';
import InitPaymentError from '../../ErrorPages/InitPaymentError';

export interface IPaymentVisaNet extends IErrorMessageHocProps {
  deal: GetDeal_deal;
}

const PaymentVisaNet: FunctionComponent<IPaymentVisaNet> = ({ deal, componentHandleError }) => {
  const style = paymentStyles();
  const [initPayment, { loading }] = useMutation<IVisanetPaymentResponse, InitializePaymentVariables>(
    reserveForm.INITIALIZE_PAYMENT,
  );
  const [parentNodeId] = useState<string>('wrapper:checkout:paymentProvider');
  const [paymentData, setPaymentData] = useState<IPaymentData>(null);
  const [initError, setInitError] = useState(null);
  const [softLocked, setSoftLocked] = useState(false);
  const paymentInfo = deal?.paymentInfo as GetDeal_deal_paymentInfo[];
  const lastTransaction = paymentService.getLastTransaction(paymentInfo);
  const transactionError = lastTransaction?.errorCode?.length;
  const {
    filters: { token },
  } = collectedFilters();
  const location = useLocation<Record<string, []>>();
  const { previousTransactions } = location.state || {};
  const noTransactionInitiated = paymentInfo?.length === previousTransactions?.length;

  useEffect(() => {
    if (deal) {
      handlePayment();
    }
  }, [deal]);

  useEffect(() => {
    renderPaymentSection();
  }, [paymentData]);

  useDealStatusAssertion({
    [JourneyTypes.RESERVE_ONLINE]: 3100,
    [JourneyTypes.CONTINUE_IN_DEALERSHIP]: false,
    [JourneyTypes.ORDER_ONLINE]: false,
  });

  const handlePayment = async () => {
    return await initPayment({
      variables: {
        identifier: token,
        paymentType: PaymentType.reservation_fee,
      },
    })
      .then(initPaymentResponse => {
        if (initPaymentResponse) {
          const {
            data: { initPayment },
          } = initPaymentResponse;

          setPaymentData({
            formUrl: initPayment.url,
            amount: initPayment.amount,
            cardHolderEmail: initPayment.cardHolderEmail,
            cardHolderName: initPayment.cardHolderName,
            cardHolderLastName: initPayment.cardHolderLastName,
            merchantId: initPayment.merchantId,
            purchaseNumber: initPayment.purchaseNumber,
            sessionToken: initPayment.sessionToken,
          });
        }
      })
      .catch(e => {
        const softLockedError = errorHandler.handleSoftLock(e);
        setInitError(e);
        setSoftLocked(softLockedError?.isStockLocked);
      });
  };

  const renderPaymentSection: () => void = useCallback(() => {
    if (loading) {
      return;
    }

    if (deal) {
      let merchantLogo = SubaruLogo;
      if (deal.brand === dimensions.BRAND.BMW_DIS) {
        merchantLogo = BMWLogo;
      }

      return PaymentProviderVisaNet({
        parentNodeId,
        merchantLogo: merchantLogo,
        paymentData,
        className: style.visaNetForm,
      }).catch(componentHandleError);
    }
  }, [loading, paymentData]);

  if (initError) {
    if (softLocked) {
      return <OutOfStockPaymentError />;
    }

    return <InitPaymentError />;
  }

  if (loading || !paymentData) {
    return <MinimalLoader />;
  }

  return (
    <Box className={style.container}>
      {transactionError && !noTransactionInitiated ? (
        <PaymentFailedMessage style={style} paymentInfo={lastTransaction} />
      ) : (
        <DefaultText paymentData={paymentData} style={style} />
      )}
      <Box data-test={parentNodeId} className={[style.paymentButtonWrapper, !loading && style.alignCenter].join(' ')} />
    </Box>
  );
};

export default errorMessageHoc(PaymentVisaNet);
