import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type TermsAndConditionsModalClasses = 'root';

const termsAndConditionsModalStyles = makeStyles<ICheckoutTheme, TermsAndConditionsModalClasses>(
  ({ typography }) => ({
    root: {
      display: 'grid',
      fontFamily: typography.fontFamily,
      padding: '16px',
    },
  }),
  { name: 'GqlCheckout_TermsAndConditionModal' },
);

export default termsAndConditionsModalStyles;
