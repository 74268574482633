import { createContext } from 'react';
import { GetDeal_deal } from 'src/gql/common/graphql/queries/__generated__/GetDeal';
import { ExpressionOfInterest } from '../graphql/fragments/deal/__generated__/ExpressionOfInterest';
import { ApolloError } from '@apollo/client';
import { StockType } from 'src/__generated__/globalTypes';

type CheckoutContextProps = {
  deal: GetDeal_deal;
  setDeal?: (deal: GetDeal_deal) => void;
  loading: boolean;
  error?: ApolloError;
  refetch?: () => void;
  expressionOfInterest?: ExpressionOfInterest[];
  extrasPageHasContent?: boolean | null;
  isStock?: boolean;
  isJlrStockDiscount?: boolean;
  stockType?: StockType | null;
};

const CheckoutContextDefault: CheckoutContextProps = {
  deal: null,
  loading: false,
};

const CheckoutContext = createContext<CheckoutContextProps>(CheckoutContextDefault);

export default CheckoutContext;
