import React from 'react';
import IPlatformConfig from '../model/IPlatformConfig';
import PlatformConfigContext from '../react/PlatformJsonnetContext';
import IInitializerComponent from '../components/IInitializerComponent';
import jsonnetApi from '../api/jsonnetApi';

const PlatformJsonnetConfigProvider: IInitializerComponent<{ config: IPlatformConfig }> = {
  initialize: () => {
    return jsonnetApi.getConfiguration().then((config: IPlatformConfig | void) => {
      return { config: config as IPlatformConfig };
    });
  },

  component: ({ children, config }) => {
    return <PlatformConfigContext.Provider value={config}>{children}</PlatformConfigContext.Provider>;
  },
};

export default PlatformJsonnetConfigProvider;
