import { ICheckoutTheme } from '../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type FinanceSectionClasses =
  | 'financeWrapper'
  | 'financeDetails'
  | 'title'
  | 'details'
  | 'image'
  | 'backToFinanceButtonContainer'
  | 'continueButtonContainer';

const financeSectionStyles = makeStyles<ICheckoutTheme, FinanceSectionClasses>(
  ({ breakpoints }) => ({
    financeWrapper: {
      marginBottom: '40px',
      padding: '0 30px',
      [breakpoints.down('sm')]: {
        padding: '0 15px',
      },
    },
    financeDetails: {
      padding: '10px 0',
      width: '100%',

      [breakpoints.down('sm')]: {
        textAlign: 'center',
      },
    },
    title: {
      display: 'block',
      fontWeight: 700,
      marginBottom: '10px',
    },
    details: {
      display: 'block',
      marginBottom: '20px',
    },
    backToFinanceButtonContainer: {},
    image: {},
    continueButtonContainer: {},
  }),
  { name: 'Checkout4_FinanceSection' },
);

export default financeSectionStyles;
