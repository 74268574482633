import { IRawError, ISchemaField } from '../model/IJsonSchema';
import { EnquiryFormFieldIDs } from '../enum/EnquiryFormFieldIDs';

const enquiryFormInputFieldErrorMessage = {
  setErrorMessage: (formData: ISchemaField, rawErrors: IRawError[]) => {
    if (formData.id === EnquiryFormFieldIDs.IDENTIFICATION_NUMBER_OF_DOCUMENT_TYPE) {
      const errMessageHint = formData.schema?.maxLength;
      return rawErrors?.find(err => {
        const message: any = err;
        return message.search(errMessageHint.toString()) !== -1;
      });
    }
  },
};

export default enquiryFormInputFieldErrorMessage;
