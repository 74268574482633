import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import Translation from '../../../../../../common/components/presentation/Translation/Translation';
import FailedPaymentError from '../../ErrorPages/FailedPaymentError';
import InitPaymentError from '../../ErrorPages/InitPaymentError';
import OutOfStockPaymentError from '../../ErrorPages/OutOfStockPaymentError';
import { TransactionErrorTypes } from '../../../../../../common/enum/TransactionErrorTypes';
import { ApolloError } from '@apollo/client';
import webpayStyles from './webpayStyles';
import { GetDeal_deal } from '../../../../../common/graphql/queries/__generated__/GetDeal';
import MinimalLoader from '../../../../../../common/components/presentation/MinimalLoader/MinimalLoader';
import { useIntl } from 'react-intl';

interface IWebpayProps {
  initError: ApolloError;
  softLocked?: boolean;
  loading: boolean;
  deal: GetDeal_deal;
}

const Webpay: FC<IWebpayProps> = ({ initError, loading, deal, softLocked }) => {
  const styles = webpayStyles();
  const intl = useIntl();

  if (loading) {
    return <MinimalLoader />;
  }

  if (initError) {
    if (softLocked) {
      return <OutOfStockPaymentError />;
    }

    return <InitPaymentError />;
  }

  if (!loading && deal?.status === TransactionErrorTypes.FAILED) {
    return <FailedPaymentError />;
  }

  return (
    <>
      <Typography variant="subtitle1" className={styles.title}>
        <Translation id="trans__checkout__payment_page__subtitle" />
      </Typography>
      <Typography variant="subtitle2">
        <Translation className={styles.description} id="trans__checkout__payment_page__description1" />
      </Typography>
      <Typography variant="subtitle1" className={styles.description2}>
        <Translation
          id="trans__checkout__payment_page__description2"
          values={{
            email: (
              <a
                className={styles.email}
                href={`mailto:${intl.formatMessage({
                  id: 'trans__checkout__payment_page__email',
                })}`}
              >
                <Translation id={'trans__checkout__payment_page__email'} />
              </a>
            ),
          }}
        />
      </Typography>
    </>
  );
};

export default Webpay;
