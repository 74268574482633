import React from 'react';
import universal, { UniversalComponent } from 'react-universal-component';
import errorHandler from '../../common/service/errorHandler';
import MinimalLoader from '../../../common/components/presentation/MinimalLoader/MinimalLoader';
import ThemedErrorMessage from '../../../common/components/presentation/Error/ThemedErrorMessage';
import IThemableComponent from 'src/common/theme/IThemableComponent';
import { ConfirmationMessageClasses } from './components/continueInDealership/confirmationMessageStyles';
import { SummaryTitleClasses } from '../summary/components/summaryTitle/summaryTitleStyles';
import { SummaryClasses } from '../summary/components/summaryViews/summaryStyles';

export interface IGqlDeferredContinueInDealerConfirmationThemableComponents {
  GqlCheckout_ConfirmationMessage: IThemableComponent<ConfirmationMessageClasses>;
  GqlSummary_SummaryTitle: IThemableComponent<SummaryTitleClasses>;
  GqlCheckout_Summary: IThemableComponent<SummaryClasses>;
}

const GqlDeferredContinueInDealerConfirmation: UniversalComponent<any> = universal<any>(
  () => import(/* webpackChunkName: "enquiry-form-confirmation" */ './components/ContinueInDealerConfirmationScreen'),
  {
    loading: (): JSX.Element => <MinimalLoader />,
    error: (props: { error: Error }) => {
      errorHandler.handleError(props.error);
      return (
        <ThemedErrorMessage
          fullPage={true}
          message={'Failed loading continue in dealership screen.'}
          error={props.error}
        />
      );
    },
  },
);

export default GqlDeferredContinueInDealerConfirmation;
