import React, { FunctionComponent, ReactElement } from 'react';
import Button from '@material-ui/core/Button';

interface IDefaultButton {
  className?: string[];
  dataTest: string;
  variant: 'contained' | 'outlined' | 'text';
  labelClasses?: string;
  outlinedPrimary?: string;
  containedPrimary?: string;
  contained?: string;
  children?: ReactElement | string;
  type?: 'submit' | 'button';
  disabled?: boolean;
  onClick?: () => void;
  disableTouchRipple?: boolean;
}

const DefaultButton: FunctionComponent<IDefaultButton> = ({
  className,
  dataTest,
  variant = 'contained',
  labelClasses = '',
  outlinedPrimary = '',
  children,
  type = 'button',
  disabled = false,
  onClick = () => null,
  disableTouchRipple,
  containedPrimary,
  contained,
  ...props
}) => {
  return (
    <Button
      {...props}
      onClick={onClick}
      type={type}
      disabled={disabled}
      data-test={dataTest}
      className={className?.join(' ')}
      variant={variant}
      classes={{
        label: labelClasses,
        outlinedPrimary: outlinedPrimary,
        contained: contained,
        containedPrimary: containedPrimary,
      }}
      disableTouchRipple={disableTouchRipple}
    >
      {children}
    </Button>
  );
};

export default DefaultButton;
