import React, { FunctionComponent, useCallback } from 'react';
import { activeJourneyType } from '../../../common/graphql/cache';
import { JourneyType } from '../../../../__generated__/globalTypes';
import EnquiryForm from '../../../commonSteps/enquiryForm/components/enquiryForm/EnquiryForm';
import useJourneyStepsContext from '../../hooks/useJourneyStepsContext';
import { UrlParamTypes } from '../../../../common/enum/UrlParamTypes';
import useUrlDealId from '../../../common/hooks/useUrlDealId';
import { useHistory } from 'react-router';
import { JourneyStepsRoutes } from '../../enum/JourneyStepsRoutes';

type CustomerInformationProps = {
  stateId?: string;
};

const CustomerInformation: FunctionComponent<CustomerInformationProps> = ({ stateId }) => {
  const { handleNext } = useJourneyStepsContext();
  const currentJourney = activeJourneyType();
  const history = useHistory();
  const token = useUrlDealId();

  const goToNextStep = useCallback(() => {
    switch (currentJourney) {
      case JourneyType.continue_in_dealership:
        history.replace({
          pathname: JourneyStepsRoutes.CONFIRMATION,
          search: `?${UrlParamTypes.TOKEN}=${token}`,
        });
        break;
      default:
        handleNext();
    }
  }, [currentJourney]);

  return <EnquiryForm stateId={stateId} journeyType={activeJourneyType() as JourneyType} goToNextStep={goToNextStep} />;
};

export default CustomerInformation;
