import { useContext } from 'react';
import JourneyStepsContext, { IJourneyStepsContextProps } from '../components/context/JourneyStepsContext';

const useJourneyStepsContext = (): IJourneyStepsContextProps => {
  const { steps, activeStep, changeActiveStep, handleNext } = useContext(JourneyStepsContext);

  return { steps, activeStep, changeActiveStep, handleNext };
};

export default useJourneyStepsContext;
