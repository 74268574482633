import React, { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { Box } from '@material-ui/core';
import ExpressionsOfInterest from '../../../../commonSteps/extras/components/expressionsOfInterest/ExpressionsOfInterest';
import useCheckoutContext from '../../../../common/hooks/useCheckoutContext';
import Loader from '../../../../../common/components/presentation/Loader/Loader';
import extrasStyles from './extrasStyles';
import { Routes } from '../../../../../common/enum/Routes';
import { useHistory } from 'react-router';
import collectedFilters from '../../../../../common/service/collectedFilters';
import { useSaveExpressionsOfInterest } from '../../../../commonSteps/extras/hooks/mutations/useSaveExpressionsOfInterest';
import useJourneyStepsContext from '../../../hooks/useJourneyStepsContext';
import ContinueButton from '../../buttons/continueButton/ContinueButton';

const Extras: FunctionComponent = () => {
  const styles = extrasStyles();
  const history = useHistory();
  const { handleNext } = useJourneyStepsContext();
  const { saveExpressionsOfInterest } = useSaveExpressionsOfInterest();
  const { deal, loading, error, refetch } = useCheckoutContext();
  const [dealExpressionsOfInterest, setDealExpressionsOfInterest] = useState([]);
  const { postCode } = deal?.dealer || {};
  const {
    filters: { token, expressionsOfInterest },
  } = collectedFilters();

  useEffect(() => {
    setDealExpressionsOfInterest(deal?.expressionsOfInterest ? deal.expressionsOfInterest.map(item => item.code) : []);
  }, [deal]);

  const goToNextStep = useCallback(() => handleNext(), [handleNext]);

  const redirectUnavailableDeal = useCallback(() => history.replace(Routes.DEAL_UNAVAILABLE, {}), []);

  const clickContinueCTA = useCallback(() => {
    const codes = expressionsOfInterest?.split(',') ?? [];
    saveExpressionsOfInterest(token, codes)
      .then(() => {
        setDealExpressionsOfInterest(codes);
        refetch();
        goToNextStep && goToNextStep();
      })
      .catch(() => {
        history.push({ pathname: Routes.DEAL_UNAVAILABLE });
      });
  }, [history, token, postCode, expressionsOfInterest, dealExpressionsOfInterest]);

  useEffect(() => {
    if (error) {
      redirectUnavailableDeal();
    }
  }, [error]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <Box className={styles.container} data-test={'checkout4:extras:root'}>
        <ExpressionsOfInterest dealExpressionsOfInterest={dealExpressionsOfInterest} />
      </Box>
      <Box className={styles.actionButtonContainer}>
        <ContinueButton
          testId="checkout4:continue_button:extras"
          handleClick={clickContinueCTA}
          activateNextStep={false}
        />
      </Box>
    </>
  );
};

export default Extras;
