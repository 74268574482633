import { Button } from '@material-ui/core';
import { ApolloQueryResult } from '@apollo/client';
import React, { FC, useState } from 'react';
import { getPersonalDetailsUrl } from '../../../../../common/constants/myAccount';
import useWidgetContext from '../../../../../common/react/useWidgetContext';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import {
  GetEnquiryFormSchema,
  GetEnquiryFormSchemaVariables,
} from '../../graphql/queries/__generated__/GetEnquiryFormSchema';
import myAccountChangeDetailsCtaStyles from './myAccountChangeDetailsCtaStyles';

interface MyAccountChangeDetailsCtaProps {
  refetchEnquiryForm?: (
    variables?: Partial<GetEnquiryFormSchemaVariables>,
  ) => Promise<ApolloQueryResult<GetEnquiryFormSchema>>;
}

const MyAccountChangeDetailsCta: FC<MyAccountChangeDetailsCtaProps> = ({ refetchEnquiryForm }) => {
  const {
    configuration: { myAccountUrl },
  } = useWidgetContext();
  const styles = myAccountChangeDetailsCtaStyles();
  const [showRefetchCta, setShowRefetchCta] = useState<boolean>(false);
  const translationId = showRefetchCta
    ? 'trans__checkout__next_step__customer_information__refresh_cta'
    : 'trans__checkout__next_step__customer_information__change_detail_cta';
  const handleButtonClick = () => {
    if (showRefetchCta) {
      refetchEnquiryForm();
    } else {
      window.open(getPersonalDetailsUrl(myAccountUrl), '_blank');
      setShowRefetchCta(true);
    }
  };

  return (
    <Button
      className={styles.root}
      onClick={handleButtonClick}
      data-test={`checkout:myaccount:change-details${showRefetchCta ? '-refetch' : ''}-cta`}
    >
      <Translation id={translationId} className={styles.text} />
    </Button>
  );
};

export default MyAccountChangeDetailsCta;
