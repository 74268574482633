import React, { FunctionComponent } from 'react';
import dealersListContainerStyles from './dealersListContainerStyles';
import { Box } from '@material-ui/core';
import DealerListHeader from '../dealerListHeader/DealerListHeader';
import DealersList from '../dealersList/DealersList';
import { GetDealers_dealers_nodes } from '../../../graphql/queries/__generated__/GetDealers';

export interface IDealersListContainerProps {
  dealers: GetDealers_dealers_nodes[];
  selectedDealerId: string;
  selectDealerId: (id: string) => void;
  disableDealerSelection?: boolean;
}

const DealersListContainer: FunctionComponent<IDealersListContainerProps> = ({
  dealers,
  selectedDealerId,
  selectDealerId,
  disableDealerSelection,
}) => {
  const styles = dealersListContainerStyles();

  return (
    <Box className={styles.listContainer} data-test="container:dealerList">
      <DealerListHeader />
      <DealersList
        dealers={dealers}
        selectedDealerId={selectedDealerId}
        selectDealerId={selectDealerId}
        disableDealerSelection={disableDealerSelection}
      />
    </Box>
  );
};

export default DealersListContainer;
