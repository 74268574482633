import React, { FC, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Typography } from '@material-ui/core';
import { ApolloError } from '@apollo/client';
import paymentEwayStyles from './paymentEwayStyles';
import Translation from '../../../../../../common/components/presentation/Translation/Translation';
import InitPaymentError from '../../ErrorPages/InitPaymentError';
import FailedPaymentError from '../../ErrorPages/FailedPaymentError';
import MinimalLoader from '../../../../../../common/components/presentation/MinimalLoader/MinimalLoader';
import SoftLockModal from '../../../../../checkout4/components/softLockModal/softLockModal';
import useJourneyStepsContext from '../../../../../checkout4/hooks/useJourneyStepsContext';
import { activeJourneyType } from '../../../../../common/graphql/cache';
import { JourneyType } from '../../../../../../__generated__/globalTypes';
import useJourneyListContext from '../../../../../checkout4/context/journeys/useJourneyListContext';
import { ScrollInViewBehaviourType, ScrollInViewBlockType } from '../../../../../../common/enum/ScrollInViewTypes';

interface PaymentEwayProps {
  initError: ApolloError;
  paymentAmount: string;
  isPaymentFailed: boolean;
  loading: boolean;
  refundableState: string;
}

const PaymentEway: FC<PaymentEwayProps> = ({ initError, isPaymentFailed, loading, paymentAmount, refundableState }) => {
  const styles = paymentEwayStyles();
  const intl = useIntl();
  const [isVisible, setIsVisible] = useState(false);

  const { changeActiveStep } = useJourneyStepsContext();

  const { updateJourneyList } = useJourneyListContext();
  const softLockError = initError?.message === 'trans__checkout__payment_page__out_of_stock_message';

  useEffect(() => {
    if (initError && !isVisible && !loading) {
      setIsVisible(true);
    }
  }, [initError, loading]);

  const scrollToActiveStep = () => {
    setTimeout(() => {
      document
        .querySelector(`[data-test="checkout:step_0"]`)
        .scrollIntoView({ behavior: ScrollInViewBehaviourType.SMOOTH, block: ScrollInViewBlockType.CENTER });
    }, 250);
  };

  const handleSoftLockContinueBtn = () => {
    scrollToActiveStep();
    updateJourneyList(JourneyType.online_order);
    setIsVisible(false);
    activeJourneyType(JourneyType.continue_in_dealership);
    changeActiveStep(0);
  };

  if (loading) {
    return <MinimalLoader />;
  }

  if (initError && !softLockError) {
    return <InitPaymentError />;
  }

  if (isPaymentFailed) {
    return <FailedPaymentError />;
  }

  return (
    <>
      <Typography data-test="text:payment:subtitle" variant="subtitle1" className={styles.subtitle}>
        <Translation id="trans__checkout__payment_page__order_online__subtitle" />
      </Typography>
      <Typography data-test="text:payment:description" variant="subtitle1" className={styles.description}>
        <Translation
          id="trans__checkout__payment_page__description1"
          values={{
            reservationFee: paymentAmount,
            refundstate: refundableState?.toLowerCase() ?? '',
          }}
        />
      </Typography>
      <Typography data-test="text:payment:description2" variant="subtitle1" className={styles.description2}>
        <Translation
          id="trans__checkout__payment_page__description2"
          values={{
            email: (
              <a
                className={styles.email}
                href={`mailto:${intl.formatMessage({
                  id: 'trans__checkout__payment_page__email',
                })}`}
              >
                <Translation id="trans__checkout__payment_page__email" />
              </a>
            ),
          }}
        />
      </Typography>
      {isVisible && (
        <SoftLockModal
          isOpen={!!softLockError}
          onClose={() => setIsVisible(false)}
          handleContinueBtn={handleSoftLockContinueBtn}
        />
      )}
    </>
  );
};

export default PaymentEway;
