import React, { FunctionComponent } from 'react';
import { Box } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';

const SectionLoader: FunctionComponent = () => (
  <Box width={'100%'} data-test={'section:loader'}>
    <Box display="flex" justifyContent="space-between" alignItems="center">
      <Skeleton animation="wave" height={42} width="45%" />
      <Box style={{ width: 27, height: 27 }}>
        <Skeleton animation="wave" variant="circle" height={27} />
      </Box>
    </Box>
    <Skeleton animation="wave" height={35} width="85%" />
    <Skeleton animation="wave" height={35} width="85%" />
    <Skeleton animation="wave" height={35} width="85%" />
  </Box>
);

export default SectionLoader;
