import { makeStyles } from '@material-ui/core/styles';
import { ICheckoutTheme } from '../../../../../../../common/theme/ICheckoutTheme';

export type DealerDistanceClasses = 'dealerDistance' | 'dealerDistanceUnit' | 'geoNotSupported';

const dealerDetailsStyles = makeStyles<ICheckoutTheme, DealerDistanceClasses>(
  theme => ({
    dealerDistance: {
      display: 'flex',
      flex: 'auto',
      justifyContent: 'end',
      color: theme.palette.common.black,
    },
    dealerDistanceUnit: {
      marginLeft: 4,
      '& span': {
        fontWeight: 'bold',
      },
    },
    geoNotSupported: {
      maxWidth: 100,
    },
  }),
  { name: 'Checkout_DealerDistance' },
);

export default dealerDetailsStyles;
