import { IEnquiryFormSchema, IEnquiryFormData } from '../model/IJsonSchema';

const enquiryFormDataTransformerGQL = {
  to(data: any): IEnquiryFormSchema | any {
    const formData: IEnquiryFormSchema = {
      nameInfo: {
        firstName: data.firstName || '',
        lastName: data.lastName || '',
        salutation: data.salutation || 1,
        nationalityId: data.nationalityId || '',
      },
      contactInfo: {
        phone: data.phone || '',
        email: data.email || '',
        areaCode: data.areaCode || '',
        identificationNumber: data.identificationNumber,
        preferredContactMethod: data.preferredContactMethod || '',
      },
      regionDistrict: {
        region: data.region || '',
        district: data.district || '',
      },
      address: data.address || '',
      cityDealer: {
        citySelect: data.citySelect || '',
        dealerSelect: data.dealerSelect || '',
      },
      provinceRegionDistrict: {
        region: data.region || '',
        district: data.district || '',
        province: data.province || '',
      },
      cityDistrictDealer: {
        citySelect: data.citySelect || '',
        cityDistrict: data.cityDistrict || '',
        dealerSelect: data.dealerSelect || '',
      },
      regionDealer: {
        regionSelect: data.regionSelect || '',
        dealerSelect: data.dealerSelect || '',
      },
      identificationNumberByType: {
        identificationType: data.identificationType || '',
        identificationNumber: data.identificationNumber || '',
      },
      location: {
        city: data.city || '',
        dealer: data.dealer || '',
        postCodeAutocomplete: data.postCodeAutocomplete || '',
      },
      termsAndConditions: data.termsAndConditions ?? false,
      legalDisclaimer: data.legalDisclaimer ?? false,
      privacyPolicy: data.privacyPolicy ?? false,
      marketingInformation: data.marketingInformation ?? false,
      captchaToken: data.captchaToken,
      appointment: {
        dealerSelect: data.dealerSelect || '',
        salesConsultant: data.salesConsultant || '',
        preferredDate: data.preferredDate || '',
        preferredTime: data.preferredTime || '',
        purchaseTime: data.purchaseTime || '',
        comments: data.comments || '',
        identificationNumberRut: data.identificationNumberRut ?? '',
      },
      housing: {
        housingDepartment: data.housingDepartment || '',
        housingProvince: data.housingProvince || '',
        housingDistrict: data.housingDistrict || '',
        housingStreet: data.housingStreet || '',
      },
      tradeIn: {
        colour: data.tradeIn.colour || '',
        make: data.tradeIn.make || '',
        model: data.tradeIn.model || '',
        variant: data.tradeIn.variant || '',
        year: data.tradeIn.year || '',
        mileage: data.tradeIn.mileage || '',
        tradeInCheckbox: data.tradeIn.tradeInCheckbox || false,
      },
      customerTypeSwitch: {
        companyName: data.customerTypeSwitch.companyName || '',
        customerType: data.customerTypeSwitch.customerType || '',
      },
      tradeInSelect: data.tradeInSelect || '',
      singpassInfo: {
        fullNamePerNRIC: data.singpassInfo.fullNamePerNRIC,
        identity: data.singpassInfo.identity,
        occupation: data.singpassInfo.occupation,
        occupationType: data.singpassInfo.occupationType,
        dateOfBirth: data.singpassInfo.dateOfBirth,
        email: data.singpassInfo.email,
        NRIC: data.singpassInfo.NRIC,
        gender: data.singpassInfo.gender,
        nationalityId: data.singpassInfo.nationalityId,
        phone: data.singpassInfo.phone,
        telephoneNo: data.singpassInfo.telephoneNo,
        block: data.singpassInfo.block,
        unitNo: data.singpassInfo.unitNo,
        country: data.singpassInfo.country,
        model: data.singpassInfo.model,
        aliasName: data.singpassInfo.aliasName,
        hanyuPinyinAliasName: data.singpassInfo.hanyuPinyinAliasName,
        drivingLicenseDate: data.singpassInfo.drivingLicenseDate,
        streetName: data.singpassInfo.streetName,
        buildingName: data.singpassInfo.buildingName,
        postalCode: data.singpassInfo.postalCode,
        variant: data.singpassInfo.variant,
        hanyuPinyinName: data.singpassInfo.hanyuPinyinName,
        maritalStatus: data.singpassInfo.maritalStatus,
        marriedName: data.singpassInfo.marriedName,
      },
      customerTypeInfo: {
        customerType: data.customerTypeInfo.customerType || '',
      },
    };

    if (data.district) {
      formData.location.district = data.district;
    }

    if (data.identificationType) {
      formData.contactInfo.identificationType = data.identificationType;
    }

    if (data.salesConsultant) {
      formData.dealerConsultant.dealerSelect = data.dealerSelect;
      formData.dealerConsultant.salesConsultant = data.salesConsultant;
    }

    return formData;
  },

  from(data: any): IEnquiryFormData {
    const formData: IEnquiryFormData = {
      firstName: data.nameInfo?.firstName,
      lastName: data.nameInfo?.lastName,
      phone: data.contactInfo?.phone,
      email: data.contactInfo?.email,
      dealerSelect:
        data.appointment?.dealerSelect ||
        data.cityDealer?.dealerSelect ||
        data.postCodeDealer?.dealerSelect ||
        data.regionDealer?.dealerSelect ||
        data.dealerSelect,
      salesConsultant: data.appointment?.salesConsultant,
      termsAndConditions: data.termsAndConditions,
      legalDisclaimer: data.legalDisclaimer,
      privacyPolicy: data.privacyPolicy,
      marketingCommercialOptins: data?.marketingCommercialOptins?.length,
      captchaToken: data.captchaToken,
      identificationType: data.identificationNumberByType?.identificationType,
      identificationNumber: data.identificationNumberByType?.identificationNumber,
      identificationNumberRut: data.identificationNumberRut,
      citySelect: data.cityDealer?.citySelect,
      postCodeAutocomplete: data.postCodeDealer?.postCodeAutocomplete,
      companyName: data.customerTypeSwitch?.companyName,
      customerType: data.customerTypeSwitch?.customerType,
      regionSelect: data.regionDealer?.regionSelect,
      tradeInSelect: data.tradeInSelect,
    };

    if (data.appointment?.preferredDate) {
      formData.preferredDate = data.appointment.preferredDate;
    }
    if (data.contactInfo?.preferredContactMethod) {
      formData.preferredContactMethod = data.contactInfo.preferredContactMethod;
    }
    if (data.appointment?.preferredTime) {
      formData.preferredTime = data.appointment.preferredTime;
    }
    if (data.appointment?.purchaseTime) {
      formData.purchaseTime = data.appointment.purchaseTime;
    }
    if (data.appointment?.comments) {
      formData.comments = data.appointment.comments;
    }

    if (data.appointment?.salesConsultant) {
      formData.salesConsultant = data.appointment.salesConsultant;
    }

    if (data.nameInfo?.salutation) {
      formData.salutation = data.nameInfo.salutation;
    }

    if (data.nameInfo?.nationalityId) {
      formData.nationalityId = data.nameInfo.nationalityId;
    }

    if (data.contactInfo?.areaCode) {
      formData.areaCode = data.contactInfo.areaCode;
    }

    if (data.contactInfo?.identificationType) {
      formData.identificationType = data.contactInfo.identificationType;
    }

    if (data.appointment?.identificationNumberRut) {
      formData.identificationNumberRut = data.appointment.identificationNumberRut;
    }

    if (data.location?.city) {
      formData.city = data.location.city;
    }

    if (data.location?.district) {
      formData.district = data.location.district;
    }

    if (data.appointment?.postCodeAutocomplete) {
      formData.postCodeAutocomplete = data.appointment.postCodeAutocomplete;
    }

    if (data.contactInfo?.identificationNumber) {
      formData.identificationNumber = data.contactInfo.identificationNumber;
    }

    if (data.marketingInformation) {
      formData.marketingInformation = data.marketingInformation;
    }

    if (data.housing) {
      formData.housingDepartment = data.housing.housingDepartment || '';
      formData.housingProvince = data.housing.housingProvince || '';
      formData.housingDistrict = data.housing.housingDistrict || '';
      formData.housingStreet = data.housing.housingStreet || '';
    }

    if (data.identificationNumberByType?.identificationNumber) {
      formData.identificationNumber = data.identificationNumberByType.identificationNumber;
    }

    if (data.tradeIn?.tradeInCheckbox) {
      formData.colour = data.tradeIn.colour;
      formData.make = data.tradeIn.make;
      formData.model = data.tradeIn.model;
      formData.variant = data.tradeIn.variant;
      formData.year = data.tradeIn.year;
      formData.mileage = data.tradeIn.mileage;
      formData.tradeInCheckbox = data.tradeIn.tradeInCheckbox;
    }

    if (data.regionDistrict) {
      formData.region = data.regionDistrict.region;
      formData.district = data.regionDistrict.district;
    }

    if (data.provinceRegionDistrict) {
      formData.region = data.provinceRegionDistrict.region;
      formData.district = data.provinceRegionDistrict.district;
      formData.province = data.provinceRegionDistrict.province;
    }

    if (data.cityDistrictDealer) {
      formData.citySelect = data.cityDistrictDealer.citySelect;
      formData.cityDistrict = data.cityDistrictDealer.cityDistrict;
      formData.dealerSelect = data.cityDistrictDealer.dealerSelect;
    }

    if (data.address) {
      formData.address = data.address;
    }

    if (data.customerTypeSwitch?.companyName) {
      formData.companyName = data.customerTypeSwitch.companyName;
    }

    if (data.customerTypeSwitch?.customerType) {
      formData.customerType = data.customerTypeSwitch.customerType;
    }

    if (data.regionDealer?.regionSelect) {
      formData.regionSelect = data.regionDealer.regionSelect;
    }

    if (data.tradeInSelect) {
      formData.tradeInSelect = data.tradeInSelect;
    }

    if (data.dealerConsultant) {
      formData.dealerSelect = data.dealerConsultant.dealerSelect;
      formData.salesConsultant = data.dealerConsultant.salesConsultant;
    }

    if (data.phoneNumberByArea) {
      formData.phone = data.phoneNumberByArea.phone;
      formData.areaCode = data.phoneNumberByArea.areaCode;
    }

    if (data.termsAndConditionsWithConsent) {
      formData.termsAndConditionsWithConsent = data.termsAndConditionsWithConsent;
    }

    if (data.singpassInfo) {
      formData.fullNamePerNRIC = data.singpassInfo.fullNamePerNRIC;
      formData.identity = data.singpassInfo.identity;
      formData.occupation = data.singpassInfo.occupation;
      formData.occupationType = data.singpassInfo.occupationType;
      formData.dateOfBirth = data.singpassInfo.dateOfBirth;
      formData.email = data.singpassInfo.email;
      formData.NRIC = data.singpassInfo.NRIC;
      formData.gender = data.singpassInfo.gender;
      formData.nationalityId = data.singpassInfo.nationalityId;
      formData.phone = data.singpassInfo.phone;
      formData.telephoneNo = data.singpassInfo.telephoneNo;
      formData.block = data.singpassInfo.block;
      formData.unitNo = data.singpassInfo.unitNo;
      formData.country = data.singpassInfo.country;
      formData.model = data.singpassInfo.model;
      formData.aliasName = data.singpassInfo.aliasName;
      formData.hanyuPinyinAliasName = data.singpassInfo.hanyuPinyinAliasName;
      formData.drivingLicenseDate = data.singpassInfo.drivingLicenseDate;
      formData.streetName = data.singpassInfo.streetName;
      formData.buildingName = data.singpassInfo.buildingName;
      formData.postalCode = data.singpassInfo.postalCode;
      formData.variant = data.singpassInfo.variant;
      formData.hanyuPinyinName = data.singpassInfo.hanyuPinyinName;
      formData.maritalStatus = data.singpassInfo.maritalStatus;
      formData.marriedName = data.singpassInfo.marriedName;
    }

    if (data.customerTypeInfo?.customerType) {
      formData.customerType = data.customerTypeInfo.customerType;
    }

    return formData;
  },
};

export default enquiryFormDataTransformerGQL;
