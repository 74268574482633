const errorHandler = {
  warn(error: any): void {
    /* tslint:disable:no-console */
    if (process.env.ENVIRONMENT !== 'prod') {
      console.warn(error);
    }
  },

  handleError(error: any): void {
    /* tslint:disable:no-console */
    console.error(error);
  },

  promise(): (error: any) => void {
    return error => errorHandler.handleError(error);
  },
};

export default errorHandler;
