import { Box, Typography } from '@material-ui/core';
import React, { useMemo } from 'react';
import { FC } from 'react';
import collectedFilters from '../../../../../../common/service/collectedFilters';
import useCheckoutContext from '../../../../../common/hooks/useCheckoutContext';
import expressionOfInterestSummaryStyles from './expressionOfInterestSummaryStyles';

const ExpressionOfInterestSummary: FC = () => {
  const styles = expressionOfInterestSummaryStyles();
  const {
    filters: { expressionsOfInterest: eoi },
  } = collectedFilters();
  const eoiFilters = eoi?.split(',');
  const { expressionOfInterest } = useCheckoutContext();

  const selectedItems = useMemo(() => {
    const selectedCodes = eoiFilters || [];
    const selections = expressionOfInterest.filter(item => selectedCodes.includes(item.code));
    return selections;
  }, [expressionOfInterest, eoiFilters]);

  return (
    <Box data-test={'checkout4:extras:summary:root'}>
      {selectedItems.map(item => (
        <Typography
          key={item.code}
          variant={'body1'}
          className={styles.item}
          data-test={`checkout:expressions_of_interest:summary:label:${item.code}`}
        >
          {item.attributes.label}
        </Typography>
      ))}
    </Box>
  );
};
export default ExpressionOfInterestSummary;
