import dxpDigitalDataAssignment from '../../../../common/utilities/dxpDigitalDataAssignment';
import { DxpDataLayerEvent, DxpDataLayerEventNames } from '../../../../common/utilities/customDatalayerInterface';
import dxpProducts from '../../../../common/constants/dxpProducts';
import { formatDataLayerDeal, getSaveEnquiryFormNextSteps } from '../../gql/common/utils/dxpDataLayerHandler';
import {
  SaveEnquiryForm_saveEnquiryForm,
  SaveEnquiryForm,
} from 'src/gql/commonSteps/enquiryForm/graphql/mutations/__generated__/SaveEnquiryForm';
import { JourneyStepsRoutes } from '../../gql/checkout4/enum/JourneyStepsRoutes';

type DxpDataLayerInterceptorTypes = {
  operationName: string;
  response: SaveEnquiryForm;
};

const dxpDataLayerInterceptor = {
  dxpInterceptor: ({ operationName, response }: DxpDataLayerInterceptorTypes) => {
    switch (operationName) {
      case 'SaveEnquiryForm':
        try {
          const data = response.saveEnquiryForm as SaveEnquiryForm_saveEnquiryForm;
          dxpDigitalDataAssignment({
            event: DxpDataLayerEvent.CONFIGURATOR,
            eventName: DxpDataLayerEventNames.CNF_DEALERS_CLICKED,
            pageTitle: document.title,
            DXPProduct: dxpProducts.checkout,
            dealerPreference: data?.dealer?.label,
            ...formatDataLayerDeal(data),
            nextSteps: getSaveEnquiryFormNextSteps(),
            stepName: JourneyStepsRoutes.DEALER.replace('/', ''),
          });
        } catch (err) {
          // eslint-disable-next-line no-console
          console.log('DataLayer error', err);
        }

        break;
      default:
        break;
    }
  },
};

export default dxpDataLayerInterceptor;
