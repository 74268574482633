import React, { Dispatch, FunctionComponent, SetStateAction } from 'react';
import { Typography } from '@material-ui/core';
import paymentDisclaimerModalStyles from './paymentDisclaimerModalStyles';
import ModalWithHeader from '../../../../../common/components/presentation/ModalWithHeader/ModalWithHeader';
import Translation from '../../../../../common/components/presentation/Translation/Translation';
import { useIntl } from 'react-intl';

interface ITermsAndConditionsModalProps {
  isOpen: boolean;
  toggleModal: Dispatch<SetStateAction<boolean>>;
}

const PaymentDisclaimerModal: FunctionComponent<ITermsAndConditionsModalProps> = ({ isOpen, toggleModal }) => {
  const styles = paymentDisclaimerModalStyles();
  const intl = useIntl();

  return (
    <ModalWithHeader
      open={isOpen}
      toggleModal={toggleModal}
      title={<Translation id="trans__checkout__enquiry_form__reserve_online_terms_and_conditions_title_page" />}
    >
      <Typography
        data-test={`payment_disclaimer_modal`}
        className={styles.root}
        dangerouslySetInnerHTML={{
          __html: intl.formatMessage({
            id: 'trans__checkout__next_step__payment_page_disclaimer__terms_and_condition_modal',
          }),
        }}
      />
    </ModalWithHeader>
  );
};

export default PaymentDisclaimerModal;
