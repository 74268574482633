import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type CheckboxClasses =
  | 'root'
  | 'labelRoot'
  | 'label'
  | 'formControlContainer'
  | 'disclaimer'
  | 'note'
  | 'marketingInformationDisclaimer'
  | 'container'
  | 'modalTitle'
  | 'clickable';

const checkboxStyles = makeStyles<ICheckoutTheme, CheckboxClasses>(
  ({ palette, typography }) => ({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      width: '40px',
      height: '40px',
    },
    labelRoot: {
      padding: '10px',
    },
    label: {
      alignSelf: 'center',

      '& a': {
        color: palette.primary.main,
        textDecoration: 'none',
      },
    },
    formControlContainer: {
      alignItems: 'center',
      display: 'inline-flex',
      padding: '10px 10px 10px 0',
      marginRight: '15px',
    },
    disclaimer: {
      '& span': {
        fontWeight: 'bold',
      },
      '& a': {
        fontWeight: 'bold',
      },
    },
    note: {
      fontFamily: typography.fontFamily,
    },
    marketingInformationDisclaimer: {},
    container: {},
    modalTitle: {
      cursor: 'pointer',
      paddingLeft: '40px',
    },
    clickable: {
      color: palette.primary.main,
    },
  }),
  { name: 'GqlCheckout_Checkbox' },
);

export default checkboxStyles;
