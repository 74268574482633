import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type ExtrasClasses = 'container' | 'actionButtonContainer';

const extrasStyles = makeStyles<ICheckoutTheme, ExtrasClasses>(
  ({ breakpoints }) => ({
    container: {
      display: 'flex',
      flexWrap: 'wrap',
      width: '100%',
      justifyContent: 'start',
    },
    actionButtonContainer: {
      marginBottom: 10,
      [breakpoints.down('sm')]: {
        padding: '0 15px',
      },
    },
  }),
  { name: 'Checkout4_Extras' },
);

export default extrasStyles;
