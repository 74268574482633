import { ICheckoutTheme } from '../../../../../../common/theme/ICheckoutTheme';
import { makeStyles } from '@material-ui/core/styles';

export type DealersListContainerClasses = 'listContainer';

const dealersListContainerStyles = makeStyles<ICheckoutTheme, DealersListContainerClasses>(
  theme => ({
    listContainer: {
      width: '100%',
      maxWidth: 300,
      boxShadow: 'none',

      [theme.breakpoints.down('sm')]: {
        maxWidth: 'none',
        marginTop: 30,
      },
    },
  }),
  { name: 'Checkout_DealersListContainer' },
);

export default dealersListContainerStyles;
