import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type PaymentTermsAndConditionModalClasses =
  | 'dialog'
  | 'title'
  | 'dialogPaper'
  | 'dialogTitle'
  | 'dialogContent'
  | 'dialogActions'
  | 'disclaimerText'
  | 'dialogTitleContent'
  | 'iconButton'
  | 'acceptButton';

const paymentTermsAndConditionModalStyles = makeStyles<ICheckoutTheme, PaymentTermsAndConditionModalClasses>(
  ({ palette }) => ({
    dialog: {},
    title: {},
    dialogPaper: {
      borderRadius: 0,
    },
    dialogTitle: {
      padding: '10px 24px 10px 24px',
    },
    dialogTitleContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      color: palette.primary.main,
    },
    dialogContent: {},
    dialogActions: {
      padding: '10px 20px 10px 20px',
    },
    disclaimerText: {
      marginBottom: 20,
      whiteSpace: 'pre-line',
    },
    iconButton: {
      width: 26,
      height: 26,
      padding: 0,
      color: palette.common.white,
      backgroundColor: palette.primary.main,
      '&:hover': {
        color: palette.common.white,
        backgroundColor: palette.primary.main,
      },
    },
    acceptButton: {
      margin: '0 auto',
      borderRadius: 0,
      width: '100%',
      color: palette.common.white,
      maxWidth: 'unset',
      '&:hover': {
        backgroundColor: palette.primary.main,
      },
    },
  }),
  { name: 'Checkout4_PaymentTermsAndConditionModal' },
);

export default paymentTermsAndConditionModalStyles;
