import { useState, useEffect, useCallback } from 'react';
import { RedirectProps } from 'react-router-dom';
import { Routes } from '../enum/Routes';
import { UrlParamTypes } from '../enum/UrlParamTypes';
import usePlatformConfig from '../react/usePlatformConfig';
import useUrlSearchParams from './useUrlSearchParams';
import useCheckoutContext from '../../gql/common/hooks/useCheckoutContext';

const useRedirect = (): RedirectProps | null => {
  const searchParams = useUrlSearchParams();
  const [redirecProps, setRedirectProps] = useState<RedirectProps>(null);
  const {
    platformConfig: {
      platformFeatures: { isDealerEnabled, isExtrasPageEnabled },
    },
  } = usePlatformConfig();
  const { extrasPageHasContent } = useCheckoutContext();

  const getRedirectPath = useCallback(() => {
    if (isExtrasPageEnabled && extrasPageHasContent) {
      return Routes.EXTRAS;
    }
    if (isDealerEnabled) {
      return Routes.DEALER;
    }
    return Routes.REQUEST_QUOTE;
  }, [isDealerEnabled, isExtrasPageEnabled, extrasPageHasContent]);

  useEffect(() => {
    const { token, from } = searchParams || {};

    const queryString = `${UrlParamTypes.TOKEN}=${token}&${UrlParamTypes.FROM}=${from}`;

    if (token && extrasPageHasContent !== null) {
      setRedirectProps({
        to: {
          pathname: getRedirectPath(),
          search: queryString,
        },
        from: Routes.INDEX,
        exact: true,
      });
    }
  }, [searchParams, extrasPageHasContent, getRedirectPath]);

  return redirecProps;
};

export default useRedirect;
