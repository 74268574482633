import makeStyles from '@material-ui/core/styles/makeStyles';
import { ICheckoutTheme } from '../../../../../common/theme/ICheckoutTheme';

export type CustomRadioGroupClasses =
  | 'container'
  | 'root'
  | 'radioButtonContainer'
  | 'radioButtonGroup'
  | 'disclaimer'
  | 'labelContainer'
  | 'errorMessageContainer'
  | 'errorMessage'
  | 'mandatoryLegend';

const customRadioGroupStyles = makeStyles<ICheckoutTheme, CustomRadioGroupClasses>(
  ({ palette, typography }) => ({
    container: {},
    disclaimer: {
      fontSize: typography.fontSM,
      '& span': {
        fontWeight: 'bold',
      },
      '& a': {
        fontWeight: 'bold',
      },
    },
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
      '& svg': {
        fontSize: '20px',
      },
      width: '40px',
      height: '40px',
    },
    radioButtonContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    radioButtonGroup: {
      display: 'flex',
      flexDirection: 'row',
    },
    errorMessageContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'end',
      height: 40,
    },
    errorMessage: {
      color: palette.error.main,
      fontSize: typography.fontSM,
    },
    mandatoryLegend: {
      marginBottom: 50,
    },
    labelContainer: {},
  }),
  { name: 'GqlCheckout_CustomRadioGroup' },
);

export default customRadioGroupStyles;
